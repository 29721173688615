import React from 'react'
import {basepath,baseurl} from '../../basepath'

export default function InternalStorage() {
  return (
    <div className='internalStorage'>
            <div className="storagecontainer">
            <div className="_lft">
        <div className="titlecontent">
            <h2>11GB* RAM+ <br/> 128GB Internal Storage</h2>
            <p>With 128GB of storage, you can keep all your precious memories 
and big files in your pocket. No need to delete anything or worry 
about running out of space.</p>
  <p>And with RAM+ technology, you get 11GB of RAM (6GB + 5GB*) to make your phone multitask faster and smoother than ever.</p>
        </div>
        </div>
        <div className="_rft">
            <div className="_img">
              <img src={`${baseurl}${basepath}/images/blaze2/multitasking-mobile.png`} className='img-fluid' alt="Blaze 2" />
            </div>
        </div>
            </div>
            <div className="storageram">
              <img src={`${baseurl}${basepath}/images/blaze2/6gb.png`} className='img-fluid _bg6' alt="Blaze 2" />
              <img src={`${baseurl}${basepath}/images/blaze2/5gb.png`} className='img-fluid _bg5' alt="Blaze 2" />

            </div>
    </div>
  )
}
