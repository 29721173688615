import React,{useState} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {BrowserRouter} from 'react-router-dom'
import './styles/animate.css'
import './styles/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/font-awesome.min.css'
// import './styles/fonts.css'
import './styles/grid-gallery.css'
import './styles/hover.css'
// import './styles/kGallery.css'
import './styles/magnific-popup.css'
//import './styles/responsive.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import './styles/style.css'
import './styles/main.scss'
// import 'bootstrap/dist/css/bootstrap.min.css'

import Header from './components/Header'
import Footer from './components/Footer'


const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <App />
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
