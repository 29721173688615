import React,{useEffect} from 'react'
import AOS from 'aos';
import {basepath,baseurl} from '../basepath'

const Blazepro = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <>
    <section class="page_title inner_breadcum detailpage_breadcum">
        <div class="container-fluid">
            <h1>Blazepro</h1>
        </div>
    </section>
    <section className='blazepro_section'>
        <img src={`${baseurl}${basepath}/images/sec-1-bg.jpg`} />
        <div className='container container_one'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='pro-img'>
                        <img data-aos="fade-left"  src={`${baseurl}${basepath}/images/sec-1-pro-img.png`} />
                    </div>
                    <div className='section-content' data-aos="fade-up" data-aos-easing="linear"
     data-aos-duration="1000">
                        <h1>50MP</h1>
                        <label>Ai Tripal Camera Setup</label>
                        <div className='section-logo'>
                            <img src={`${baseurl}${basepath}/images/blazepro-logo.png`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='blazepro_section'>
        <div className='container-fluid container_two'>
            <div className='row'>
                <div className='col-md-8 col-sm-7 col-xs-12'>
                    <div className='section-content'>
                        <ul className='row'>
                            <li className='col-md-6' data-aos="fade-up"  >
                                <div className='flex-col'>
                                    <img src={`${baseurl}${basepath}/images/50mp-ai.png`} />
                                    <div className='text'>
                                        <h1>50 MP</h1>
                                        <p>Triple AI Camera</p>
                                    </div>
                                </div>
                            </li>
                            <li className='col-md-6' data-aos="fade-up"  >
                                <div className='flex-col'>
                                    <img src={`${baseurl}${basepath}/images/90hz.png`} />
                                    <div className='text' data-aos="flip-up" >
                                        <h1>90 Hz</h1>
                                        <p>Screen Refresh Rate</p>
                                    </div>
                                </div>
                                <div className='flex-col lighting'>
                                    <img src={`${baseurl}${basepath}/images/lightning-fast.png`} />
                                    <div className='text'>
                                        <h1>Lightning Fast</h1> 
                                        <p>Side Finger Print Sensor</p>
                                    </div>
                                </div>
                            </li>
                            <li className='col-md-12' data-aos="zoom-in" >
                                <div className='flex-col'>
                                    <img src={`${baseurl}${basepath}/images/media-tek-G37.png`} />
                                    <div className='text media_tek'>
                                        <h1>Media Tek<br />G37</h1> 
                                        <p>2.3GHz Super Fast</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-md-4 col-sm-5 col-xs-12' data-aos="zoom-in"  >
                    <div className='section-content'>
                        <ul className='row'>
                            <li className='col-md-12'>
                                <div className='flex-col'>
                                    <img src={`${baseurl}${basepath}/images/ramplus.png`} />
                                    <div className='text ramplus'>
                                        <h1>RAM+</h1> 
                                        <p>4GB + 3GB*</p>
                                    </div>
                                </div>
                            </li>
                            <li className='col-md-12'>
                                <div className='flex-col'>
                                    <img src={`${baseurl}${basepath}/images/frosted.png`} />
                                    <div className='text'>
                                        <h1>Frosted</h1> 
                                        <p>Glass Design</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='blazepro_section'>
        <img src={`${baseurl}${basepath}/images/sec-3-bg.jpg`} alt='' />
        <div className='container container_three'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='section-content' data-aos="zoom-in"  >
                        <h1>All Glass Back Design</h1>
                        <label>Matte Finish Anti Glare Frosted Glass<br />for stylish looks and smudge free handling</label>
                    </div>
                    <div className='proul'>
                        <img data-aos="zoom-in"   src={`${baseurl}${basepath}/images/third-sec-p4.png`} />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='blazepro_section'>
        <img src={`${baseurl}${basepath}/images/50mp-bg.jpg`} alt='' />
        <div className='container container_four'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='section-content' data-aos="zoom-in"  >
                        <div className='text'>
                            <h1>50MP</h1>
                            <label>AI TRIPAL CAMERA</label>
                        </div>
                        <p><b>1/2.8" Large</b> Image Sensor by Samsung<br/>f1.8 Aperture lens for extraordinary low light photography</p>
                        <div className='fiftympproduct'>
                            <img data-aos="zoom-in"  src={`${baseurl}${basepath}/images/50mp-product.png`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='blazepro_section five_section'>
        <div className='container container_five'>
            <div className='row'>
                <div className='col-md-6 col-sm-6 col-xs-12 col'>
                    <div className='gallery-img' data-aos="zoom-in"  >
                        <img src={`${baseurl}${basepath}/images/g1.png`} alt='' />
                    </div>
                    <div className='gallery-img' data-aos="zoom-in"  >
                        <img src={`${baseurl}${basepath}/images/g2.png`} alt='' />
                    </div>
                </div>
                <div className='col-md-6 col-sm-6 col-xs-12 col'>
                    <div className='gallery-img'data-aos="zoom-in"  >
                        <img src={`${baseurl}${basepath}/images/g3.png`} alt='' />
                    </div>
                    <div className='gallery-img' data-aos="zoom-in"  >
                        <img src={`${baseurl}${basepath}/images/g4.png`} alt='' />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='blazepro_section'>
        <img src={`${baseurl}${basepath}/images/pro_mode_bg.jpg`} alt='' />
        <div className='container container_six'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='section-content'>
                        <div className='text'>
                            <div className='text-in'  data-aos="fade-left"   >
                                <h1>PRO MODE</h1>
                                <label>Supports RAW image capture</label>
                            </div>
                            <img data-aos="zoom-in"   src={`${baseurl}${basepath}/images/pro-mode-product.png`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='blazepro_section'>
        <img src={`${baseurl}${basepath}/images/time-lapse-bg.jpg`} alt='' />
        <div className='container container_seven'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='section-content' data-aos="zoom-in"  >
                        <div className='text'>
                            <h1>TIME LAPSE</h1>
                            <p>Create mind boggling time lapse videos with<br />In-built camera app</p>
                            <img src={`${baseurl}${basepath}/images/time-lapse-product.png`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='blazepro_section'>
        <img src={`${baseurl}${basepath}/images/dazzle-img-bg.jpg`} alt=''/>
        <div className='container container_eight'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='section-content'>
                        <div className='text'>
                            <div className='text-in' data-aos="zoom-in"  >
                                <h1>DAZZLE YOUR</h1>
                                <h3>SOCIAL MEDIA</h3>
                                <p>With instant GIF shot<span>in in-built camera app</span></p>
                            </div>
                            <img data-aos="zoom-in"   src={`${baseurl}${basepath}/images/dazzle-pro-img.png`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='blazepro_section'>
        <img src={`${baseurl}${basepath}/images/make-multitasking-bg.jpg`} alt="" />
        <div className='container container_nine'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='section-content'>
                        <div className='text'>
                            <div className='text-in' data-aos="zoom-in"  >
                                <h1>4GB + 3GB* RAM</h1>
                                <p>Make multitasking a breeze with ram+</p>
                            </div>
                            <img data-aos="fade-left"   src={`${baseurl}${basepath}/images/ramplus-product.png`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='blazepro_section'>
        <img src={`${baseurl}${basepath}/images/clean_ui-bg.jpg`} alt="" />
        <div className='container container_ten'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='section-content'>
                        <div className='text'>
                            <div className='text-in' data-aos="zoom-in"  >
                                <h1>Clean UI<br />Near Stock <br />Android experience. </h1>
                                <p>Clean, smooth & bloat free experience.</p>
                            </div>
                            <div className='center'>
                                <img data-aos="zoom-in"   src={`${baseurl}${basepath}/images/clean_ui.png`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='blazepro_section'>
        <img src={`${baseurl}${basepath}/images/blink-bg.jpg`} alt='' />
        <div className='container container_eleven'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='section-content'>
                        <div className='text'>
                            <div className='text-in' data-aos="zoom-in">
                                <h1>You blink, you miss!</h1>
                                <p><b>Lightning fast side finger print sensor</b><br />that unlocks your phone faster </p>
                                <h3>than the blink of an eye</h3>
                                <h4>Finger print unlock time 0.24sec*</h4>
                            </div>
                            <img data-aos="zoom-in"   src={`${baseurl}${basepath}/images/miss-product.png`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Blazepro
