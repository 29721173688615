import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';
import {basepath,baseurl} from '../basepath'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';

const url = process.env.REACT_APP_API_URL;

const HomePage = () => {

  var storeId = 0
  const [bannerPath, setBannerPath] = useState('')
  const [banner, setBanner] = useState([])
  const [loading,setLoading] = useState(true)

  if (typeof window !== 'undefined') {
    storeId = window.localStorage.getItem('storeId')
  }

  const getBanner = async () => {
    try {
      const res = await axios.get(
        `${url}/banners/${storeId}`
      )
      if(res.status===201){
        setBannerPath(res.data.path)
        setBanner(res.data.banner)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(storeId!==null){
      getBanner()
    }
  }, [storeId])
  return (
    <>
      <div className='banner'>
        
        <div className='banner_slider owl-carousel ' id='banner_slider'>
          {
            loading?<>loading</>:<Carousel 
            showArrows={true}
            infiniteLoop 
            useKeyboardArrows={true} 
            swipeable={true}
            emulateTouch={true}
             showThumbs={true} 
             autoPlay
             transitionTime={500}
             interval={3000}
             
             >
            {banner.length > 0 &&
              banner?.map((item, i) => (
                <Link to="#">
                <div className='item' key={i}>
                  <LazyLoadImage src={`${bannerPath}/${item.image}`} effect="blur" alt={item.title} className='wd100' />
                </div>
                </Link>
              ))}

            {/* <div className="item">
				<img src="images/slider1.jpg" alt="" className="wd100" /> 
			</div> */}
          </Carousel>
          }
        </div>
      </div>
      {/* <!--Banner End--> */}

      <section className='featur_pro_list'>
        <div className='container-fluid'>
          <div className='row'>
          <div className='col-md-4'>
              <a href={`${baseurl}${basepath}/product-details/yuva-2-pro`}>
                      <div className='one_sec_box shadow'>
                        <h5>YUVA 2 PRO</h5>
                        <h3>
                        The Elegant Performer! 
                        </h3>
                        <a href={`${baseurl}${basepath}/product-details/yuva-2-pro`}>View Details</a>
                        <div className='box_img_bttm'>
                          <img src={`${baseurl}${basepath}/images/homepro1.png`} alt='img' />
                        </div>
                      </div>
                </a>
            </div>
            <div className='col-md-4'>
              <a href={`${baseurl}${basepath}/product-details/blaze-5g`}>
                      <div className='one_sec_box shadow border'>
                        <h5>Blaze 5G</h5>
                        <h3>Dual 5G </h3>
                        <a href={`${baseurl}${basepath}/product-details/blaze-5g`}>View Details</a>
                        <div className='box_img_bttm'>
                          <img src={`${baseurl}${basepath}/images/homepro2.jpg`} alt='img' />
                        </div>
                      </div>
                </a>
            </div>

            <div className='col-md-4'>
              <a href={`${baseurl}${basepath}/product-details/blaze-2`}>
                      <div className='one_sec_box shadow'>
                        <h5>Blaze 2</h5>
                        <h3>
                          Unlock the thrill
                          
                        </h3>
                        <a href={`${baseurl}${basepath}/product-details/blaze-2`}>View Details</a>
                        <div className='box_img_bttm'>
                          <img src={`${baseurl}${basepath}/images/blaze-2-img-1.png`} alt='img' />
                        </div>
                      </div>
                </a>
            </div>
            <div className='col-md-4' hidden>
              <a href={`${baseurl}${basepath}/product-details/lava-z3`}>
                      <div className='one_sec_box mt-4 shadow'>
                        <h5>Blaze 2</h5>
                        <h3>A premium smooth<br/>and abstract build.</h3>
                        <a href={`${baseurl}${basepath}/blaze-2`}>View Details</a>
                        <div className='box_img_bttm'>
                          <img src={`${baseurl}${basepath}/images/blaze-2-img-1.jpg`} alt='img' />
                        </div>
                      </div>
                </a>
            </div>
            <div className='col-md-4' hidden>
              <a href={`${baseurl}${basepath}/product-details/lava-z3`}>
                      <div className='one_sec_box mt-4 shadow'>
                        <h5>Yuva 2 Pro</h5>
                        <h3>
                        Premium Glass <br/>Back Design
                        </h3>
                        <a href={`${baseurl}${basepath}/yuva-2-pro`}>View Details</a>
                        <div className='box_img_bttm'>
                          <img src={`${baseurl}${basepath}/images/Yuva-2-Pro-lavender-1.png`} alt='img' />
                        </div>
                      </div>
                </a>
            </div>
          </div>
        </div>
      </section>

      <section className='hot_pro_list hot_pro_list_home' hidden>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='title'>Hot Accessories</h3>
            </div>

            <div className='col-md-6 text-right'>
              <Link to={`${basepath}/product/smartphone`} className='view_all_btn'>
                View All <img src={`${baseurl}${basepath}/images/arrow.png`} alt='img' />
              </Link>
            </div>
          </div>
          <div className='row hot_pro_row'>
            <div className='col-md-6 hot_6 '>
			<Link to={`${basepath}/product/smartphone`}>
              <div className='one_sec_box one_hot_produ hot_prod1 wow animated  fadeIn'>
                <div className='one_hot_img'>
                  <img src={`${baseurl}${basepath}/images/ear_1.png`} alt='img' />
                  <h6>Probuds N1</h6>
                </div>
                <div className='one_host_detila'>
                  <h3>Switch on Your Unstoppable Fun</h3>
                  <a href='#'>Know More</a>
                </div>
              </div>
			</Link>
			<Link to={`${basepath}/product/smartphone`}>
              <div className='one_sec_box one_hot_produ hot_prod2 wow animated  fadeIn'>
                <div className='one_host_detila'>
                  <h3>Compatible with all devices with micro USB port devices.</h3>
                  <a href='#'>Know More</a>
                </div>

                <div className='one_hot_img'>
                  <img src={`${baseurl}${basepath}/images/usb-1.png`} alt='img' />
                  <h6>Data Cable</h6>
                </div>
              </div>
			  </Link>
            </div>
            <div class="col-md-4 hot_4">
			<Link to={`${basepath}/product/smartphone`}>
					<div class="one_sec_box one_hot_power_bank  hot_prod3 wow animated  fadeIn">
						<h3>Power Bank</h3>
						<a href="#">Know More</a>
						<div class="box_img_bttm">
						<img src={`${baseurl}${basepath}/images/power_bank.png`} alt='img' />
						</div>
					</div> 
				</Link>
				</div>
            {/* <div className='col-md-4 hot_4'>
              <div className='one_sec_box one_hot_power_bank hot_prod3 wow animated  fadeIn'>
                <h3>Power Bank</h3>
                <a href='#'>Know More</a>
                <div className='box_img_bttm'>
                  <img src={`${baseurl}${basepath}/images/power_bank.png`} alt='img' />
                </div>
              </div>
            </div> */}

            <div className='col-md-3 hot_3'>
			<Link to={`${basepath}/product/smartphone`}>
              <div className='one_sec_box one_hot_carger hot_prod4 wow animated  fadeIn'>
                <h3>Charger</h3>

                <a href='#'>Know More</a>
                <div className='box_img_bttm'>
                  <img src={`${baseurl}${basepath}/images/charger_1.png`} alt='img' />
                </div>
              </div>
			</Link>
			<Link to ={`${basepath}/product/smartphone`}>
              <div className='one_sec_box one_hot_carger hot_prod5 wow animated  fadeIn'>
                <h3>Battery</h3>
                <a href='#'>Know More</a>
                <div className='box_img_bttm'>
                  <img src={`${baseurl}${basepath}/images/charger_2.png`} alt='img' />
                </div>
              </div>
			  </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HomePage
