import React from 'react'
import  "../styles/a3vibe.scss";
import { Link } from 'react-router-dom';
import {basepath,baseurl} from '../basepath'

export default function A3Vibe() {
  return (
    <>
    <section className='page_title inner_breadcum detailpage_breadcum'>
        <div className="full_container">
            <div className="left">
                <h4>A3 Vibe</h4>
            </div>
            <div className="right">
                <Link to='/'>Overview</Link>
                <Link to='/'>Specification</Link>
            </div>
        </div>
    </section>
    <section className='a3vibe_Banner'>
        <div className="__wrapper">
           <div className='container'>
              <div className="row">
                <div className="col-md-6">
                <div className="_leftscreen">
                <h2>A3 Vibe</h2>
                <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/a3Vibe_mobile_left.png`} /> 
                    
                  </div>
                </div>
                <div className="col-md-6">
                <div className="_rightscreen">
                 <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/a3Vibe_content_right.png`} />
                  </div>
                  
                </div>
              </div>
           </div>
        </div>
    </section>
    <section className='a3vibe_infoscreen'>
          <div className="container">
            <div className="row">
            <div className="_groups">
                  <div className="_card">
                    <div className="box">
                     <h4>Elegant & Stylish</h4>
                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/card1.png`} />
                      </div>
                  </div>
                  <div className="_card">
                    <div className="box">
                     <h4>1750 mAh 
Big Battery
</h4>
                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/card2.png`} />
                      </div>
                  </div>
                  <div className="_card">
                    <div className="box">
                     <h4>1.8” Screen</h4>
                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/card3.png`} />
                      </div>
                  </div>
                  <div className="_card">
                    <div className="box">
                     <h4>Wireless FM with recording</h4>
                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/card4.png`} />
                      </div>
                  </div>
                  <div className="_card">
                    <div className="box">
                     <h4>Camera Phone</h4>
                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/card5.png`} />
                      </div>
                  </div>
              </div>
            </div>
          </div>
    </section>
    <section className='a3vibescreen01'>
        <div className="container">
          <div className="row">
              <div className="col-md-6">
                <div className="leftscreen">
                    <h2>Lava A3 Vibe: Simple Snaps, Memorable Moments.</h2>
                    <p>Lava A3 Vibe comes equipped with a  <strong>0.3MP primary camera</strong>, allowing you to capture essential moments with simplicity and ease.
                    </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="rightscreen">
                        <div className="_imgcard">
                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/a3videscreen_right.png`} />

                        </div>
                </div>
              </div>
          </div>
        </div>
    </section>
    <section className='a3vibescreen02'>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="leftscreen">
                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/a3vibe_screen_leftmobile.png`} />

              </div>
            </div>
            <div className="col-md-6">
              <div className="rightscreen">
                <h2>Amazing Music Experience</h2>
                <p>Turn up the volume and lose yourself in the music. The Lava A3 Vibe delivers loud and clear sound, making every song a pleasure to listen to.</p>
              </div>
            </div>
          </div>
        </div>
    </section>
    <section className='a3vibescreen03'>
        <div className='container'>
            <div className="row">
                <div className="col-md-6">
                    <div className="leftscreen">
                        <h2>
                        <strong>7 Days</strong> Battery Backup
                        </h2>
                        <p>Enjoy extended use with the Lava A3 Vibe’s powerful 1750 mAh battery. Whether you’re making calls, sending texts, or listening to music, this phone keeps going as long as you do.
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                  <div className="rightscreen">
                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/a3vibe_screen_3_right.png`} />

                  </div>
                </div>
            </div>
        </div>
    </section>
    <section className='a3vibescreen04'>
        <div className='container'>
            <div className="row">
              <div className="col-md-6">
                <div className="leftscreen">
                  <h2>Super Strong <br/> Poly-Carbonate Body</h2>
                  <p>
                  Designed to withstand the knocks and drops of everyday life, the Lava A3 Vibe’s poly-carbonate body is tough enough for any challenge.</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="rightscreen">
                    <div className="box">

                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/a3vibe_screen_4.right_mobile.png`} />
                    </div>
                </div>
              </div>
            </div>
            </div>
      </section>
      <section className='a3vibescreen05'>
        <div className='container'>
            <div className="row">
                <div className="col-md-6">
                  <div className="leftscreen">
                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/a3vibe_screen_5.leftmobile.png`} />
                     
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rightscreen">
                    <div className="screen_memory">
                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/a3vibe_screen_memory.png`} />

                    </div>
                      <div className="content">
                      <h2>Expand memory up <br/> to 32GB </h2>
                      <p>Never worry about space again. With support for up to 32 GB of expandable storage, the Lava A3 Vibe lets you store all your favourite songs, pictures, and videos with ease.
                      </p>
                      </div>
                  </div>
                </div>
            </div>
            </div>
        </section>
        <section className='a3vibescreen06'>
        <div className='container'>
            <div className="row">
              <div className="col-md-5">
                <div className="leftscreen">
                  <h2>AUTO CALL RECORDING</h2>
                  <p>
                  Automatically Record Your Calls 
Within Folders 

                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="rightscreen">

                     <img alt="A3 Vibe" src={`${baseurl}${basepath}/images/a3vibe/screen_6_right.png`} />
                </div>
              </div>
            </div>
            </div>
            </section>
    </>
  )
}
