import React,{useEffect} from 'react'
import '../../styles/yuva2proj.scss'
import {basepath,baseurl} from '../../basepath'
import AOS from 'aos'

export default function Coreprocessor() {
  return (
    <div className='coreprocessor'>
            <div className="title_container">
                <h2>2.3Ghz Octa Core Processor</h2>
                <h4>Elegance meets the Performer! </h4>
                <p>The 2.3Ghz Octa core processor runs smoothly and efficiently. Thanks to the eMMC5.1 technology, your phone can multitask with ease.</p>
            </div>
            <div className="gaming">
                <div className="_lftmobile">
                 <img src={`${baseurl}${basepath}/images/yuva2pro/game-mobile-down.png`} className='img-fluid mobile1' alt="Yuva 2 Pro"/>
                </div>
                <div className="_rftmobile">
                <img src={`${baseurl}${basepath}/images/yuva2pro/game-mobile-up.png`} className='img-fluid mobile2' alt="Yuva 2 Pro"/>
                </div>
                <div className="_centeravtar">
                <img src={`${baseurl}${basepath}/images/yuva2pro/game-img.png`} className='img-fluid' alt="Yuva 2 Pro"/>
                </div>
            </div>
    </div>
  )
}
