import React from 'react'
import {basepath,baseurl} from '../../basepath'

export default function TimeLapes() {
  return (
    <div className='timelapes'>
            <div className="timelapes_title">
                <h2>TIME LAPSE</h2>
                <h4 className='text-uppercase'>All in a matter of seconds!</h4>
                <p>Want to see the world in fast-forward? With time lapse feature on BLAZE 2, capture the beauty of change in seconds. Whether it's a blooming flower, 
                    a bustling city, or a melting ice cream, you'll never miss a moment with time lapse. It's fun, easy, and amazing.</p>
                    <strong>It's like magic, but it's real.</strong>
            </div>
            <div className="time-lapes-frame">
               <img src={`${baseurl}${basepath}/images/blaze2/time-lapes-frame.png`} className='img-fluid _up' alt="Blaze 2" />
               <div className="_shadow">
               <img src={`${baseurl}${basepath}/images/blaze2/time-lapes-frame-shadow.png`} className='img-fluid _up' alt="Blaze 2" />

               </div>
            </div>
        </div>
  )
}
