import React,{useEffect} from 'react'
import '../../styles/yuva2proj.scss'
import {basepath,baseurl} from '../../basepath'
import AOS from 'aos'

export default function AutoRecording() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return (
    <div className='autorecording'>
            <div className="title_container text-center">
                <h2>Anonymous Auto  <br/>Call Recording</h2>
                <h4>You want it! We have it!</h4>

            </div>
            <div className="recording-mobile-frame">
                <div className="recording-effect">
                <img src={`${baseurl}${basepath}/images/yuva2pro/recording-effect.png`} className='img-fluid' alt="Yuva 2 Pro"/>
                </div>
                <div className="recording-mobile" data-aos="fade-down"
                   data-aos-easing="linear"
                   data-aos-duration="1500">
                <div className="__icons">
                <img src={`${baseurl}${basepath}/images/yuva2pro/recording-icons.png`} className='img-fluid' alt="Yuva 2 Pro"/>
                </div> 
                   <img src={`${baseurl}${basepath}/images/yuva2pro/recording-mobile.png`} className='img-fluid __up' alt="Yuva 2 Pro"/>
                 </div>
                <img src={`${baseurl}${basepath}/images/yuva2pro/recording-mobile-frame.png`} className='img-fluid __middle' alt="Yuva 2 Pro"/>
            </div>
            <div className="recording-mobile-base">
                <img src={`${baseurl}${basepath}/images/yuva2pro/recording-mobile-base.png`} className='img-fluid __base' alt="Yuva 2 Pro"/>

            </div>
        </div>
  )
}
