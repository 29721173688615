import React,{useRef,useState,useEffect} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {basepath,baseurl} from '../../basepath'
import $ from 'jquery';

export default function TripleCamera() {
  const [oldSlide, setOldSlide] = useState('');
  const [activeSlide, setActiveSlide] = useState('');
  const [activeSlide2, setActiveSlide2] = useState('');
  const slider = useRef();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        arrows:false,
        beforeChange: (current, next) => {
          setOldSlide(current);
          setActiveSlide(next);
        },
        afterChange: current => setActiveSlide2(current)
      };
    useEffect(()=>{
      $(".slick-slider").slick({
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
         autoplay: true,
         fade: true,
    });
    },[])
    
  return (
    <div className='triplecamera'>
        <div className="title_content">
            <h2>13MP Ai Triple Camera</h2>
            <h4>Large 1.12µm Pixels For Superior Light and Color Capture</h4>
            <p>Whether you want to capture stunning landscape, group photo, or portrait, this camera can do it all. 
                With its 13 MP AI TRIPLE CAMERA, you can unleash your creativity and take amazing photos anytime, anywhere.</p>
        </div>
        <div className="overflow_right"></div>
        <div className='mask-container'>
        <Slider {...settings} ref={slider} className='slider-container'>
        <div>
            <img src={`${baseurl}${basepath}/images/yuva2pro/13mp-bg.png`} className='' alt="Yuva 2 Pro"/>
          </div>
          <div>
          <img src={`${baseurl}${basepath}/images/yuva2pro/13mp-bg.png`} className='' alt="Yuva 2 Pro"/>
          </div>
          <div>
          <img src={`${baseurl}${basepath}/images/yuva2pro/13mp-bg.png`} className='' alt="Yuva 2 Pro"/>
          </div>
          <div>
          <img src={`${baseurl}${basepath}/images/yuva2pro/13mp-bg.png`} className='' alt="Yuva 2 Pro"/>
          </div>
          <div>
          <img src={`${baseurl}${basepath}/images/yuva2pro/13mp-bg.png`} className='' alt="Yuva 2 Pro"/>
          </div>
          <div>
          <img src={`${baseurl}${basepath}/images/yuva2pro/13mp-bg.png`} className='' alt="Yuva 2 Pro"/>
          </div>
        </Slider>
        </div>
    </div>
  )
}
