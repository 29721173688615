import React from "react";
import {basepath,baseurl} from '../basepath'
const Condition = () => {
  return (
    <>
      <section className="innerpage_toppadding">
        <div className="full_container">
          <div className="contact_1">
            <div className="row">
              <div className="col-md-12">
                <div className="section_title text-center">
                  <h4>Terms and Conditions</h4>
                  <div className="breaker"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="faq1  terms">
                 
                  {/* <!-- Element Warranty Policy --> */}
                  <div className="full-wrap terms">
                    <div className="ip_content" hidden>
                      <h4
                        className="title"
                        id="blaze-5G-gaming">
                        Blaze 5G Gaming
                      </h4>
                      <ol className="list-decimal">
                        <li>To enter the contest, you must be:
                        <ul>
                          <li>a) A citizen of India and resident of India at the
                          time of entry.</li>
                          <li>b) At least 18 years old or over at the time of entry.</li>
                        </ul>
                        </li>
                        <li>
                          The winners will receive a Blaze 5G phone by Lava.{" "}
                        </li>
                        <li>
                          The winners will be announced on the Lava website.
                        </li>
                        <li>
                          There is no entry fee and no purchase necessary to
                          participate in the activity.
                        </li>
                        <li>
                          The Participants agree to be bound by the terms and
                          conditions contained herein. Customers are deemed to
                          have read, understood and unconditionally accepted the
                          terms and conditions herein.
                        </li>
                        <li>Other Terms & Conditions are applied.</li>
                      </ol>
                    </div>
                  </div>
                  {/* <!-- End Element Warranty Policy -->
<!-- Element Warranty Policy --> */}
                  {/* <!--  */}
                  {/* <div className="full-wrap terms">
  <div className="ip_content">
    <h4 className="title" id="">Blaze Offer</h4>
  <br/><br/>
<ul className="list">
<li>Customers who have received Blaze will be receiving a Whatsapp  and an E-mail on their registered numbers and mail ids.</li>
<li>These customers are eligible to get a Free Probuds worth Rs 1599.</li>
<li>After availing the offer, Probuds will be shipped within 2-3 business days and the customer shall receive them within 5-7 business days.</li>
<li>Last date to avail offer: 31st July 2022.</li>

</ul>

</div>
</div> --> */}
                  {/* <!-- End Element Warranty Policy -->
<!-- Element Warranty Policy --> */}
                  <div className="full-wrap terms" hidden>
                    <div className="ip_content">
                      <h4 className="title" id="">
                        Service @ Home
                      </h4>
                      <ul className="list">
                        <li>
                          Service@Home (Doorstep Services) is solely available
                          to all Blaze Customers only.{" "}
                        </li>

                        <li>
                          Customers may avail the Services through Website,
                          WhatsApp, Lava Care App or Call Centre number
                          18605005001;{" "}
                        </li>

                        <li>
                          The Service@Home shall be made available across India
                          including ~7000 PIN Codes Nationally;{" "}
                        </li>

                        <li>
                          The Service shall be performed by Lava trained
                          personnel duly authorized by its Service Partners who
                          shall carry/ wear authenticated Lava identity card all
                          the time;{" "}
                        </li>

                        <li>
                          Elementary Level Scan of the handset will be performed
                          at the customer location;{" "}
                        </li>

                        <li>
                          Only software and minor repairs will be carried under
                          the Service@Home program and in case of major repairs,
                          handset shall be taken to Authorized Service Center
                          (to & fro) of the handset shall not be chargeable to
                          any customer. However, handset which are Out of
                          Warranty will be charged for spares / services as per
                          the applicable price lists approved and recommended by
                          LAVA. Please visit LAVA website:
                          "http://www.lavamobiles.com/support" to know more
                          about spare / accessory part cost.{" "}
                        </li>

                        <li>
                          Our Authorized Service Center will use their best
                          efforts to reach out to customer (face2face) within 24
                          hours after receiving of a valid request (Service
                          promise) or per prior appointment as the case may be,
                          excluding exceptional circumstance beyond the
                          reasonable control of ASPs;{" "}
                        </li>

                        <li>
                          Authorized Service Center should issue a job sheet /
                          receipt to customer incase the handset is required to
                          be taken to the Service Center for high level repair
                          activity;{" "}
                        </li>

                        <li>
                          Authorized Service Center shall give payment receipt
                          to customer in case any amount is charged to repair
                          the handset;{" "}
                        </li>

                        <li>
                          All the terms and conditions of Warranty Policy shall
                          remain and be binding upon the parties for its product
                          Blaze against manufacturing defects when used normally
                          for a period of ONE (1) YEAR from the date of original
                          retail purchase by the customer;{" "}
                        </li>

                        <li>
                          This Program Service@home can be availed by the
                          customer for a period of 1 year from the date of
                          purchase of the handset;{" "}
                        </li>

                        <li>
                          Lava at its sole discretion reserves right to withdraw
                          the Program any time as may deem fit so;{" "}
                        </li>

                        <li>
                          All conflicts in relation to the service shall be
                          subject to jurisdiction of the courts at New Delhi.{" "}
                        </li>
                        <li>Service at home available only for Blaze & Yuva series
                        of smartphones.</li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Element Warranty Policy -->
<!-- Element Warranty Policy --> */}
                  <div className="full-wrap terms" hidden>
                    <div className="ip_content">
                      <h4
                        className="title"
                        id=""
                        style={{ paddingTop: "80px", marginTop: "-70px" }}
                      >
                        Mobikwik Offer
                      </h4>
                      <p>
                        <strong>
                          OFFER1: Get flat Rs 300/- Cashback @LAVA with MobiKwik
                          payments.
                        </strong>
                      </p>

                      <ul className="list">
                        <li>
                          Minimum transaction amount via MobiKwik to avail the
                          offer is Rs 9,999/-{" "}
                        </li>
                        <li>
                          Offer can be availed once per user during the campaign
                          period.
                        </li>
                        <li>
                          Cashback will be credited within 24 hours of the
                          transaction.{" "}
                        </li>
                        <li>Other generals offer T&C as applicable.</li>
                      </ul>
                      <p>
                        <strong>
                          {" "}
                          OFFER2: Get flat Rs 500/- Cashback @ LAVA with
                          MobiKwik payments.
                        </strong>
                      </p>
                      <ul className="list">
                        <li>
                          Minimum transaction amount via MobiKwik to avail the
                          offer is Rs 17,999/- and above.{" "}
                        </li>
                        <li>
                          Offer can be availed once per user during the campaign
                          period.
                        </li>
                        <li>
                          Cashback will be credited within 24 hours of the
                          transaction
                        </li>
                      </ul>
                      <p>
                        <strong>
                          Cashback will be auto credited to the customer's
                          MobiKwik wallet within 48 hours of the transaction.
                        </strong>
                      </p>
                      <ul className="list">
                        <li>Cashback offers cannot be clubbed.</li>
                        <li>
                          Offer valid on payments made via MobiKwik wallet only.
                        </li>
                        <li>
                          If the order is cancelled or left undelivered at
                          LAVA's end, LAVA (not MobiKwik) will initiate a
                          refund.
                        </li>
                        <li>
                          In case of partial refunds/cancellations, the refund
                          amount will be adjusted with the cashback received on
                          initial payment.{" "}
                        </li>
                        <li>
                          After the refund is initiated, the amount will be
                          credited back within 7-10 days.
                        </li>
                        <li>
                          Both MobiKwik & LAVA reserve the right to
                          discontinue/alter/change the offer without any prior
                          notice
                        </li>
                        <li>Other offer T&C as applicable.</li>
                      </ul>
                      <p>
                        Offer valid from 1st - 30th November'22 on
                        lavamobiles.com
                      </p>
                    </div>
                  </div>
                  {/* <!-- Element Warranty Policy -->
<!-- Element Warranty Policy --> */}
                  <div className="full-wrap terms" hidden>
                    <div className="ip_content">
                      <h4
                        className="title"
                        id=""
                        style={{ paddingTop: "80px", marginTop: "-70px" }}
                      >
                        Contest Terms & Conditions
                      </h4>
                      <p>
                        Participants can participate in the contest on the
                        respective social media platforms -Instagram, Facebook &
                        Twitter.
                      </p>

                      <p>
                        Winners will be selected randomly basis their answer is
                        correct. After the winners are announced, winners need
                        to contact Lava Mobiles on the respective social media
                        platform, with their full name, complete address with
                        pin code & mobile number. If the winners do not provide
                        us with the above details within 48 hours of
                        announcement of winners, the said winner won't be
                        qualified to receive the prize.
                      </p>

                      <p>
                        Company shall not be responsible in case the package
                        isn't delivered due to the incorrect address details or
                        invalid number provided by the customer. In such a case,
                        new winner will be picked.
                      </p>

                      <p>
                        The contests are not intended for internal employees.
                        Any internal employee's participation will be
                        disqualifed.
                      </p>
                      <h3>Other TnC:</h3>
                      <ol className="list-decimal">
                        <li>To enter the contest, you must be:
                          <ul>
                          <li>
                          a) A citizen of India and resident of India at the
                          time of entry.</li>
                          <li>
                            b) At least 18 years old or over at the time of entry.
                          </li>
                          </ul>
                        </li>
                        <li>
                          LAVA International Limited reserves the right to
                          disqualify any participant and/or revoke or forfeit
                          any prizes at any stage of the contest with or without
                          a prior notice at its sole absolute discretion, for
                          reasons including: Entry is incomplete and/or fails to
                          fulfil and/or comply with all or any of the Terms and
                          Conditions stipulated herein and any applicable rules
                          and regulations.
                          <p>
                            Participant breaches any of the Terms and Conditions
                            or other rules and regulations of the Contest or
                            violates any applicable laws or regulations.
                          </p>
                          <p>
                            LAVA International Limited has, at its sole
                            discretion, any reason whatsoever to believe that
                            such Eligible Participant has attempted to undermine
                            the operation of the Offer in any way whatsoever,
                            including but not limited to fraud, cheating or
                            deception.
                          </p>
                          <p>
                            Notwithstanding the above, LAVA International
                            Limited shall at its sole and absolute discretion
                            disqualify any participation at any stage of the
                            Contest and forfeit any prizes for any reason as it
                            deems fit and reasonable with or without a prior
                            notice.
                          </p>
                          <p>
                            LAVA International Limited decision on matters
                            relating to the Activity (including, but not limited
                            to the selection of the winners) shall be final and
                            absolute. No discussion, correspondence, enquiry,
                            appeal or challenge by any of the Participants in
                            respect of any decision of LAVA International
                            Limited shall be entertained.
                          </p>
                          <p>
                            Prizes must be collected in accordance with the mode
                            and within a time period which will be specified by
                            LAVA International Limited, at its sole and absolute
                            discretion, upon notification of any winnings.
                          </p>
                          <p>
                            Failure to adhere to the mode of collection and
                            within the stipulated time period will result in
                            automatic forfeiture of the prizes and the winner
                            shall have no claim whatsoever against LAVA
                            International Limited, its affiliates, subsidiaries,
                            employees, representatives, retailers, distributors,
                            dealers as well as its advertising, creative, media,
                            digital, design and other creative agencies of LAVA
                            International Limited.
                          </p>
                          <p>
                            The reward cannot be sold, exchanged, converted,
                            transferred, auctioned, assigned in favour of other,
                            or combined with any goods, cash, person or scheme
                            respectively in any circumstances.
                          </p>
                        </li>

                        <li>
                          LAVA International Limited shall not be liable for any
                          disruption to the contest, whether due to technical
                          problems or otherwise, which is beyond its reasonable
                          control.
                        </li>

                        <li>
                          The Terms and Conditions of the contest shall be
                          construed, governed and interpreted in accordance with
                          the laws of India.
                        </li>

                        <li>
                          All rights and privileges herein granted to LAVA
                          International Limited are irrevocable and not
                          subjected to rescission, restraint or injunction under
                          any and all circumstances. Under no circumstances
                          shall the Eligible Participants have the right to
                          injunctive relief or to restrain or otherwise
                          interfere with the organization of the contest, the
                          production, distribution, exhibition and/or
                          exploitation of the Contest and/or any product based
                          on and/or derived from the Contest.
                        </li>

                        <li>
                          All personal data collected may be used in relation to
                          the performance of the Contest. The Participant hereby
                          consents that their personal data may be used by LAVA
                          International Ltd for marketing and promotional
                          purposes.
                        </li>

                        <li>
                          LAVA International Limited reserves the right at its
                          absolute discretion to vary, delete or add to these
                          Terms and Conditions with or without any prior notice.
                          It is a candidate's responsibility to check regularly,
                          in order to ascertain if any changes have been made to
                          these Terms and Conditions.
                        </li>

                        <li>
                          These Terms and Conditions will prevail over any
                          inconsistent terms, conditions, provisions or
                          representations contained in any other communications
                          or promotional materials to the Contest.
                        </li>

                        <li>
                          The main language of the Terms and Conditions shall be
                          the English language. Any translation to any other
                          language than English shall be for convenience only
                          and the English Version shall prevail in the event of
                          any discrepancies between the English version and the
                          other language's version.
                        </li>

                        <li>
                          By virtue of an entry to the Contest, the Eligible
                          Participant signifies his/her absolute and
                          unconditional acceptance and agreement to all the
                          Terms & Conditions stipulated herein.
                        </li>

                        <li>
                          LAVA International Limited reserves the right to
                          cancel, terminate or suspend this Contest without any
                          prior notice. For the avoidance of doubt, any
                          cancellation, termination or suspension by LAVA
                          International Limited of the Contest shall not entitle
                          the Eligible Participants to any claim or compensation
                          against LAVA International Limited, its agents, and
                          employees for any and all losses or damages suffered
                          or incurred by the Participants as a direct or an
                          indirect result of the act of cancellation,
                          termination or suspension thereof.
                        </li>

                        <li>
                          All disputes are subject to the exclusive jurisdiction
                          of New Delhi only.
                        </li>

                        <li>
                          All information entered by the customer for
                          registration under this contest shall be subject to
                          the Privacy Policy of the company and said information
                          may be used by the company for its brand promotion.
                        </li>

                        <li>
                          The participant making entry in this contest gives
                          his/her consent to receive informatory/transactional
                          SMS or phone calls relating to this contest and other
                          promotional/commercial messages, from the company or
                          its authorized person/entity for its brand promotion
                          and further waives all his/her rights to take any
                          action against the company/its officials/authorized
                          person or entity for receiving SMS or phone calls.
                        </li>

                        <li>
                          LAVA International Ltd. may, without advance notice,
                          cancel, suspend or postpone the contest at any time at
                          its sole discretion.
                        </li>

                        <li>
                          In the instance of a contest winner being unavailable,
                          another winner may or may not be selected.
                        </li>

                        <li>
                          Winner shall be announced only after a complete
                          verification of details in the service centre
                          submitted by the participant.
                        </li>

                        <li>
                          Dates for submissions, and subsequent announcements
                          are subject to change. Lava International Ltd. does
                          not hold any responsibility for any issue arising
                          thereafter.
                        </li>

                        <li>
                          Participation in the contest is voluntary and
                          Participants are advised to read the full Terms and
                          Conditions. Participation in the contest shall imply
                          that the participant has duly read and agreed to
                          adhere to and is bound by the 'Terms and Conditions
                          herein.
                        </li>
                      </ol>
                    </div>
                  </div>
                  {/* <!-- Element Warranty Policy --> */}

                  {/* <!-- Element Warranty Policy --> */}
                  <div
                    className="full-wrap terms" hidden>
                    <div className="ip_content">
                      <h4
                        className="title"
                        id="warranty-on-out-warranty"
                      >
                        Warranty on Out Warranty ( 100 Days Warranty Refill
                        Program )
                      </h4>
                      <ul className="list">
                        <li>
                          The warranty on out warranty plan provides 100 days
                          Warranty on spare part that is replaced in out of
                          warranty.
                        </li>
                        <li>
                          Applicable Service charge on 100 days warranty calls
                          will be zero.
                        </li>
                        <li>
                          Part Warranty is applicable only on battery , PCBA ,
                          Display / Folder and Charger only.
                        </li>
                        <li>
                          Applicable only from repairs done on or after 12th
                          July 2021.
                        </li>
                          <br />
                        <div className="table-responsive p-0">
                          <h5>Applicable on following models : </h5>
                          <table className="table table-bordered table-striped ctm new-table">
                            <thead>
                              <tr>
                                <th>Model</th>
                                <th>Category</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>H1_HERO600</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA_A1200</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LF5000_A5</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LF5000_A5WAVE</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA_34PLUS</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVAGEM_WAVE</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LFP1_PULSE1</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA_A1LITE</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA_A1Colors</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA_A7_2020</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA PULSE</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>H2_HERO600+</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>H3_HERO600S</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LFF00_FLIP</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>A1_2020</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LF3400_34ULTRA</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LF1000_A1_2021</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA_A3</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA_A7</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA_A7WAVE</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>A1SUPER</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA_A1_GOLD</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>34SUPER</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA_A9</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LAVA_A9WAVE</td>
                                <td>FEATURE</td>
                              </tr>
                              <tr>
                                <td>LS000Z1</td>
                                <td>SMART</td>
                              </tr>
                              <tr>
                                <td>LE000Z93P_Z2</td>
                                <td>SMART</td>
                              </tr>
                              <tr>
                                <td>LE000Z93P_Z4</td>
                                <td>SMART</td>
                              </tr>
                              <tr>
                                <td>LE000Z93P_Z6</td>
                                <td>SMART</td>
                              </tr>
                              <tr>
                                <td>LE000Z93P</td>
                                <td>SMART</td>
                              </tr>
                              <tr>
                                <td>LS0001_Z2MAX</td>
                                <td>SMART</td>
                              </tr>
                              <tr>
                                <td>LE000Z93P_Z2_3GB</td>
                                <td>SMART</td>
                              </tr>
                              <tr>
                                <td>LF1000_A1_2021</td>
                                <td>FEATURE</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <li>
                          In case the customer faces issue in some other part
                          which isn't covered in 100 days warranty the part and
                          labour cost will be as per standard OW guidelines.
                        </li>
                        <li>
                          Duration for customers who have some left over
                          warranty - In case the handset is in Warranty and ELS
                          fail and the standard warranty is to expire in less
                          than 100 days - after the standard warranty gets
                          expired customer will get part warranty as applicable
                          in Out warranty for the remaining number of days.
                        </li>
                        <li>Applicable on Selected Models.</li>
                        <li>
                          The warranty on Out of Warranty will be considered
                          lapsed in case the handset is found ELS fail -i.e. if
                          The Product (Handset / Accessory) has been subject to
                          abnormal use or conditions, improper storage, exposure
                          to excessive moisture or dampness, exposure to
                          excessive temperatures, unauthorized modifications,
                          unauthorized repair, abuse, accident, Acts of God,
                          spills of food or liquids, improper installation and
                          breakage or damage to antennae, LCD, key panel and
                          housing cracked etc. for such cases customer will have
                          to pay Service charges and cost of spares(those will
                          be required to be replaced for necessary repairs) to
                          get the service of handset / accessory.
                        </li>
                        <li>
                          Terms of warranty are subjected to change from time to
                          time as communicated by company.
                        </li>
                        <li>
                          Lava holds discretion to withdraw the offer at any
                          point of time without any prior notice or liability to
                          the Customer.
                        </li>
                        <li>
                          All disputes are subject to the jurisdiction of the
                          Courts of New Delhi only.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="full-wrap terms cancelattion" hidden>
                    <div className="ip_content">
                      <h4
                        className="title" id="e-store-cancellation-and-replacement-policy">
                        Lava e-Store Replacement and Cancellation policy
                      </h4>
                      <h5>Order Cancellation Policy </h5>
                      <p>You can cancel your order online before the product has
                        been shipped.</p>
                      <p>If you have paid via a pre-payment mode (credit card,
                        debit card or net banking), then your entire order
                        amount will be refunded. Once your cancellation request
                        is created, we will ensure that the cancellation is
                        processed as soon as possible.</p>
                      <h5>Refunding Modes for Cancelled order:</h5>
                      <p>Except for Cash-on-Delivery transactions, refunds are
                        made to the payment mode/account that was originally
                        used to make the transaction. The refund shall be made
                        in Indian Rupees only and be equivalent to the
                        transaction payment received in Indian Rupees.</p>
                      <h5>Refund Timelines</h5>
                          <p>When an order is cancelled the refund is initiated and
                        the information is conveyed to our payment partners. The
                        time taken to process a normal refund depends on the
                        payment mode that was used while making the payment.
                        Depending on the bank's processing time, it can take 7
                        business days for the refund to reflect in the
                        customer's bank account or card balance. If the payment
                        is not reflected within the estimated time, please reach
                        out to us with your order numbers.</p>
                      <h5>Replacement Policy</h5>
                      <p>
                        Our replacement policy allows you to request a
                        replacement device at no additional cost if the device
                        received by you is defective. If you find that the
                        package is tampered, please do not accept the item and
                        hand the package back to the delivery person.
                      </p>
                      <p>You will be eligible to get a replacement if</p>
                      <ul className="list">
                        <li>
                          A replacement request is made within 7 (seven) days of
                          receipt of the delivery of the order.
                        </li>
                        <li>
                          Our examination of the device shows a defect that
                          qualifies the device for replacement.
                        </li>
                        <li>
                          The device has been received in its original
                          condition, means no physical change or damage to the
                          device.
                        </li>
                        <li>
                          The device has been received in the original product
                          packaging along with supporting documentation such as
                          receipt or proof of purchase, price tags, labels, and
                          accessories obtained along with the original product.
                        </li>
                        <li>
                          The original HS seal shouldn't be tampered and there
                          should not any physical damage.
                        </li>
                        <li>
                          Replacement is subject to availability of a suitable
                          replacement.
                        </li>
                        <li>
                          No refund in cash would be provided even if the device
                          purchased as "Cash on Delivery", in this scenario as
                          well the customer is required to fill in the bank
                          details.
                        </li>
                      </ul>
                      <h5>Raising the replacement request</h5>
                      <ul className="list">
                        <li>
                          You can visit the Lava ASP with receipt or proof of
                          purchase, price tags, labels, and accessories obtained
                          along with the original product.
                        </li>
                        <li>
                          You can get the service center details from the Lava
                          Website and can locate the nearest Authorized Service
                          Center by searching the Pin Code / City / District.
                        </li>
                        <li>
                          You can contact at the customer care number 1860 500
                          5001.
                        </li>
                      </ul>

                      <p>
                        The device will be inspected at the service center and
                        if found to be defective, the device to be deposited and
                        a new replacement device will be shipped to the shipping
                        address.
                      </p>
                      <p>
                        Once you have raised a replacement request, our team
                        will investigate and initiate the replacement process.
                        You will receive an SMS/ E-mail advising you on the
                        estimated delivery date. Based on customer location,
                        delivery span varies. If you don't get the return within
                        the promised date, contact us immediately.
                      </p>
                    </div>
                  </div>
                  {/* <!-- End Element Warranty Policy --> */}

                  {/* <!-- Element Warranty Policy --> */}
                  <div className="full-wrap terms">
                    <div className="ip_content">
                      <h4
                        className="title"
                        id="standard-warranty-1year" >
                        Standard Warranty (1 Year)
                      </h4>
                      <ol className="list-decimal">
                        <li>
                          LAVA international Ltd. (LAVA) provides Warranty for
                          its products like devices and original accessories
                          delivered with the devices/or sold by LAVA through
                          authorized dealers (hereinafter referred to as
                          "product").
                        </li>
                        <li>
                          For all handsets, the warranty period for the mobile
                          device is one (1) year; for battery, charger and
                          headset, earphones and cosmetic parts is six (6)
                          months from the date of purchase (including but not
                          limited to Front cover, back cover, battery cover,
                          volume key and power key), unless specifically
                          specified..
                        </li>
                      </ol>

                        <h5>Terms and Conditions</h5>
                        <ol className="list-decimal">
                        <li>
                          The product shall be counted as Out of Warranty if The
                          Product (Handset / Accessory) has been subject to
                          abnormal use or conditions, improper storage, exposure
                          to excessive moisture or dampness, exposure to
                          excessive temperatures, unauthorized modifications,
                          unauthorized repair, abuse, accident, Acts of God,
                          spills of food or liquids, improper installation and
                          breakage or damage to antennae, LCD, key panel and
                          housing cracked etc. for such cases customer will have
                          to pay Service charges and cost of spares(those will
                          be required to be replaced for necessary repairs) to
                          get the service of handset / accessory.
                        </li>

                        <li>
                          The charges of labor as well as spares used for
                          non-warranty repair shall be as per the applicable
                          Price lists approved and recommended by LAVA. Please
                          visit LAVA website:
                          "http://www.lavamobiles.com/support" to know more
                          about spare / accessory part cost.
                        </li>

                        <li>
                          Lava shall have the right to validate date of purchase
                          on basis of Handset activation (Tertiary Sales) date
                          captured in Lava system/Original Proof of Purchase of
                          the device, as the case may be.
                        </li>

                        <li>
                          For any handset which does not fulfill the above
                          mentioned conditions, the warranty period for the
                          mobile device is one (1) year; for battery, charger
                          and headset, earphones and cosmetic parts is six (6)
                          months from the date of purchase.
                        </li>

                        <li>
                          Warranty of the Handset / Accessory is subject to
                          technical & physical verification at Service Center as
                          per the parameters prescribed by LAVA. "LAVA" shall
                          have the sole discretion in rejecting the same, if the
                          Handset /Accessory is not found to be covered under
                          warranty.
                        </li>

                        <li>
                          Warranty of the handset is not assignable or
                          transferable to any other consumer.
                        </li>
                        <li>
                          To avail warranty coverage under offer, customer must
                          visit Lava authorized service center only.
                        </li>

                        <li>
                          The responsibility for the personal data loaded onto
                          the device is of the Customer alone. Customer shall be
                          liable to take the backup of stored data on-his-own,
                          before device submission at the Service Centre. Any
                          loss of data during service shall not be
                          responsibility of company.
                        </li>

                        <li>
                          LAVA shall make all efforts to ensure that the product
                          is repaired within earliest possible time subject to
                          availability of spare parts. At times however, due to
                          non-availability/shortage of critical spare parts or
                          complicated fault, the repair turnaround time may take
                          longer than the indicated time as may be indicated
                          under the relevant job sheet. LAVA shall not be
                          responsible for any loss(es) whatsoever in the event
                          of delay in repair for such aforementioned reasons.
                        </li>

                        <li>
                          In Case repair is not possible for the product covered
                          under warranty then Lava reserves the right to replace
                          it with any other product of similar specifications.
                        </li>

                        <li>
                          Terms of warranty are subjected to change from time to
                          time as communicated by company for all LAVA products,
                          accessories, cosmetic parts or any other component(s)
                          of the aforesaid LAVA products.
                        </li>

                        <li>
                          Lava holds discretion to withdraw the offer at any
                          point of time without any prior notice or liability to
                          the Customer.
                        </li>

                        <li>
                          All disputes are subject to the jurisdiction of the
                          Courts of New Delhi only.
                        </li>

                        <li>
                          In the event the delivery is not taken by customer
                          from LAVA within 60 days from the date of written
                          intimation by LAVA, LAVA reserves the right to auction
                          the product to recover the repair and auction charges
                          from the customer.
                        </li>

                        <li>
                          Terms of warranty are subjected to change from time to
                          time as communicated by company for all LAVA products,
                          accessories, cosmetic parts or any other component(s)
                          of the aforesaid LAVA products.
                        </li>
                      </ol>
                    </div>
                  </div>
                  {/* <!-- Element Warranty Policy --> */}
                  <div className="full-wrap terms" hidden>
                    <div className="ip_content">
                      <h4
                        className="title">
                        LAVA Feature Phone Free Recharge Offer
                      </h4>
                      <p>
                        Here is a special offer for selected lucky LAVA Feature
                        Phone customers. The offer includes a distinct code that
                        will be sent to select few customer, which they can
                        redeem by sending SMS LAVARO&lt;SPACE&gt;UNIQUE
                        CODE&lt;SPACE&gt;New Feature Phone's IMEI number
                        &lt;SPACE&gt;Own mobile number to 9311819494. This will
                        ensure that they get a free{" "}
                        <span className="inr">`</span>100 recharge on their
                        Mobile number.{" "}
                      </p>
                      <ol className="list-decimal">
                        <li>
                          This "LAVA Feature Phone Recharge Offer" is only valid
                          at the time of purchase of a new LAVA Feature Phone.
                          It will be invalid for LAVA Smartphones.
                        </li>
                        <li>
                          This offer will be applicable only if the consumer
                          buys a Lava feature phone within 1 month of receiving
                          the distinct code.
                        </li>
                        <li>
                          One distinct code will be valid only for a single
                          one-time redemption.
                        </li>
                        <li>
                          Customer availing the offer will get Rs. 100 recharge
                          only after completion of the redemption process and on
                          the mobile number that he will send in the SMS (one
                          that he will send along with the unique code and IMEI
                          number).
                        </li>
                        <li>
                          For any queries, customer can reach out to the Toll
                          free number - 1860 500 5001.
                        </li>
                        <li>
                          Please note that the distinct code will be shared with
                          only selected LAVA Feature Phone users.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Condition;
