import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {useParams,Link} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {basepath,baseurl} from '../basepath'
import getHeaderToken from '../service/generate-token'
import PlaceholderBox from '../components/PlaceholderBox'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

const url = process.env.REACT_APP_API_URL;

const Product = () => {
  var storeId = 0
  const {slug} = useParams()
  const [productImagePath, setProductImagePath] = useState('')
  const [products, setProducts] = useState([])
  const [show, setShow] = useState(false)
  const [path, setPath] = useState('')
  const [product, setProduct] = useState({})
  const [productGallery, setProductGallery] = useState([])
  const [count, setCount] = useState(0)
  const [productSorting, setProductSorting] = useState("latest");
  const [currency_code, setCurrency_code]= useState(localStorage.getItem('currency_code') || 'Rs');
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {

  setCurrency_code(localStorage.getItem('currency_code') || 'Rs');
    
  }, [localStorage.getItem('currency_code')])

  const [productSlug,setProductSlug] = useState("")

  if (typeof window !== 'undefined') {
    storeId = window.localStorage.getItem('storeId')
  }

  const handleClose = () => setShow(false)
  //   const handleShow = () => setShow(true);

  const getAllProduct = async () => {
    try {
      const res = await axios.get(
        `${url}/products/${slug}/${storeId}`,
        {...getHeaderToken.headers, params: {sort: productSorting}}
      )
      setProductImagePath(res.data.path)
      setProducts(res.data.products)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllProduct()
  }, [storeId,slug, productSorting])

  const getOverView = async (slug) => {
    setShow(true)
    try {
      const {data} = await axios.get(
        `${url}/product_overview/${slug}/${storeId}`
      )
      setPath(data.path)
      setProduct(data.products)
      setProductSlug(data.products.slug)
      setProductGallery(data.product_gallery)
    } catch (error) {
      console.log(error)
    }
  }
  setTimeout(()=>{
    setLoading(false)
   },1000)

  return (
    <>
      <section className='page_title inner_breadcum detailpage_breadcum'>
        <div className='full_container'>
        <h4>{slug}</h4>
        </div>
      </section>
      <section className='breadcurms  '>
        <div className='full_container'>
          <ul className='text-left'>
            <li>
              <Link to={`${basepath}/`}>Home</Link>
            </li>
            <li> Product</li>
          </ul>
        </div>
      </section>

      <section className='produc_list'>
        <div className='full_container'>
          <div className='product_head_1'>
            <div className='row'>
              <div className='col-md-12'>
                <h4>{products?.data?.length} Products</h4>
                <div className='selec_coun'>
                  <small>Sort By: </small>
                  <select onChange={(e)=>{
                    setProductSorting(e.target.value)
                    }} 
                    defaultValue={productSorting}>
                    <option value={"latest"}>Latest First</option>
                    <option value={"price_asc"}>Low to High</option>
                    <option value={"price_dsc"}>High to Low</option>
                    
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className='product_list'>
        <div className='full_container'>
          <div className='row'>
            {/*{products?.data?.slice(0,1).map((product) => (
              <div className='col-md-3 wow animated  fadeIn' key={product.id}>
                <div className='one_produ_1'>
                  <Link to={`${basepath}/lavaBlaze`}>
                    <div className='produc_img'>                    
                      <img src={`${productImagePath}/${product.image}`} alt='img' />                    
                    </div>
                  </Link>
                  <div className='product_content'> 
                      <a href="#"><h2>{product.title}</h2></a> 
                  </div>
                  <div className='product_pricing'>
                    <h4>Rs. {product.price} </h4>
                    <Link
                      onClick={() => getOverView(product.slug)}
                      data-toggle='modal'
                      data-target='#quick_view'
                    >
                      <img src={`${baseurl}${basepath}/images/eye_icon.png`} alt='' /> Quick View
                    </Link>
                  </div>
                </div>
              </div>
            ))}*/}
           {/* {products?.data?.slice(1,2).map((product) => (
              <div className='col-md-3 wow animated  fadeIn' key={product.id}>
                <div className='one_produ_1'>
                  <Link to={`${basepath}/blazepro`}>
                    <div className='produc_img'>
                      <img src={`${productImagePath}/${product.image}`} alt='img' />
                    </div>
                  </Link>
                  <div className='product_content'> 
                    <a href="#"><h2>{product.title}</h2></a> 
                  </div>
                  <div className='product_pricing'>
                    <h4>Rs. {product.price} </h4>
                    <Link
                      onClick={() => getOverView(product.slug)}
                      data-toggle='modal'
                      data-target='#quick_view'
                    >
                      <img src={`${baseurl}${basepath}/images/eye_icon.png`} alt='' /> Quick View
                    </Link>
                  </div>
                </div>
              </div>
            ))}*/}
            {products?.data?.map((product) => (
              isLoading ? <PlaceholderBox/> : (
                <div className='col-md-3' key={product.id}>
                <AnimationOnScroll animateIn="animate__fadeInUp" initiallyVisible={true}>
                <div className='one_produ_1 card shadow rounded border p-4'>
                  <Link to={`${basepath}/product-details/${product.slug}`}>
                    <div className='produc_img'>
                      <img src={`${productImagePath}/${product.image}`} alt='img' />
                    </div>
                  </Link>
                  <div className='product_content'>
                     <Link to={`${basepath}/product-details/${product.slug}`}> 
                       <h1>{product.title}</h1> 
                      </Link> 
                  </div>
                  <div className='product_pricing'>
                  <h4 className={`${product.price > 0 ? 'd-block': 'd-sm-none'}`}>{currency_code}. {product.price} </h4>
                    <Link
                      onClick={() => getOverView(product.slug)}
                      data-toggle='modal'
                      data-target='#quick_view'
                    >
                      <img src={`${baseurl}${basepath}/images/eye_icon.png`} alt='' /> Quick View
                    </Link>
                  </div>
                </div>
                  </AnimationOnScroll>
              </div>
              )
            ))}
          </div>
        </div>
      </section>
     
      <Modal show={show} size='lg' onHide={handleClose} className='shadow-lg'>
        <Modal.Body className='rounded-0'>
          <div className='quick_view_body rounded-0'>
            <button
              type='button'
              onClick={handleClose}
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
            <div className='quick_vie_img'>
              <div className='clearfix'>
                <div className='gallery'>
                  <div className='previews'>
                    {productGallery?.map((item, i) => (
                      <Link
                        role='button'
                        key={i}
                        onClick={() => setCount(i)}
                        className=''
                        data-full={`${productImagePath}/${productGallery[i]?.image}`} 
                      >
                        <img src={`${productImagePath}/${productGallery[i]?.image}`} alt="" />
                      </Link>
                    ))}
                  </div>
                  <div className='full'>
                    <img src={`${productImagePath}/${productGallery[count]?.image}`} alt=""/>
                  </div>
                </div>
              </div>
            </div>
            <div className='quickbody_content'>
              <h4>{product.title}</h4>
              {
                <div dangerouslySetInnerHTML={{__html:product.short_description}}></div>
              }
              <div className='quick_vi_pric'>{currency_code}. {product.price}</div>
              <Link to={`${basepath}/product-details/${productSlug}`} className='view_more rounded-0'>View More Details</Link>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}

export default Product
