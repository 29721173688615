import React from 'react'
import '../../styles/yuva2proj.scss'
import {basepath,baseurl} from '../../basepath'
import AOS from 'aos'
export default function Promode() {
    AOS.init();
  return (
    <div className='promode_bg'>
        <div className="title_container">
            <h2 className='text-uppercase'>Pro Mode</h2>
            <h4>Unleash your creativity and capture amazing shots with pro mode.</h4>
            <p>Love taking photos in your own style! Here is the pro mode for the pro you.
                Do it your way - adjust exposure, white balance, focus and more. </p>
                <img src={`${baseurl}${basepath}/images/yuva2pro/pro-mode-text.png`} className='img-fluid promode-text' alt="Yuva 2 Pro" />    
        </div>
        <div className='promode'>
                <div className="mobile-promode_img" data-aos="fade-down" data-aos-duration="3000" data-aos-easing="linear">
                   <img src={`${baseurl}${basepath}/images/yuva2pro/pro-mode-mobile.png`} className='img-fluid' alt="Yuva 2 Pro" /> 
                   <div className="shadow">
                   <img src={`${baseurl}${basepath}/images/yuva2pro/shadow-promode-mobile.png`} className='img-fluid' alt="Yuva 2 Pro" /> 
                        
                    </div> 
                </div>
        </div>
    </div>
  )
}
