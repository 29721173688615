import React,{useEffect} from 'react'
import  "../styles/yuva2proj.scss";
import AOS from 'aos'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {basepath,baseurl} from '../basepath'
import { Link } from 'react-router-dom'
import InfographicUSP from '../components/yuvaCom/InfographicUSP';
import ElegantScreen from '../components/yuvaCom/ElegantScreen';
import TripleCamera from '../components/yuvaCom/TripleCamera';
import Promode from '../components/yuvaCom/Promode';
import Multitask from '../components/yuvaCom/Multitask';
import Coreprocessor from '../components/yuvaCom/Coreprocessor';
import Highsecurity from '../components/yuvaCom/Highsecurity';
import Marathonbattery from '../components/yuvaCom/Marathonbattery';
import Cleanui from '../components/yuvaCom/Cleanui';
import AutoRecording from '../components/yuvaCom/AutoRecording';

export default function Yuvapro() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return (
    <>
    <section className='page_title inner_breadcum detailpage_breadcum'>
        <div className="full_container">
            <div className="left">
                <h4>Yuva 2 Pro</h4>
            </div>
            <div className="right">
                <Link to='/'>Overview</Link>
                <Link to='/'>Specification</Link>
            </div>
        </div>
    </section>
    <section className='banner'>
        <div className='_bg'>
            <LazyLoadImage src={`${baseurl}${basepath}/images/yuva2pro/bg-yuva2pro.png`} alt="Yuva 2 Pro" />
            <div className='_images'>
                    <div className="_leftup" data-aos="fade-down" data-aos-duration="1000" data-aos-easing="linear">
                      <img src={`${baseurl}${basepath}/images/yuva2pro/up-phone1.png`} className='img-fluid layer1' alt="Yuva 2 Pro" />
                      <img src={`${baseurl}${basepath}/images/yuva2pro/up-phone2.png`} className='img-fluid layer2' alt="Yuva 2 Pro" />
                    </div>
                    <div className="_rightdown">
                        <img src={`${baseurl}${basepath}/images/yuva2pro/up-right-phone1.png`} className='img-fluid layer3' alt="Yuva 2 Pro" data-aos="fade-left" data-aos-duration="1000" data-aos-easing="linear"/>
                        <img src={`${baseurl}${basepath}/images/yuva2pro/up-right-shadow.png`} className='img-fluid layer4' alt="Yuva 2 Pro" data-aos="zoom-in"  data-aos-duration="3000"/>
                        <img src={`${baseurl}${basepath}/images/yuva2pro/up-right-shadow1.png`} className='img-fluid layer5' alt="Yuva 2 Pro" data-aos="fade-down" />
                        <img src={`${baseurl}${basepath}/images/yuva2pro/down-right.png`} className='img-fluid layer6' alt="Yuva 2 Pro"  data-aos="fade-up" data-aos-duration="3000"/>
                    </div>
            </div>
            <div className="title">
                <h1 className='heading_first text-uppercase fw-bolder'>
                    Yuva 2 Pro
                </h1>
                <h4 className='heading_second text-uppercase'>The Elegant Performer</h4>
            </div>
        </div>
    </section>
        <InfographicUSP/>
        <ElegantScreen/>
        <TripleCamera/>
        <Promode/>
        <Multitask/>
        <Coreprocessor/>
        <Highsecurity/>
        <Marathonbattery/>
        <AutoRecording/>
        <Cleanui/>
    </>
  )
}
