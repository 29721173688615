import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import { basepath, baseurl } from '../basepath'
import CommingSoon from '../components/CommingSoon'

const url = process.env.REACT_APP_API_URL;

const Search = () => {
    var storeId = 0
    const { searchQuery } = useParams()
    const [productImagePath, setProductImagePath] = useState('')
    const [products, setProducts] = useState([])
    const [show, setShow] = useState(false)
    const [path, setPath] = useState('')
    const [product, setProduct] = useState({})
    const [productGallery, setProductGallery] = useState([])
    const [count, setCount] = useState(0)
    const [country, setCountry] = useState(1)

    const [productSlug, setProductSlug] = useState("")

    const handleClose = () => setShow(false)
    //   const handleShow = () => setShow(true);
    storeId = window.localStorage.getItem('storeId') 

    const getAllProduct = async (id) => {
        try {
            const res = await axios.get(
                `${url}/search/${id}/?q=${searchQuery}`
            )
            setProductImagePath(res.data.path)
            setProducts(res.data.products)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
     storeId = window.localStorage.getItem('storeId')
     getAllProduct(storeId)
    }, [searchQuery])

    const getOverView = async (slug) => {
        setShow(true)
        try {
            const { data } = await axios.get(
                `${url}/product_overview/${slug}/${storeId}`
            )
            setPath(data.path)
            setProduct(data.products)
            setProductSlug(data.products.slug)
            setProductGallery(data.product_gallery)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
        < section class = "page_title inner_breadcum detailpage_breadcum" > <div class="full_container">
            <h1>{searchQuery}</h1>
        </div></section>
            {products.length ? (
                <>
                            <section className='breadcurms  '>
                <div className='container-fluid'>
                    <ul className='text-left'>
                        <li>
                            <Link to={`${baseurl}/`}>Home</Link>
                        </li>
                        <li> Product</li>
                    </ul>
                </div>
            </section>

            <section className='produc_list'>
                <div className='container-fluid'>
                    <div className='product_head_1'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h4>{products?.data?.length} Products</h4>
                            </div>

                            <div className='col-md-6  text-right'>
                                <div className='selec_coun'>
                                    <small>Sort By: </small>
                                    <select>
                                        <option>POPularity</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='product_list'>
                <div className='container-fluid'>
                    <div className='row'>

                        {products?.map((product) => (
                            <div className='col-md-3 wow animated  fadeIn' key={product.id}>
                                <div className='one_produ_1'>
                                    <Link to={`${basepath}/product-details/${product.slug}`}>
                                        <div className='produc_img'>
                                            <img src={`${productImagePath}/${product.image}`} alt='img' />
                                        </div>
                                    </Link>
                                    <div className='product_content'>
                                        <Link to={`${basepath}/specification/${product.slug}`}>
                                            <h2>{product.title}</h2>
                                        </Link>
                                    </div>
                                    <div className='product_pricing'>
                                        <h4>Rs. {product.price} </h4>
                                        <Link
                                            // onClick={() => getOverView(product.slug)}
                                            data-toggle='modal'
                                            data-target='#quick_view'
                                        >
                                            <i className="fa fa-eye" aria-hidden="true"></i>  Quick View
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
                </>
            ): <CommingSoon title={'No Product Found!'}/>}
            <Modal show={show} size='lg' onHide={handleClose}>
                <Modal.Body>
                    <div className='quick_view_body'>
                        <button
                            type='button'
                            onClick={handleClose}
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'
                        >
                            <span aria-hidden='true'>&times;</span>
                        </button>
                        <div className='quick_vie_img'>
                            <div className='clearfix'>
                                <div className='gallery'>
                                    <div className='previews'>
                                        {productGallery?.map((item, i) => (
                                            <Link
                                                role='button'
                                                onClick={() => setCount(i)}
                                                className=''
                                                data-full={`${productImagePath}/${productGallery[count]?.image}`}
                                            >
                                                <img src={`${productImagePath}/${productGallery[count]?.image}`} alt="" />
                                            </Link>
                                        ))}
                                    </div>
                                    <div className='full'>
                                        <img src={`${productImagePath}/${productGallery[count]?.image}`} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='quickbody_content'>
                            <h4>{product.title}</h4>
                            {
                                <div dangerouslySetInnerHTML={{ __html: product.short_description }}></div>
                            }
                            <div className='quick_vi_pric'>Rs. {product.price}</div>
                            <Link to={`${basepath}/product-details/${productSlug}`} className='view_more'>View More Details</Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Search
