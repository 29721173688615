import React,{useEffect} from 'react'
import '../../styles/yuva2proj.scss'
import AOS from 'aos'
import {basepath,baseurl} from '../../basepath'

export default function ElegantScreen() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return (
    <div className='elegantscreen'>
        <div className="theelegant-text">
         <img src={`${baseurl}${basepath}/images/yuva2pro/the-elegant-text.png`} className='img-fluid' data-aos="fade-down" data-aos-duration="1000" data-aos-easing="linear" alt="Yuva 2 Pro" />
        </div>
        <h2 className='text-center'>The Elegant Performer!</h2>
        <p className='tedxt-center'>Yuva 2 Pro is more than just a phone - “It's a statement of who you are!” With its elegant glass back and three stunning yet graceful colors to choose from, you can express your style with ease. 
</p>
     <h4 data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear">You will love how Yuva 2 Pro reflects your personality and vibe, whether you are feeling classy, sassy, or flashy.</h4>
     <div className="standup_product">
     <img src={`${baseurl}${basepath}/images/yuva2pro/the-elegant.png`} className='img-fluid' data-aos="fade-down" data-aos-duration="1000" data-aos-easing="linear"
     alt="Yuva 2 Pro" />
     </div>
    </div>
  )
}
