import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import parse from 'html-react-parser'
import { Table } from 'react-bootstrap';

const url = process.env.REACT_APP_API_URL;

const Specification = () => {
  var storeId = 0
  const { slug } = useParams()

  // const [slug,setSlug] = useState(router.query.slug)
  const [product, setProduct] = useState({})

  const [productSpecifaction, setProductSpecifaction] = useState([])
  const [path, setPath] = useState('')
  const [bannerpath, setBannerPath] = useState('')

  const [isDetailVisible, setIsDetailVisible] = useState(false)

  if (typeof window !== 'undefined') {
    storeId = window.localStorage.getItem('storeId')
  }

  const getProductSecification = async () => {
    try {
      const res = await axios.get(
        `${url}/product_specify/${slug}/${storeId}`
      )
      setProductSpecifaction(res.data?.product_specification)
      setPath(res.data?.path)
      setBannerPath(res.data?.bannerpath)
      setProduct(res.data.products)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProductSecification()
  }, [slug, storeId])

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === 'remove') {
        return <></>
      }
    },
  }

  return (
    <>
      <section className='page_title inner_breadcum specification_breadcum detailpage_breadcum'>
        <div className='full_container'>
          <div className="left">
            <h4>{product.title}</h4>
          </div>
          <div className="right specification">
            <Link to={`/product-details/${slug}`}>Overview</Link>
            <Link to={`/specification/${slug}`}>Specification</Link>
          </div>
          {/* <ul className='text-right container'>
            <li>
              <a href='#'>Overview</a>
            </li>
            <li> About Us</li>
          </ul> */}
        </div>
      </section>
      <div className='spec_banner'>
        <img src={`${bannerpath}/${product?.banner_image}`} alt='img' />
      </div>

      <div className='spec_page'>
        <div className='bg-white'>
        <Table className='full_container' responsive striped>
          {productSpecifaction.map((item) => item.title == "product summary" && parse(item.description, options))}
        </Table>
        </div>
        {isDetailVisible ? <Table className='spect-width' responsive>
          <tbody>
            {
              productSpecifaction.map((item) => item.title != "product summary" && (
                <>
                  <tr key={item.id}>
                    <td className='spectification_title'><h5>{item.title} </h5></td>
                    <td>
                      {parse(item.description, options)}
                    </td>
                  </tr>
                </>
              ))
            }
          </tbody>
        </Table> : null}

      </div>

      {/* {isDetailVisible ? <section>
        <div className='full_container'>
          {productSpecifaction?.map((item) => (
            <>
              <div className='one_features' key={item.id}>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='spectification_title'>
                      <h5>{item.title} </h5>
                    </div>
                  </div>

                  <div className='col-md-8'>
                    {parse(item.description, options)}
                  </div>
                </div>
              </div>
              <div className='breaker'></div>
            </>
          ))}

          <p className='gray'> * - Under LAVA Testing Conditions</p>
        </div>
      </section> : null} */}

      <div className='spe_show_more_btn' onClick={() => {
        setIsDetailVisible(!isDetailVisible)
      }} >
        <a>{isDetailVisible ? "Hide Details" : "Show Details"}</a>
      </div>

      <div className='clear'></div>
      <div
        className='modal fade'
        id='quick_view'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='quick_view_body'>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>

                <div className='quick_vie_img'>
                  <div className='clearfix'>
                    <div className='gallery'>
                      <div className='previews'>
                        <a href='#' className='selected' data-full='/images/one_product2.jpg'>
                          <img src='/images/one_product2.jpg' />
                        </a>
                        <a href='#' data-full='/images/one_product3.jpg'>
                          <img src='/images/one_product3.jpg' />
                        </a>
                        <a href='#' data-full='images/one_product4.jpg'>
                          <img src='/images/one_product4.jpg' />
                        </a>
                      </div>
                      <div className='full'>
                        <img src='/images/one_product2.jpg' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='quickbody_content'>
                  <h4>Blaze Pro</h4>
                  <ul>
                    <li>Premium Frosted Glass Back Design</li>
                    <li>50MP Triple AI Rear Camera</li>
                    <li>64GB ROM + (4+3)GB RAM</li>
                    <li>Powered by Android 12 OS</li>
                  </ul>
                  <div className='quick_vi_pric'>Rs. 10499</div>
                  <a href='#' className='view_more'>
                    View More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Specification
