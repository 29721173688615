import React from 'react'
import '../../styles/yuva2proj.scss'
import AOS from 'aos'
import {basepath,baseurl} from '../../basepath'

export default function InfographicUSP() {
    AOS.init();
  return (
    <div className='infographic'>
        <div className='_main_info'>
                <div className="_column_one">
                            <div className="_columns">
                                <h4 className='title_fourth'>Glass Back Design</h4>
                            <img src={`${baseurl}${basepath}/images/yuva2pro/glass-back-design.png`} className='img-fluid layer6' alt="Yuva 2 Pro" />
                            </div>
                            <div className="_columns">
                                    <div className="_columns_div">
                                    <h4 className='title_fourth'>13MP Triple <br/>AI Camera</h4>
                                        <img src={`${baseurl}${basepath}/images/yuva2pro/13mp.png`} className='img-fluid layer6' alt="Yuva 2 Pro" />
                                    </div>
                                    <div className="_columns_div">
                                    <h4 className='title_fourth'>6.5” IPS HD+ </h4>
                                       <img src={`${baseurl}${basepath}/images/yuva2pro/6.5ips_hd.png`} className='img-fluid layer6' alt="Yuva 2 Pro" />
                                    </div>
                            </div>

                      <div className="_column_one_last">
                      <h4 className='title_fourth'>MediaTek G37 <br/>
2.3GHz Super Fast</h4>
                         <img src={`${baseurl}${basepath}/images/yuva2pro/mediatek.png`} className='img-fluid layer6' alt="Yuva 2 Pro" />
                      </div>      
                </div>

                <div className="_column_two">
                        <div className="_columns_div">
                        <h4 className='title_fourth'>7GB RAM+ <br/>
(4GB + 3GB*)</h4>
                        <img src={`${baseurl}${basepath}/images/yuva2pro/7gb_ram.png`} className='img-fluid layer6' alt="Yuva 2 Pro" />
                        </div>
                        <div className="_columns_div">
                        <h4 className='title_fourth'>
                        Full Day Battery <br/> 5000mAH
                        </h4>
                        <img src={`${baseurl}${basepath}/images/yuva2pro/fullday_battery.png`} className='img-fluid layer6' alt="Yuva 2 Pro" />
                        </div>
                </div>
        </div>
    </div>
  )
}
