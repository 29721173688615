import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {basepath,baseurl} from '../basepath'
import '../styles/about.scss'

const About = () => {

  const [show1,setShow1] = useState(false)
  const [show2,setShow2] = useState(false)
  const [show3,setShow3] = useState(false)


  const handleClose1 =()=>setShow1(false)
  const handleClose2 =()=>setShow2(false)
  const handleClose3 =()=>setShow3(false)


  const handelModel1 =()=>{
    setShow1(true)
  }
  const handelModel2 =()=>{
    setShow2(true)
  }
  const handelModel3 =()=>{
    setShow3(true)
  }


  return (
    <>
      <div class="inner_banner">
		<img src={`${baseurl}${basepath}/images/inner_bannner2.jpg`} alt="Image" />
		<div class="container-fluid">
			<div class="inner_banner_content">
				<h2>Our Vision</h2>
				<p>To empower people to do more, to be more</p>
			</div>
		</div>

	</div>


	<section class="breadcurms  ">
		<div class="full_container">
			<ul class="text-left">
				<li><Link to={`${basepath}/`}>Home</Link></li>
				<li> About Us</li>
			</ul>
		</div>
	</section>


	<section className='about__container'>
		<div class="full_container">
			<div class="contact_1">
				<div class="row">
					<div class="col-md-12">
						<div class="section_title">
							<h4>OUR CULTURE AND PHILOSOPHY</h4>
							<div class="breaker"></div>

						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-md-6">
						<div class="section_title gray">
							<p>A strong culture is what separates great companies from those that perish sooner or
								later. As everything else, be it an invention or any technology, it can be equaled, if
								not bettered. Building a strong culture by working with exceptional, like-minded people
								and having a strong infrastructure of systems and processes to enable the envisioned
								culture is our highest priority.
							</p>
							<p><strong>Nothing in nature, whether living or inert, exists for its own sake.
								</strong>Everything exists to
								serve something other than itself. The sun, the air, the earth, every tree, animal or
								even natural resources are examples of this. The greater its contribution to others, the
								greater is the power that is bestowed upon it. As human beings, at the smallest level,
								we exist for our immediate families. As we allow the radius to expand, it covers the
								larger community that we are a part of, and eventually we exist for all of humanity. The
								more number of people we impact, the greater is the power that we receive from the
								cosmos. It is the fundamental law of nature.</p>

							<p> It is the conscious understanding of this simple universal principle that forms the core
								purpose of Lava - “<strong>To empower people to do more, to be more.</strong>” This
								includes all
								stakeholders – consumers, partners, employees, shareholders and society as a whole.</p>

							<p>External circumstances are more or less the same for everyone, so it stands to reason
								that the main difference between success and failure is purely internal. For the
								individual, the internal factor is their character, and for the collective, it is
								culture, which comprises the shared beliefs, behaviors and actions of a group of people.
								Lava follows a belief system that anything is possible when conceived with clarity and
								pursued with commitment, conviction, focus and determination. It is expressed as -
								<strong>Create Possibilities. </strong>While belief forms the foundation of a culture,
								values are its
								pillars. For Lava, these pillars are built on the dictum of “<strong>do what is right
									and do it
									right</strong>”, which are manifested into our values of <strong>integrity, passion
									for excellence and adaptability. </strong>
							</p>

						</div>
					</div>
					<div class="col-md-6">
						<img src={`${baseurl}${basepath}/images/about_r_side.jpg`} className='card-img-top shadow-sm p-3' alt="Image"/>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<div class="section_title">
							<br></br>
							<h4>CORE VALUES</h4>
							<div class="breaker"></div>

						</div>
					</div>
				</div>

				<div class="core_balue_s">
					<div class="row">
						<div class="col-md-4">
							<div class="one_core_value">
								<h4>Integrity</h4>
								<img src={`${baseurl}${basepath}/images/core_value1.png`} alt="Image" />
								<p>Steadfast adherence to high moral and professional standards. We should have the same
									qualities that we seek from others, to be of value to others. It is from this
									premise that we have adopted the value of integrity, which is essentially about
									doing the right things.</p>
							</div>
						</div>


						<div class="col-md-4">
							<div class="one_core_value">
								<h4>Passion For Excellence</h4>
								<img src={`${baseurl}${basepath}/images/core_value2.png`} alt="Image" />
								<p>Intense keenness to stretch the limits of greatness in everything we do. The desire
									to go into the details to achieve the highest standards of quality with utmost
									efficiency so that we can achieve great things, together. Excellence is a continuous
									cycle of rigorous planning, execution and measurement.</p>
							</div>
						</div>

						<div class="col-md-4">
							<div class="one_core_value">
								<h4>Adaptability</h4>
								<img src={`${baseurl}${basepath}/images/core_value3.png`} alt="Image" />
								<p>The ability to quickly evolve in response to changing circumstances. Possessing the
									humility to truly understand the principle of constant change, along with the
									nimbleness to quickly evolve and adapt to it, is crucial for survival.</p>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<div class="section_title">
							<br></br>
							<h4>LEADERSHIP</h4>
							<div class="breaker"></div>

						</div>
					</div>
				</div>


				<div class="our_leadership">
					<div class="row">
						<div class="col-md-8">
							<div class="our_leadship_content">
								<h4>Hari Om Rai</h4>
								<h6>Chairman & Managing Director</h6>

								<p>In 2009, Hari Om Rai established Lava International with the vision - “To empower
									people to do more, to be more”. Lava has shown tremendous growth over the years with
									its annual turnover crossing $1.2 Billion.</p>
								<p>
									Hari Om Rai is the Co-Chairman of Government of India’s Fast Track Task Force to
									catalyze and re-establish the mobile handsets manufacturing eco-system in India. He
									is also the proud winner of the coveted ‘Entrepreneur of The Year’ award at The 9th
									Asia Pacific Entrepreneurship Awards 2018 India.</p>
							</div>
						</div>
						<div class="col-md-4">
							<div class="leadship_image">
								<img src={`${baseurl}${basepath}/images/leader_img1.png`} alt="Image" />
							</div>
						</div>


					</div>
				</div>

				<div class="team_players">
					<div class="row">
						<div class="col-md-4">
							<div class="one_team_pl card">
							<img src={`${baseurl}${basepath}/images/team22.jpg`} className="card-img-top" alt="Image" />
								<div class="team_content2 card-body">
									<div class="content_le_team">
										<h4>Sunil Bhalla</h4>
										<h6>Co-founder and Director</h6>
									</div>
									<div class="content_righ_team">
										<Link onClick={handelModel1} data-toggle="modal" data-target="#exampleModalCenter">+</Link>
									</div>

								</div>

							</div>
						</div>

						<div class="col-md-4">
							<div class="one_team_pl card">
									<img src={`${baseurl}${basepath}/images/team23.jpg`} className='card-img-top' alt="Image" />
								
								<div class="team_content2 card-body">
									<div class="content_le_team">
										<h4>Shailendra Nath Rai</h4>
										<h6>Co-founder and Director</h6>
									</div>
									<div class="content_righ_team">
										<Link onClick={handelModel2} data-toggle="modal" data-target="#exampleModalCenter2">+</Link>
									</div>

								</div>

							</div>
						</div>


						<div class="col-md-4">
							<div class="one_team_pl card">
									<img src={`${baseurl}${basepath}/images/team24.jpg`} className='card-img-top' alt="Image" />
								
								<div class="team_content2 card-body">
									<div class="content_le_team">
										<h4>Vishal Sehgal</h4>
										<h6>Co-founder and Director</h6>
									</div>
									<div class="content_righ_team">
										<Link onClick={handelModel3} data-toggle="modal" data-target="#exampleModalCenter3">+</Link>
									</div>

								</div>

							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<div class="section_title">
							<br></br>
							<h4>Our Impact</h4>
							<div class="breaker"></div>

						</div>
					</div>
				</div>

				<div class="about_impect_s">
					<div class="one_team_impect">
						<img src={`${baseurl}${basepath}/images/impect1.jpg`} alt="Image"/>
						<h6>13+</h6>
						<p>Year Exeripence</p>
					</div>

					<div class="one_team_impect">
						<img src={`${baseurl}${basepath}/images/impect2.jpg`} alt="Image" />
						<h6>30,000+</h6>
						<p>People with with Us</p>

					</div>


					<div class="one_team_impect">
						<img src={`${baseurl}${basepath}/images/impect3.jpg`} alt="Image" />
						<h6>1.65 <span class="small">Lack</span></h6>
						<p>People with with Us</p>

					</div>


					<div class="one_team_impect">
						<img src={`${baseurl}${basepath}/images/impect4.jpg`} alt="Image" />
						<h6>1000</h6>
						<p>Distributors</p>

					</div>

					<div class="one_team_impect">
						<img src={`${baseurl}${basepath}/images/impect5.jpg`} alt="Image" />
						<h6>8000+</h6>
						<p>Professionally managed service center</p>

					</div>

				</div>


				<div class="impect_details">
					<div class="row">
						<div class="col-md-6">
							<div class="impec_img gray">
								<p>Lava International Limited is a leading Mobile Handset Company in India and has
									expanded its operations to multiple countries across the world. Right from its
									inception Lava has been at the forefront of building a strong ecosystem of design
									and manufacturing of mobile handsets. The company has been building mobile handset
									design capability including industrial design, mechanical design, hardware design &
									software design. In line with the Govt. of India’s ‘Make in India’ initiative, Lava
									has taken a lead in support of this important nation building program and has
									already set up a design team in India, becoming the first to do so in the country.
									With this ‘Design in India’ initiative, Lava is now the only Mobile handset company
									that makes truly “Make In India” phones with complete control on design and
									manufacturing within India. Lava was also ranked as the ‘Most Trustworthy Brand’ in
									the CMR Retail Sentiment Index 2018.</p>
								<p>
									Lava products are available through a nationwide retail network of 1.65 Lakh
									retailers who in turn are directly served by over 1000 distributors. Lava’s after
									sales service network is one of the largest in the country with close to 800+
									professionally managed service centers focused on speed and quality of service.</p>

							</div>
						</div>

						<div class="col-md-6">
							<div class="impec_img33">
								<img src={`${baseurl}${basepath}/images/impct_side_br.jpg`} className='card-img-top shadow-sm p-3' alt="Image" />
							</div>
						</div>
					</div>
				</div>




				<div class="row" style={{display:"none"}}>
					<div class="col-md-12">
						<div class="section_title">
							<br></br>
							<h4>Location Worldwide</h4>
							<div class="breaker"></div>

						</div>
					</div>
				</div>

				<div class="row" style={{display:"none"}}>
					<div class="col-md-12">
						<div class="head_ofice_l">
							<h5><strong>Head Office: </strong>Noida, India </h5>
							<hr />
							<h5><strong>Operations: </strong></h5>

							<ul class="listing">
								<li>India</li>
								<li>Thailand</li>
								<li>Indonesia</li>
								<li>Mexico </li>
								<li>China</li>
								<li>Egypt</li>
								<li>Middle East</li>
								<li>Nepal</li>
								<li>Bangladesh </li>
								<li>Sri Lanka</li>
								<li>Dubai</li>
								<li>Hong Kong</li>
								<li>Singapore</li>
								<li>USA</li>
								<li>Egypt</li>
								<li>Ghana</li>
								<li>Kenya</li>
								<li>Nigeria</li>
								<li>Mexico</li>
								<li>KSA ( Saudi Arabia)</li>
								<li>Kuwait </li>
								<li>Qatar</li>
								<li>Romania</li>
								<li>Papua New Guinea</li>
								<li>Switzerland</li>
								<li>British Virgin Island </li>
								<li>Taiwan</li>
								<li>Somalia </li>
								<li>Mauritius </li>
								<li>Israel</li>
								<li>Dublin </li>
								<li>Sweden </li>
								<li>Greece</li>

							</ul>

						</div>
					</div>
				</div>


			</div>
		</div>

	</section>
  <Modal show={show1} size='lg' onHide={handleClose1} className='shadow-lg'>
        <Modal.Body className='p-0 rounded-0 border-0'>
			<div class="modal-content p-0 rounded-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLongTitle">Sunil Bhalla, Co-founder and Director</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={handleClose1}>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="profile_team_popup">
						<div class="row">
							<div class="col-md-4">
								<div class="team_profile">
										<img src={`${baseurl}${basepath}/images/team22.jpg`} className='rounded-0' alt="Image" />
								</div>
							</div>

							<div class="col-md-8">
								<div class="team_profile_content">
									<p>Sunil Bhalla is a professional turned serial entrepreneur. A Mechanical engineer & an MBA from IMT, he has worked with Maruti Udyog Limited in the past. At Lava International Limited, he contributes in Strategic Planning. 
									</p>
								</div>
							</div>
						</div>

					</div>
				</div> 
			</div>
        </Modal.Body>
  </Modal>
  <Modal show={show2} size='lg' onHide={handleClose2} className='shadow-lg'>
         <Modal.Body className='p-0 rounded-0 border-0'>
			<div class="modal-content p-0 rounded-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLongTitle">Shailendra Nath Rai, Co-founder & Director</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={handleClose2}>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="profile_team_popup">
						<div class="row">
							<div class="col-md-4">
								<div class="team_profile">
										<img src={`${baseurl}${basepath}/images/sn-rai.jpg`} alt="Image"/>
								</div>
							</div>

							<div class="col-md-8">
								<div class="team_profile_content">
									<p> A renowned business professional with over 24 years of varied industry
										experience, Shailendra Nath Rai is fondly known as SNR. With a degree in
										Mechanical Engineering and a Green Belt Certification in Six Sigma, SNR is one
										of the leading professionals in Supply Chain Management in the country today. He
										has been a part of the greatly acclaimed sponsored research on “Perpetual Supply
										Demand Balancing” with the Indian School of Business (ISB).

									</p>
								</div>
							</div>
						</div>

					</div>
				</div> 
			</div>
        </Modal.Body>
  </Modal>
  <Modal show={show3} size='lg' onHide={handleClose3} className='shadow-lg'>
  <Modal.Body className='p-0 rounded-0 border-0'>
			<div class="modal-content p-0 rounded-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLongTitle">Vishal Sehgal, Co-founder & Director</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={handleClose3}>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="profile_team_popup">
						<div class="row">
							<div class="col-md-4">
								<div class="team_profile">
										<img src={`${baseurl}${basepath}/images/team24.jpg`} alt="Image" />
								</div>
							</div>

							<div class="col-md-8">
								<div class="team_profile_content">
									<p> From management trainee at Marico to CEO at Airtel Rajasthan, Vishal has seen business in its various hues. Having worked both in startups like Marico (1992) and Zip Telecom (2001), as well as large organizations like Pepsico India (1998) and Airtel (2005), Vishal has a rich blend of entrepreneurial and professional experience. In his last stint at Airtel Rajasthan before co-founding Lava International, he led the business to a dominant 45% revenue market share in a multi-player industry.

										Vishal brings to the table a vast, first-hand knowledge of consumer brands and the telecom business. Being "tech-curious" at heart, among other business priorities, he leads the technology evolution practices in the company.
										
										

									</p>
								</div>
							</div>
						</div>

					</div>
				</div> 
			</div>
        </Modal.Body>
  </Modal>

    </>
  )
}

export default About
