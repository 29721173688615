import axios from 'axios'
import React,{useEffect, useState} from 'react'
import {Link,useLocation} from 'react-router-dom'
import {basepath,baseurl} from '../basepath'
import swal from 'sweetalert';
import getHeaderToken from '../service/generate-token'


const url = process.env.REACT_APP_API_URL;
let countryId = localStorage.getItem('storeId');
const Footer = () => {
  var storeId = 0
  const router = useLocation()
  const [email,setEmail] = useState("")
  const [error,setError] = useState("")
  const [country,setCountry] = useState("1")
  const [latestProductData,setLatestProductData] = useState([])
  const [productCategory,setProductCategory] = useState([])
  const [year,setYear] = useState(0)
  const [featurePhoneList,setFeaturePhoneList] = useState([])

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    // Event listener callback
    const handleOrientationChange = (event) => {
      setIsPortrait(event.matches);
    };

    // Add event listener for orientation change
    const mediaQueryList = window.matchMedia("(orientation: portrait)");
    mediaQueryList.addListener(handleOrientationChange);

    // Clean up the event listener when the component unmounts
    return () => {
      mediaQueryList.removeListener(handleOrientationChange);
    };
  }, []);

  if(typeof window !== 'undefined'){
		storeId = window.localStorage.getItem('storeId')	
  }
  const handleEmail =(e)=>{
		setEmail(e.target.value)
	}

  useEffect(()=>{
    window.scrollTo(0, 0);
    const year = new Date().getFullYear()
    setYear(year)
  },[router.pathname])


  const getLatestProduct = async()=>{
    try {
      const {data} = await axios.get(`${url}/latestProduct/${storeId}`)
      setLatestProductData(data.products)
    } catch (error) {
      console.log(error)
    }
  }

  const getProductCategory = async()=>{
    try {
      const {data} = await axios.post(`${url}/category_listing`,{store:storeId}, getHeaderToken)
      setProductCategory(data.category)
    } catch (error) {
      console.log(error)
    }
  }
  const getSubMenu =async()=>{
    try {
      const res = await axios.get(`${url}/menu/${countryId}`, getHeaderToken)
      console.log(res.data.category)
      setFeaturePhoneList(res?.data?.category[0].product)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(()=>{
    if(storeId!==null){
      getLatestProduct()
      getProductCategory()
      getSubMenu()
    }
    
  },[storeId])

  const subscription =async()=>{
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      try {
        const userData ={
          email:email,
          country:storeId
        }
        if(email!==''){
          const res =await axios.post(`${url}/newsletteradd`,userData)
          if(res.status === 200){
            setEmail("")
          } 
          if(res.status === 404){
            swal({
              title: res.data.message[0],
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            setEmail("")
          }
        }else{
          setError("Please insert email")
        }
        
      } catch (error) {
        console.log(error)
      }
    }


  return (
    <>
      <footer>
        <div className='full_container'>
          <div className='row'>
            <div className='col-md-2 wow animated  fadeIn'  >
              <div className='footer-logo'>
                <Link to={`${basepath}/`}>
                  <img src={`${baseurl}${basepath}/images/logo.png`} alt='logo' />
                </Link>
                <h4 className='footer_title' style={{display:"none"}}> Customer Care </h4>
                <Link style={{display:"none"}} href='mailto:lavacare@lavainternational.in'>
                  lavacare@lavainternational.in{' '}
                </Link>
                <Link style={{display:"none"}} href='tel: 1860-500-5001'> 1860-500-5001</Link>
                {/*<div className='bot_sele_country'>
                  <label className='mb-1'>Select Your Country</label>
                  <select>
                    <option>International</option>
                    <option></option>
                  </select>
                </div>*/}
              </div>
            </div>

            <div className='col-md-8'>
              <div className='footer_middle_links'>
                <div className='row'>
                  <div className='col-md-4'>
                    <h4 className='footer_title'> Products category </h4>
                    <ul className='wow animated  fadeIn' >
                      {
                        productCategory?.length>0 && productCategory.map((category,i)=>(
                          <li key={i}>
                          <Link to={`${basepath}/product/${category.slug}`}>{category.name}</Link>
                        </li>    
                        ))
                      }
                      {/* <li>
                        <Link href=''>Smartphone</Link>
                      </li>
                      <li>
                        <Link href=''>Feature Phone</Link>
                      </li>
                      <li>
                        <Link href=''>Audio</Link>
                      </li>
                      <li>
                        <Link href=''>Tablets</Link>
                      </li>
                      <li>
                        <Link href=''>Accessories</Link>
                      </li> */}
                    </ul>
                  </div>

                  <div className='col-md-4'>
                    <h4 className='footer_title'> Latest Phones </h4>
                    <ul className='wow animated  fadeIn' >
                      {
                        featurePhoneList?.length>0 && featurePhoneList.map((product,i)=>{
                          console.log(product)
                          return (
                            <li key={i}>
                          <Link to={`${basepath}/product-details/${product.slug}`}>{product.title}</Link>
                        </li>    
                          )
                        })
                      }
                      {/* <li>
                        <Link href=''>Blaze</Link>
                      </li>
                      <li>
                        <Link href=''>Agni 5G</Link>
                      </li>
                      <li>
                        <Link href=''>X2</Link>
                      </li>
                      <li>
                        <Link href=''>Z3 – Enterprise</Link>
                      </li>
                      <li>
                        <Link href=''>T81N</Link>
                      </li> */}
                    </ul>
                  </div>

                  <div className='col-md-4'>
                    <h4 className='footer_title'>Company </h4>
                    <ul className='wow animated  fadeIn'>
                      <li>
                        <Link to={`${basepath}/about`}>About Us </Link>
                      </li>
                      {/* <li>
                        <Link to={`${baseurl}/video`}>Videos </Link>
                      </li> */}
                      <li>
                        <Link to={`${basepath}/stores-and-dealers`}>Stores and Dealers </Link>
                      </li>
                      <li>
                        <Link to={`${basepath}/press-and-media`}>Press Release </Link>
                      </li>
                      <li>
                        <Link to={`${basepath}/privacy`}>Privacy Policy</Link>
                      </li>
                      <li>
                        <a href='https://www.lavamobiles.com/lava-quality-policy.pdf' target="_blank">Quality Policy</a>
                      </li>
                      <li>
                        <Link to={`${basepath}/condition`}>Terms and Condition</Link>
                      </li>
                      <li>
                        <Link to={`${basepath}/contact`}>Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-2'>
              <div className='medical_resouns Subscribe wow animated  fadeIn'>
                  <h4 className='footer_title'>Support</h4>
                  <ul>
                    {/* <li>
                      <Link to={`${basepath}/CustomerService`}>Customer Service</Link>
                    </li> */}
                    <li>
                    <Link to={`${basepath}/service-center`}>Service Center</Link>
                    </li>
                    <li>
                    <Link to={'#'}>User Manual</Link>
                    </li>
                  </ul>
              </div>
            </div>
            <div className='col-md-2' style={{display:"none"}}>
              <div className='medical_resouns Subscribe wow animated  fadeIn'>
                <h4 className='footer_title'>Subscribe</h4>
                <p> Keep me updated about devices, news, tips, and offers from Lava Store. </p>
                <form>
                  <input style={{width:'100%'}} type='email' placeholder='Enter email address' name='email' value={email} onChange={handleEmail} />
                  <input type='button' className='btn fa-input' value='&#xf061;' onClick={subscription} />
                </form>
                {email===''?<p style={{color:'red'}}>{error}</p>:""}
                <div className='footer_social_links'>
                  <ul>
                    <li>
                      <a href='https://www.facebook.com/lavamobiles' target='_blank'>
                        <i className='fa fa-facebook' aria-hidden='true'></i>{' '}
                      </a>
                    </li>
                    <li>
                      <a href='https://twitter.com/LAVAMobile' target='_blank'>
                        <i className='fa fa-twitter' aria-hidden='true'></i>
                      </a>
                    </li>
                    <li>
                      <a href='https://www.linkedin.com/uas/login-submit' target='_blank'>
                        <i className='fa fa-linkedin' aria-hidden='true'></i>
                      </a>
                    </li>
                    <li>
                      <a href='https://www.instagram.com/lava_mobiles/' target='_blank'>
                        <i className='fa fa-instagram' aria-hidden='true'></i>
                      </a>
                    </li>
                    <li>
                      <a href='https://www.youtube.com/user/LavaMobiles' target='_blank'>
                        <i className='fa fa-youtube-play' aria-hidden='true'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className='row footer_copyright'>
            <div className='col-md-12 center wow animated  fadeIn'>
              <p>Copyright © {year} Lava International Limited. All rights reserved.</p>
            </div>
          </div>

          <div className='row footer_desclaimer'>
            <div className='col-md-12 wow animated  fadeIn'>
              <p>
                <span>Disclaimer:</span> Product images, features, colors and specifications shown
                here are for representational purpose only and the actual may vary.
              </p>
              <p>
                <span> LAVA </span> is a registered trademark, wholly owned by M/s Lava
                International Limited. Any imitation of trademark and/or label is illegal and is a
                punishable offence.
              </p>
            </div>
          </div>
        </div>
      </footer>
      <div className='chat_fixed'>
        <Link href='#'>
          <img src={`${baseurl}${basepath}/images/whats_icon.png`} alt='' />
        </Link>
        <Link href='#'>
          <img src={`${baseurl}${basepath}/images/chat_icon.png`} alt='' />
        </Link>
      </div>
      <div className={`landscape ${isPortrait ? 'portrait' : 'landscape'}`}>

      </div>
    </>
  )
}

export default Footer
