import React from 'react'

export default function CustomerService() {
  return (
    <>
      <div className='full_container innerpage_toppadding customer_padding'>
          <div className='contact_1'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='section_title text-center'>
                  <h4>Customer Service</h4>
                  <div className='breaker'></div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='media_cnter helpline'>
                  <ul>
                    <li className='m_zero'>
                      <img src={`images/Support-icon.png`} alt='img' />
                      <h4>Helpline</h4>
                      <a href='tel:120 463 7100'>Tel: 120 463 7100</a> <br />
                      <a href='mailto:lavacare@lavainternational.in'>Email: lavacare@lavainternational.in</a> <br />
                      <a>Monday to Sunday</a> <br />
                      <a>10.00 AM till 7.00 PM</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
    </div>
    </>
  )
}
