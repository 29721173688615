import React from 'react'
import { Col, Card,Placeholder} from 'react-bootstrap'

export default function PlaceholderBox() {
  return (
    <>
    <Col md={3}>
        <Placeholder className="w-100 mb-3">
          <Card className='shadow-sm rounded-0'>
          <Placeholder animation="glow">
            <Placeholder xs={12} style={{height:'32vh'}} />
          </Placeholder>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> <Placeholder xs={8} />
          </Placeholder>
          <Placeholder.Button variant="primary" xs={6} />
        </Card.Body>
      </Card>
        </Placeholder>
      </Col>
    </>
  )
}
