import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {convert} from 'html-to-text'
import { Link } from 'react-router-dom';
import {basepath,baseurl} from '../basepath'

const url = process.env.REACT_APP_API_URL;

const StoresAndDealers = () => {
  var storeId = 0
  const [states, setStates] = useState([])
  const [districts, setDistricts] = useState([])
  const [stateValue, setStateValue] = useState(0)
  const [districtValue, setDistrictValue] = useState(0)

  const [retalerData, setRetalerData] = useState([])

  if (typeof window !== 'undefined') {
    storeId = window.localStorage.getItem('storeId')
  }

  const getAllState = async () => {
    try {
      const res = await axios.get(
        `${url}/state/${storeId}`
      )
      if (res.status === 201) {
        setStates(res.data.state)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllDistrict = async (id) => {
    try {
      const res = await axios.get(
        `${url}/district/${id}`
      )
      setDistricts(res.data.district)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllState()
  }, [storeId])

  const handleStateChange = (e) => {
    setStateValue(e.target.value)
    getAllDistrict(e.target.value)
  }

  const handleSearch = async () => {
    try {
      const userData = {
        country: storeId,
        state: Number(stateValue),
        district: Number(districtValue),
      }

      const res = await axios.post(
        `${url}/retailers`,
        userData
      )
      setRetalerData(res.data.retailer)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <section className="innerpage_toppadding">
        <div className='container'>
          <div className='contact_1 press_media'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='section_title'>
                  <h4>Retailer near you</h4>
                  <div className='breaker'></div>
                  <p className='gray'>
                    {' '}
                    Find our friendly retailer near you with just a click. Ask, interact, experience
                    our products and they would gladly offer the best of deals to you.{' '}
                  </p>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <div className='search_form_dealer'>
                  <div className='row'>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <select onChange={handleStateChange} className='form-control'>
                          <option>Select State...</option>
                          {states?.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name}{' '}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {districts?.length > 0 && (
                      <div className='col-md-3'>
                        <div className='form-group'>
                          <select
                            onChange={(e) => setDistrictValue(e.target.value)}
                            className='form-control'
                          >
                            <option>Select District...</option>
                            {districts?.map((district) => (
                              <option key={district.id} value={district.id}>
                                {district.name}{' '}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    {districtValue > 0 && (
                      <div className='col-md-2'>
                        <div className='form-group'>
                          <button onClick={handleSearch} className='btn btn-primary'>
                            Search
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='listof_vendor_sc'>
              <h4> {retalerData?.length} results found</h4>
              <div className='listof_vendor scrollbar'>
                <div className='row'>
                  {retalerData?.map((item) => (
                    <div className='col-md-3' key={item.id}>
                      <div className='one_vendor'>
                        <h5>{item.name} </h5>
                        <p>
                          {convert(item.description, {wordwrap: 130})} <br />
                          <strong>City </strong>- {item.city},<br />
                          <strong> District </strong>- {item.district},<br />
                          <strong>State </strong>- {item.state},
                        </p>
                        <p>
                          <strong>Contact Person:</strong> {item.contact_person},<br />
                          <strong>Phone:</strong> {item.phone}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StoresAndDealers
