import React from 'react'
import {basepath,baseurl} from '../../basepath'

export default function FingerPrint() {
  return (
    <div className='fingerprint'>
        <div className="_lft">
           <img src={`${baseurl}${basepath}/images/blaze2/battery-finger-touch.png`} className='img-fluid _lft_finger-touch' alt="Blaze 2" />
           <img src={`${baseurl}${basepath}/images/blaze2/battery-mobile1.png`} className='img-fluid _lft_mobile' alt="Blaze 2" />
           <img src={`${baseurl}${basepath}/images/blaze2/battery-mobile2.png`} className='img-fluid _rft_mobile' alt="Blaze 2" />
           <img src={`${baseurl}${basepath}/images/blaze2/battery-mobile-shadow.png`} className='img-fluid _rft_mobile_shadow' alt="Blaze 2" />

        </div>
        <div className="_rft">
            <h2>Side finger print sensor</h2>
            <p>No more fumbling with your phone to unlock it. Just swipe your finger on the side and voila! You're in. It's fast, ergonomic and on the go. 
                The side finger print sensor is the ultimate convenience for your device.</p>
        </div>
    </div>
  )
}
