import axios from 'axios'
import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {convert} from 'html-to-text'
import { Link } from 'react-router-dom';
import {basepath,baseurl} from '../basepath'

const url = process.env.REACT_APP_API_URL;

const PressAndMedia = ({data}) => {
  console.log(data)
  const [blogPath, setBlogPath] = useState('')
  const [blogData, setBlogData] = useState([])

  const getMediaPress = async () => {
    try {
      const res = await axios.get(
        `${url}/blog`
      )
      setBlogPath(res.data.blog_path)
      setBlogData(res.data.blog)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getMediaPress()
  }, [])

  return (
    <>
      <section className="innerpage_toppadding">
        <div className='full_container'>
          <div className='contact_1'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='section_title'>
                  <h4 style={{textAlign:"center"}}>Press Releases | LAVA in the news </h4>
                  <div className='breaker'></div>
                </div>
              </div>
            </div>

            <div className='listof_news scrollbar'>
              <div className='row'>
                {blogData?.map((item, i) => (
                  <div key={item.id} className='col-md-4'>
                    <div className='card rounded-0 shadow'>
					  <img src={`${blogPath}/${item.image}`} alt={item.title} className='card-img-top rounded-0' />
                      <div className="card-body">
                      <div className='news_content2'>
                        <small className='dates'>
                          {moment(blogData[i]?.created_at).format('MMM Do YYYY')}
                        </small>
                        <h4>{convert(item.title, {wordwrap: 130})}</h4>
                        <p>{convert(item.short_description, {wordwrap: 130})}</p>
                      </div>
                      <a href={`${item.url}`} className='read_more'>
                        Read More
                      </a>
					  </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='full_container'>
          <div className='contact_1'>
            <div className='new_short_lists scrollbar'>
              <div className='row'>
                {blogData?.map((item, i) => (
                  <div className='col-md-3' key={item.id}>
                    <div className='one_new_vendor'>
                      <h5>{moment(blogData[i]?.created_at).format('MMM Do YYYY')} </h5>
                      <div className='newdtesila_s'>
                        <p>{convert(item.short_description, {wordwrap: 130})}</p>
                      </div>

                      <a href={`${item.url}`} target="_blank"  className='read_more'>
                        Read More <i className='fa fa-caret-right' aria-hidden='true'></i>
                      </a>
                    </div>
                  </div>
                ))}

                {/* <div className="col-md-3">
								<div className="one_new_vendor">
									<h5>27 Dec 2021</h5>
									<div className="newdtesila_s">
										<p>Lava announces Widevine L1 update for its AGNI 5G Customers</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>


							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>10 Dec  2021</h5>
									<div className="newdtesila_s">
										<p>Lava introduces special EMI offers for Z3</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>


							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>12 Jan 2022 </h5>
									<div className="newdtesila_s">
										<p>Lava sets a new customer service 
											benchmark Announces a dedicated service manager for each AGNI 5G smartphone user</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>09 Nov 2022 </h5>
									<div className="newdtesila_s">
										<p>Lava showcases India’s prowess with the launch of its first 5G super smartphone- AGNI</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>




							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>10 Dec 2022 </h5>
									<div className="newdtesila_s">
										<p>Experience Unstoppable Fun with Lava Probuds N1</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>23 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Lava Probuds 2: Sound so good that it will make you want to Break Your Old Buds</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>24 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Design the next Indian Smartphone with Lava’s ‘Design in India’ season-2</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>25 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Lava announces first-of-its-kind 100 days Warranty on Out Warranty offer</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>19 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Get high on entertainment with all new Lava Z2s</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>




							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>26 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Powered by Indian Engineers, Lava announces Android 11 update for Z2, Z4, Z6 and MyZ triple camera variants</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>27 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>This World Music Day, groove uninterrupted with LAVA Probuds at just Rs.1</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>


							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>09 Nov 2022 </h5>
									<div className="newdtesila_s">
										<p>From College Students to Design Engineers: The heart-warming story of Lava’s Design in India contest winners</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>


							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>19 Dec 2022 </h5>
									<div className="newdtesila_s">
										<p>LAVA becomes the first mobile phone company to provide medical and accidental insurance cover to its partners’ field employees</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>


							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>23 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Lava Z2 Max- A bigger canvas for your child’s big dreams</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>

							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>27 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>This World Music Day, groove uninterrupted with LAVA Probuds at just Rs.1</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>


							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>12 Jan 2022 </h5>
									<div className="newdtesila_s">
										<p>Lava sets a new customer service 
											benchmark Announces a dedicated service manager for each AGNI 5G smartphone user</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>09 Nov 2022 </h5>
									<div className="newdtesila_s">
										<p>Lava showcases India’s prowess with the launch of its first 5G super smartphone- AGNI</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>




							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>10 Dec 2022 </h5>
									<div className="newdtesila_s">
										<p>Experience Unstoppable Fun with Lava Probuds N1</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>23 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Lava Probuds 2: Sound so good that it will make you want to Break Your Old Buds</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>24 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Design the next Indian Smartphone with Lava’s ‘Design in India’ season-2</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>25 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Lava announces first-of-its-kind 100 days Warranty on Out Warranty offer</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>19 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Get high on entertainment with all new Lava Z2s</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>




							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>26 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Powered by Indian Engineers, Lava announces Android 11 update for Z2, Z4, Z6 and MyZ triple camera variants</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>



							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>27 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>This World Music Day, groove uninterrupted with LAVA Probuds at just Rs.1</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>


							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>09 Nov 2022 </h5>
									<div className="newdtesila_s">
										<p>From College Students to Design Engineers: The heart-warming story of Lava’s Design in India contest winners</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>


							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>19 Dec 2022 </h5>
									<div className="newdtesila_s">
										<p>LAVA becomes the first mobile phone company to provide medical and accidental insurance cover to its partners’ field employees</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>


							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>23 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>Lava Z2 Max- A bigger canvas for your child’s big dreams</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div>

							<div className="col-md-3">
								<div className="one_new_vendor">
									<h5>27 Aug 2022 </h5>
									<div className="newdtesila_s">
										<p>This World Music Day, groove uninterrupted with LAVA Probuds at just Rs.1</p>
									</div>

									<a href="#" className="read_more">Read More <i className="fa fa-caret-right" aria-hidden="true"></i></a>
									 
								</div>
							</div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PressAndMedia
