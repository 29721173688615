import React,{useEffect} from 'react'
import '../../styles/yuva2proj.scss'
import {basepath,baseurl} from '../../basepath'
import AOS from 'aos'

export default function Cleanui() {
  return (
    <div className='cleanui'>
        <div className="title_container text-left">
                <h2>Clean UI</h2>
                <h4 className='text-left'>Near Stock Android experience.</h4>
                <p className='text-left'>Clean, smooth & bloat free experience.</p>
        </div>
        <div className="cleanui-android">
           <img src={`${baseurl}${basepath}/images/yuva2pro/cleanui-android.png`} className='img-fluid' alt="Yuva 2 Pro"/>
        </div>
        <div className="cleanui-mobile">
           <img src={`${baseurl}${basepath}/images/yuva2pro/cleanui-mobile.png`} className='img-fluid' alt="Yuva 2 Pro"/>
           <div className="cleanui-mobile-shadow">
           <img src={`${baseurl}${basepath}/images/yuva2pro/cleanui-mobile-shadow.png`} className='img-fluid' alt="Yuva 2 Pro"/>

           </div>
        </div>
        </div>
  )
}
