import React from 'react'
import {basepath,baseurl} from '../../basepath'
export default function PunchHole() {
  return (
    <div className='punchhole'>
        <div className="punchhole_title">
            <h2>20Hz Punch hole Display</h2>
                <p>let your eyes devlv into the mesmerizing,detailed Super gripping
                visuals be it watching online videos, high frame gaming on 90Hz
                display or capturing stunning moments</p>
        </div>
            <div className="puchholediv">
              <img src={`${baseurl}${basepath}/images/blaze2/puch-hold-mobile.png`} className='img-fluid layer1' alt="Blaze 2" />
              <img src={`${baseurl}${basepath}/images/blaze2/puch-hold-mobile2.png`} className='img-fluid layer2' alt="Blaze 2" />
              <h4>Get ready to be blown away by the power of vivi super display.</h4>
            </div>
            
    </div>
  )
}
