import React from 'react'

const CommingSoon = ({title}) => {

  return (
      <section>
        <div className='full_container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='product-search'>
                <h1>{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default CommingSoon