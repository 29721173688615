import React from 'react';
import {basepath,baseurl} from '../../basepath'

export default function UltrafastProcessor() {
  return (
  <>
      <div className='ultrafastprocessor'>
        <div className="overlayer"></div>
        <div className="_title">
            <h2>Ultrafast T616 processor </h2>
            <h4>Highest AnTuTu in the Segment* <br/> AnTuTu Score 250K+</h4>
            <p>The T616 processor delivers blazing speed, smooth performance, and energy efficient processing making it the ideal choice for gamers, creators, and professionals. Whether you're editing videos, playing online games, or multitasking on multiple applications, the T616 processor can handle it all with ease. </p>
        </div>
        <div className="__processor">
           <img src={`${baseurl}${basepath}/images/blaze2/ultra-fast-processor.png`} className='img-fluid' alt="Blaze 2" />
        </div>
    </div>
    <div className="gaming">
        <div className="overlayer"></div>
        <div className="gaming_man_mobile">
            <div className="__man1">
               <img src={`${baseurl}${basepath}/images/blaze2/game-man1.png`} className='img-fluid' alt="Blaze 2" />
            </div>
            <div className="__mobile">

               <img src={`${baseurl}${basepath}/images/blaze2/game-grame-mobile.png`} className='img-fluid' alt="Blaze 2" />
            </div>
            <div className="__man2">
               <img src={`${baseurl}${basepath}/images/blaze2/game-man2.png`} className='img-fluid' alt="Blaze 2" />

            </div>
        </div>
    </div>
  </>
  )
}
