import React from 'react'
import {basepath,baseurl} from '../../basepath'
import FingerPrint from './FingerPrint'

export default function WhooshCharging() {
  return (
    <div className='whooshcharging'>
      <div className="whooshcontainer">
        <div className="whoosh_lft">
          <div className='text'>
          <h2>18W Whoosh Charging</h2>
          <p>With 18W fast charging in the box, you can refill your powerhouse 5000mAh battery in a jiffy and keep going all day long. It's like having a magic fountain of power in your pocket. Refill at will and never miss a beat.</p>
          </div>
          <div className="table_txt">
            <img src={`${baseurl}${basepath}/images/blaze2/battery-table.png`} className='img-fluid _rft' alt="Blaze 2" />
          </div>
        </div>
        <div className="whoosh_rft">
          <img src={`${baseurl}${basepath}/images/blaze2/battery-img.png`} className='img-fluid _rft' alt="Blaze 2" />
        </div>
        </div>
        <FingerPrint/>
    </div>
  )
}
