import React from 'react'
import  "../styles/a1vibe.scss";
import { Link } from 'react-router-dom';
import {basepath,baseurl} from '../basepath'
export default function A1Vibe() {
  return (
    <>
    <section className='page_title inner_breadcum detailpage_breadcum'>
        <div className="full_container">
            <div className="left">
                <h4>A1 Vibe</h4>
            </div>
            <div className="right">
                <Link to='/'>Overview</Link>
                <Link to='/'>Specification</Link>
            </div>
        </div>
    </section>
    <section className='a1vibeBanner'>
        <div className="__wrapper">
           <div className='container'>
              <div className="row">
                <div className="col-md-6">
                <div className="_leftscreen">
                <h2>A1 Vibe</h2>
                <img alt="A1 Vibe" src="/images/a1vibe/a1vibe_banner_text.png" />
                    
                  </div>
                </div>
                <div className="col-md-6">
                <div className="_rightscreen">
                <img alt="A1 Vibe" src="/images/a1vibe/a1vibe_banner_mobile.png" /> 
                  </div>
                  
                </div>
              </div>
           </div>
        </div>
    </section>
    <section className='infoa1vibe'>
      <div className="container">
        <div className="row">
            <div className="_groups">
                  <div className="_card">
                    <div className="box">
                    <h4>Camera</h4>
                    <img alt="A1 Vibe" src="/images/a1vibe/card_1.png" class="img-fluid"/>
                    </div>
                  </div>
                  <div className="_card">
                    <div className="box">
                    <h4>1000mAh Smart 
                    AI Battery</h4>
                    <img alt="A1 Vibe" src="/images/a1vibe/card_2.png" class="img-fluid"/>
                    </div>
                  </div>
                  <div className="_card">
                    <div className="box">
                    <h4>1.8” Screen</h4>
                    <img alt="A1 Vibe" src="/images/a1vibe/card_3.png" class="img-fluid"/>
                    </div>
                  </div>
                  <div className="_card">
                    <div className="box">
                    <h4>Wireless FM 
with recording
</h4>
                    <img alt="A1 Vibe" src="/images/a1vibe/card_4.png" class="img-fluid"/>
                    </div>
                  </div>
                  <div className="_card">
                    <div className="box">
                    <h4>Auto Call 
Recording
</h4>
                    <img alt="A1 Vibe" src="/images/a1vibe/card_5.png" class="img-fluid"/>
                    </div>
                  </div>
            </div>
        </div>
      </div>
    </section>
    <section className='a1vibescreen1'>
        <div className="container">
          <div className="row">
            <div className='col-md-6'>
              <div className="_leftscreen">
              <strong>A1 Vibe comes</strong> with premium look and dual finish, powered by 1000 mAh powerful battery which delivers a backup up of more than 5 days in a single charge.

              </div>
            </div>
            <div className='col-md-6'>
              <div className="_rightscreen">
                    <img alt="A1 Vibe" src="/images/a1vibe/a1vibe_screen01_mobile.png" class="img-fluid"/>

              </div>
            </div>
          </div>
        </div>
    </section>
    <section className='a1vibescreen2'>
        <div className="container">
          <div className="row">
              <div className="col-md-6">
                <div className="_lftscreen">
                <img  alt='A1 Vibe' src='/images/a1vibe/a1vibe_screen02_left_mobile.png' class="img-fluid" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="_rftscreen">
                  <div className="five">
                    <img  alt='A1 Vibe' src='/images/a1vibe/five_days.png' class="img-fluid" />
                  </div>
                  <p>
                  A1 Vibe 1000 mAH battery, which gives 4 days of 
battery backup with Super Battery Mode

                  </p>
                </div>
              </div>
          </div>
        </div>
    </section>
    <section className='a1vibescreen3'>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="_left_screen">
                  <h2>Vibration Alert</h2>
                  <p>
                  Stay connected with the Vibration Alert. Whether you’re in a noisy street or a quiet library, the discreet vibration ensures you’re always aware of incoming calls and messages.

                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="_rft_screen">
                    <img  alt='A1 Vibe' src='/images/a1vibe/a1vibe_screen03_right_mobile.png' class="img-fluid" />

                </div>
              </div>
            </div>
          </div>
    </section>
    <section className='a1vibescreen4'>
      <div className='container'>
        <div className="row">
          <div className="col-md-7">
              <div className="_lft_screen">
                    <img  alt='A1 Vibe' src='/images/a1vibe/a1vibe_screen04_lft_mobile.png' class="img-fluid" />

              </div>
          </div>
          <div className="col-md-5">
            <div className="_rft_screen">
                <div className="_text">
                  <h2>Bluetooth Support </h2>
                  <p>
                  Transfer/Share your favorite Music, Video & Contacts Super fast with A1 Vibe
                  </p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='a1vibescreen5'>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="left_screen">
                  <div>
                  <h2>Expand memory
                  up to 32GB</h2>
                  <p>
                  Maximize your mobile experience with <strong>32GB of expandable memory</strong>. Store more photos, music, and video without ever worrying about running out of space.

                  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="right_screen">
                    <img  alt='A1 Vibe' src='/images/a1vibe/a1vibe_screen05_right.png' class="img-fluid" />

                </div>
              </div>
            </div>
          </div>
    </section>
    <section className='a1vibescreen6'>
          <div className="container">
            <div className="row">
                <div className="col-md-6">
                  <div className="_leftscreen">
                    <img  alt='A1 Vibe' src='/images/a1vibe/a1vibe_screen06_left.png' class="img-fluid" />

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="_rightscreen">
                    <h2>Auto Call Recording</h2>
                    <p>
                    Automatically Record Your Calls Within Folders 

                    </p>
                  </div>
                </div>
            </div>
            </div>
      </section>
    </>
  )
}
