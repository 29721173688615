import React from 'react'
import  "../styles/attorch.scss";
import { Link } from 'react-router-dom';
import {basepath,baseurl} from '../basepath'

export default function AtTorchBlack() {
  return (
    <>
    <section className='page_title inner_breadcum detailpage_breadcum'>
        <div className="full_container">
            <div className="left">
                <h4>A7 Torch</h4>
            </div>
            <div className="right">
                <Link to='/'>Overview</Link>
                <Link to='/'>Specification</Link>
            </div>
        </div>
    </section>
    <section className='a7screenBanner'>
        {/* <img alt="Lava Gem Power" src={`${baseurl}${basepath}/images/a7-torch-black/banner-bg.png`} /> */}
        <div className="__container">
           <div className='container'>
              <div className="row">
                <div className="col-md-7">
                  <div className="_leftscreen">
                    <h2>A7 Torch</h2>
                    <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/banner-text.png`} />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="_rightscreen">
                    <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/banner-mobile-img.png`} />
                  </div>
                </div>
              </div>
           </div>
        </div>
    </section>
    <section className='a7screeninfo'>
      <div className="container">
        <div className="row">
            <div className='col-md-12 _card'>
                <div className='__box'>
                 <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/box-1.png`} />
                 <div className="txt">
                  <h5>Dual-color <br/>
                  strip torch</h5>
                 </div>
                </div>
                <div className='__box'>
                 <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/box-2.png`} />
                 <div className="txt">
                  <h5>
                  2575 mAh <br/>Big Battery</h5>
                 </div>
                </div>
                <div className='__box'>
                 <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/box-3.png`} />
                 <div className="txt">
                  <h5>2.4” Screen</h5>
                 </div>
                </div>
                <div className='__box'>
                 <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/box-4.png`} />
                 <div className="txt">
                  <h5>Superior <br/> stereo sound</h5>
                 </div>
                </div>
                <div className='__box'>
                 <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/box-5.png`} />
                 <div className="txt">
                  <h5>Camera Phone</h5>
                 </div>
                </div>
            </div>
        </div>
      </div>
    </section>
    <section className='a7screen_01'>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="_leftscreen">
                <h2>Lava A7 Torch: <br/>
                Light Up Every Moment.
                <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_01_shadow.png`} />
                </h2>
                <p>The A7 Torch features a strip torch that provides ample illumination in dark environments. 
                  It’s perfect for emergencies or late-night tasks</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="_rightscreen">
              <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_01_mobile.png`} />
              </div>
            </div>
          </div>
        </div>
    </section>
    <section className='a7screen_02'>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="_leftscreen">
              <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_02-mobile.png`} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="_rightscreen">
                <h2>Superior Stereo 
                Sound Quality</h2>
                <p>
                Whether you’re listening to music, taking calls, or tuning in to the radio, expect clear and vibrant audio.
                </p>
              </div>
            </div>
          </div>
        </div>
    </section>
    <section className='a7screen_03'>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="_leftscreen">
                  <h2>2.4-inch big display</h2>
                  <p>Clear and vibrant viewing experience. Whether you’re reading messages or navigating menus, the display won’t disappoint.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                  <div className="_rightscreen">
                  <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_03_mobile.png`} />
                  </div>
              </div>
            </div>
          </div>
    </section>
    <section className='a7screen_04'>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="_leftscreen">
              <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_04-mobile.png`} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="_rightscreen">
                  <h2>10Days <br/>Battery Backup</h2>
                  <p>
                  You won’t have to worry about frequent charging.Whether you’re making calls, sending texts, or listening to music, this phone keeps going as long as you do.
                  </p>
              </div>
            </div>
          </div>
        </div>
    </section>
    <section className='a7screen_05'>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
                <div className="_leftscreen">
                  <h2>Sturdy and Durable  </h2>
                  <p>Militry grade build to kick out daily wear <br/> and tear.                  </p>
                </div>
            </div>
            <div className="col-md-6">
              <div className="_rightscreen">
              <img alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_05-mobile.png`} />
              </div>
            </div>
          </div>
        </div>
    </section>
    <section className='a7screen_06'>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="_leftscreen">
                <img className='_left' alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_06-left-mobile.png`} />
                <img className='_bottom' alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_06-top-ram.png`} />
              
              </div>
            </div>
            <div className="col-md-5">
              <div className="_rightscreen">
              <img  alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_06-top-mobile.png`} />
                <div className="_box">
                  <h2>Expand memory <br/>
                  up to 32GB</h2>
                  <p>
                  Never worry about space again. With support for up to 32 GB of expandable storage, 
                  the Lava A7 Torch lets you store all your favourite songs, pictures, and videos with ease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
    <section className='a7screen_07'>
        <div className="container">
          <div className="row">
              <div className="col-md-6">
                <div className="_leftscreen">
                    <h2>Auto Call Recording</h2>
                    <p>Autometically Record Your Calls Within Folders</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="_rightscreen">
                <img  alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_07-right-mobile.png`} />
                <img  className='_ram' alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_07-rec.png`} />
                <img  className='_wave' alt="A7 Torch" src={`${baseurl}${basepath}/images/a7-torch-black/screen_07-wave.png`} />
                </div>
              </div>
          </div>
        </div>
    </section>
    </>
  )
}
