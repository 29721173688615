import React from 'react'
import  "../styles/a1josh.scss";
import { Link } from 'react-router-dom';
import {basepath,baseurl} from '../basepath'

export default function A1Josh() {
  return (
    <>
    <section className='page_title inner_breadcum detailpage_breadcum'>
        <div className="full_container">
            <div className="left">
                <h4>A1 Josh</h4>
            </div>
            <div className="right">
                <Link to='/'>Overview</Link>
                <Link to='/'>Specification</Link>
            </div>
        </div>
    </section>
    <section class="FeatureBanner">
      <img alt="" src={`${baseurl}${basepath}/images/a1Josh/a1josh-bg-banner.jpg`}   />
<div class="__content">
<div class="flex">
<div class="text-center aos-init" data-aos="fade-up">
<h1 className='py-3'>A1 Josh</h1>
<img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/a1josh-banner-text.png`}  className='img-fluid mt-4' />
</div>

<div class="text-right aos-init" data-aos="fade-down">
  <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/a1josh-banner-mobile.png`} className='img-fluid mt-4' /></div>
</div>
</div>
</section>
<section className='infographics'>
    <div className="container">
      <div className="row __row">
        <div className="col-md-3">
            <div className="box">
              <h2>1000mAh Smart AI Battery</h2>
              <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/info-card.png`} />
            </div>
        </div>
        <div className="col-md-3">
            <div className="box">
            <h2>1.8” Screen</h2>
              <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/info-card2.png`} />
            </div>
        </div>
        <div className="col-md-3">
            <div className="box">
              <h2>Wireless FM 
with recording</h2>
              <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/info-card3.png`} />
            </div>
        </div>
        <div className="col-md-3">
            <div className="box">
          <h2>Auto Call  Recording</h2>
              <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/info-card4.png`} />
            </div>
        </div>
      </div>
    </div>
</section>
  <section className='screen_01'>
      <div className="container">
        <div className="row">
            <div className="col-md-7">
              <div className="_leftscreen">
                <div className='line aos-init' data-aos="fade-down"></div>
                  <h2>Lava A1 Josh </h2>
                  <p>
                  built to last. With its military-grade durability and a battery that promises up to 5 days of backup, this phone is a testament to reliability.
                   It’s a powerhouse of essential features packed into a sleek design
                  </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="_rightscreen">
              <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/screen1-right-img.png`} className='aos-init' data-aos="fade-up" />
              </div>

            </div>
        </div>
      </div>
  </section>
  <section className='screen_02'>
      <div className="container">
        <div className="row">
          <div className="col-md-7">
              <div className='_leftscreen'>
              <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/screen2-leftside.png`} className='aos-init' data-aos="fade-down" />
              </div>
          </div>
          <div className="col-md-5">
            <div className="_rightscreen text-right">
                <h2>5Days</h2>
                <h4>Battery Backup</h4>
                <p>A1 Josh 1000 mAH battery, which gives 5 days of battery backup with Super Battery Mode
</p>
            <div className='line'></div>
            </div>
          </div>
        </div>
      </div>
  </section>
  <section className='screen_03'>
    <div className="container">
      <div className="row">
        <div className="col-md-6">
            <div className="_leftscreen">
              <div className='line'></div>
              <h2>Military- <br/>
Grade Certified</h2>
<p>Crafted with durability in mind, the A1 Josh can withstand the rigors of everyday use
</p>
            </div>
        </div>
        <div className="col-md-6">
          <div className="_rightscreen">
              <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/screen3-rightside.png`} className='aos-init' data-aos="fade-down" />
            
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className='screen_04'>
    <div className="container">
      <div className="row">
        <div className="col-md-6">
        <div className="_leftscreen">
            <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/screen4-leftsideimg.png`} className='aos-init' data-aos="fade-down" />
        </div>
        </div>
        <div className="col-md-6">
        
        <div className="_rightscreen text-right aos-init" data-aos="fade-left">
            <h2>Clear Sound</h2>
            <p>Experience crystal-clear audio quality, 
            whether you’re on a call or listening to the radio
            </p>
        <div className="line"></div>
        </div>
        </div>
        
      </div>
      
    </div>
  
  </section>
  <section className='screen_05'>
      <div className="container">
          <div className="row">
              <div className="col-md-5">
                  <div className="_leftscreen">
                    <div className="line"></div>
                      <h2>Expand memory <br/> up to 32GB</h2>
                      <p>
                      Maximize your mobile experience with <strong>32GB of expandable memory.</strong> 
                      Store more photos, music, and video without ever worrying about running out of space.
                      </p>
                      <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/screen5-32gb.png`} className='__32gb aos-init' data-aos="fade-down" />
                  </div>
              </div>
              <div className="col-md-7">
                <div className="_rightscreen">

                  <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/screen5-rightside.png`} className='aos-init' data-aos="fade-down" />

                </div>
              </div>
          </div>
      </div>
  </section>
  <section className='screen_06'>
      <div className="container">
        <div className="row">
            <div className="col-md-6">
              <div className="_leftscreen">
                <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/screen6-leftside-mobile.png`} className='aos-init' data-aos="fade-down" />
                
              </div>
            </div>
            <div className="col-md-6">
              <div className="_rightscreen">
                <h2 className='aos-init mt-5' data-aos="fade-down">Auto Call Recording</h2>
                <p>Automatically Record Your Calls <br/> Within Folders </p>
                <div className='_img'>
                <img alt="A1 Josh" src={`${baseurl}${basepath}/images/a1Josh/screen6-reacording-img.png`} className='aos-init' data-aos="fade-down" />
                </div>
              </div>

            </div>
        </div>
      </div>
  </section>
    </>
  )
}
