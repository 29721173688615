import React from 'react'
import {basepath,baseurl} from '../basepath'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function LavaBlaze() {
  return (
    <div>
      <>
        <section class="page_title inner_breadcum detailpage_breadcum">
            <div class="container-fluid">
                <h1>LAVA BLAZE</h1>
            </div>
        </section>
        <div className='prow_detalpage'>
            <section className='product_details' data-aos="zoom-in" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true">
                <div className="one_detail1">
                    <div className="one_detail_img">
                        <LazyLoadImage src={`${baseurl}${basepath}/images/get-ready_img.jpg`} alt="Lava Blaze" />
                    </div>
                    <div className="one_detail_content" >
                        <p>
                            <h2>Get ready to</h2>
                            <h3>outshine with</h3>
                            <h4>stunning phone in <span>irresistible colours</span></h4>
                            <p>Premium Glass back design</p>
                        </p>
                    </div>
                </div>
            </section>
            <section className='product_details' data-aos="zoom-in" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true">
                <div className="one_detail1">
                    <div className="one_detail_img">
                        <LazyLoadImage src={`${baseurl}${basepath}/images/blez-mobile-details.jpg`} alt="Lava Blaze" />
                    </div>
                </div>
            </section>
            <section className='product_details tripal-camera' data-aos="zoom-in" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true">
                <div class="one_detail1">
                    <div class="one_detail_img">
                        <LazyLoadImage src={`${baseurl}${basepath}/images/blaze-tripal-camera.jpg`} alt="Lava Blaze" />
                    </div>
                    <div class="one_detail_content">
                        <p>
                            <h1>13 MP AI <span>TRIPLE CAMERA</span></h1>
                            <h2>Large 1.0µm Pixels</h2>
                            <p>For Superior Light and Color Capture photography</p>
                        </p>
                    </div>
                </div>
            </section>
            <section className='product_details beauty-mode' data-aos="zoom-in" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true">
                <div class="one_detail1">
                    <div class="one_detail_img">
                        <LazyLoadImage src={`${baseurl}${basepath}/images/beauty-mode-img.jpg`} alt="Lava Blaze" />
                    </div>
                    <div class="one_detail_content">
                        <p>
                            <div class="beauty_mode">
                                <h1>Beauty Mode</h1>
                                <p>Take Gorgeous Selfies with<br />enhanced <strong>AI beauty Mode</strong></p>
                            </div>
                        </p>
                    </div>
                </div>
            </section>
            <section className='product_details blaze-dezzle' data-aos="zoom-in" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true">
                <div class="one_detail1">
                    <div class="one_detail_img">
                        <LazyLoadImage src={`${baseurl}${basepath}/images/blaze-dezzle.jpg`} alt="Lava Blaze" />
                    </div>
                    <div class="one_detail_content">
                        <p>
                            <h1>Dazzle your &nbsp;<br />
                                <span>social media</span>
                            </h1>
                            <p>with instant GIF shot on <b>In-built camera app</b></p>
                        </p>
                    </div>
                </div>
            </section>
            <section className='product_details blaze-macro-camera' data-aos="zoom-in" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true">
                <div class="one_detail1">
                    <div class="one_detail_img">
                        <LazyLoadImage src={`${baseurl}${basepath}/images/blaze-macro-camera.jpg`} alt="Lava Blaze" />
                    </div>
                    <div class="one_detail_content">
                        <p>
                            <h1><strong>MACRO</strong> <span>Camera</span></h1>
                            <p>Explore the beauty of nature by Capturing the ultra<br />fine details with MACRO camera</p>
                        </p>
                    </div>
                </div>
            </section>
            <section className='product_details blaze-ram-group' data-aos="zoom-in" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true">
                <div class="one_detail1">
                    <div class="one_detail_img">
                        <LazyLoadImage src={`${baseurl}${basepath}/images/blaze-ram-group-img.jpg`} alt="Lava Blaze" />
                    </div>
                    <div class="one_detail_content">
                        <p>
                            <div class="macro-camera">
                                <h1>4GB + 3GB* RAM+</h1>
                                <label>eMMC 5.1</label>
                                <h2>Make multitasking a breeze<br />wirh ram+</h2>
                            </div>
                        </p>
                    </div>
                </div>
            </section>
            <section className='product_details blaze-octa-processor' data-aos="zoom-in" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true">
                <div class="one_detail1">
                    <div class="one_detail_img">
                        <LazyLoadImage src={`${baseurl}${basepath}/images/blaze-octa-processor-img.jpg`} alt="Lava Blaze" />
                    </div>
                    <div class="one_detail_content">
                        <p>
                            <h1>2.3GHz Octa Core processor</h1>
                            <h3>Ultra fast octa core processor for blazing fast apps and internet</h3>
                        </p>
                    </div>
                </div>
            </section>
            <section className='product_details blaze-clean-ui' data-aos="zoom-in" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true">
                <div class="one_detail1">
                    <div class="one_detail_img">
                        <LazyLoadImage src={`${baseurl}${basepath}/images/blaze-clean-ui-img.jpg`} alt="Lava Blaze" />
                    </div>
                    <div class="one_detail_content">
                        <p>
                            <h1>Clean UI</h1>
                            <h2>Near Stock Android experience.</h2>
                            <h3>Clean, smooth &amp; bloat free experience.</h3>
                        </p>
                    </div>
                </div>
            </section>
            <section className='product_details blaze-enjoy' data-aos="zoom-in" data-aos-offset="50" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true">
                <div class="one_detail1">
                    <div class="one_detail_img">
                        <LazyLoadImage src={`${baseurl}${basepath}/images/blaze-enjoy-img.jpg`} alt="Lava Blaze" />
                    </div>
                    <div class="one_detail_content">
                        <p>
                            <p>Enjoy long lasting full day battery.<br />Supports OTG and reverse charging<br />Handle it all, handle all day</p>
                        </p>
                    </div>
                </div>
            </section>
        </div>
      </>
    </div>
  )
}
