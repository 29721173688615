import React,{useEffect} from 'react'
import '../../styles/yuva2proj.scss'
import {basepath,baseurl} from '../../basepath'
import AOS from 'aos'

export default function Multitask() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return (
    <div className='multitask_container'>
         <img src={`${baseurl}${basepath}/images/yuva2pro/7gb-text.png`} className='img-fluid' alt="Yuva 2 Pro" /> 
         <div className="title_container">
           <img src={`${baseurl}${basepath}/images/yuva2pro/7gb-ram-text.png`} className='img-fluid gb-ram-text' alt="Yuva 2 Pro" /> 
            <div className='txt'>
                <h2>Multitask With Ram+ <br/>
4GB + 3GB* RAM</h2>
<p>Live the unlimited Life! Browse web, web watch videos, play games,edit files
and more and even more...</p>
            </div>
            <div className="overflow"></div>
         </div>
        <div className="multitask">
            <img src={`${baseurl}${basepath}/images/yuva2pro/4gb.png`} className='img-fluid fourgb'  alt="Yuva 2 Pro" 
            data-aos="fade-down" data-aos-duration="1000" data-aos-easing="linear"/>
            <div className="threegb" data-aos="fade-up" data-aos-duration="3000" data-aos-easing="linear">
              <img src={`${baseurl}${basepath}/images/yuva2pro/3gb-ram.png`} className='img-fluid gb-ram-text' alt="Yuva 2 Pro" /> 
            </div>
        </div>

    </div>
  )
}
