import React, {useEffect, useState} from 'react'
import {Carousel} from 'react-responsive-carousel'
import Slider from 'react-slick'
import {useParams, Link} from 'react-router-dom'
import axios from 'axios'
import parse from 'html-react-parser'
import {basepath,baseurl} from '../basepath'
import '../styles/style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const url = process.env.REACT_APP_API_URL;

const ProductDetails = () => {
  var storeId = 0

  const {slug} = useParams()
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
  }

  const [imagesFor1,setImagesFor1] = useState([])
  const [imagesFor2,setImagesFor2] = useState([])
  const [imagesPath,setImagesPath] = useState("")
  const [productData,setProductData] = useState({})
  const [productSections,setProductSections] = useState([])

  const [scrollPosition, setPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset;
    setPosition(position);
  };

  useEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset) 
      AOS.refresh()
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition() 
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  if (typeof window !== 'undefined') {
    storeId = window.localStorage.getItem('storeId')
  }

  const getProductDetails = async (s) => {
    try {
      const res = await axios.get(
        `${url}/product_details/${s}/${storeId}`
      )
      console.log(res.data)
      setProductSections(res.data.product_section)
      setImagesPath(res.data.path)
      setImagesFor1(res.data?.product_gallery1)
      setImagesFor2(res.data?.product_gallery2)
      setProductData(res.data.products)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProductDetails(slug)
  }, [slug,storeId])

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === 'remove') {
        return <></>
      }
    },
  }
  return (
    <>
    {
      productSections?.slice(0,1).map((item)=>(
        // <div>
        //   {parse(item.description, options)}
        // </div>
        parse(item.description || "", options)
      ))
    }
    </>
  )
}

export default ProductDetails
