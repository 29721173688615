import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate,NavLink } from 'react-router-dom'
import { basepath, baseurl } from '../basepath'
import axios from 'axios'
import getHeaderToken from '../service/generate-token'
import TimeMe from 'timeme.js'
import { data } from 'jquery'

const url = process.env.REACT_APP_API_URL;
let countryId = localStorage.getItem('storeId');
let lastScrollTop = 0;
const Header = () => {
  const router = useLocation()
  const [scrollPosition, setPosition] = useState(0)
  const [country, setCountry] = useState(0)
  const [countryName, setCountryName] = useState("")
  const [allCountry, setAllCountry] = useState([])
  const [, setCountryChange] = useState()
  const [, setLatestProductData] = useState([])
  const [search, setSearch] = useState("")
  const [subMenuData,setSubMenuData] = useState([])
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showSelectCountryBar, setShowSelectCountryBar] = useState(false);
  const [showSelectedCountryName, setShowSelectedCountryName] = useState('');
  const [showBurgerMenu, setShowBurgerMenu] = useState('head_menu');
  const navigate = useNavigate()


  if(navigator.geolocation){
    navigator.geolocation.getCurrentPosition(function(position){
        //console.log(position.coords.longitude)
        //console.log(position.coords.latitude)
    })
}else{
    console.log("Geolocation is not supported by this device/browser")
}

TimeMe.initialize({
  currentPageName: window.location.origin, // current page
  idleTimeoutInSeconds: 5, // stop recording time due to inactivity
});


const visitorTime = new Date();
console.log('Visitor Time:', visitorTime);
if ("geolocation" in navigator) {
  navigator.geolocation.getCurrentPosition(
    position => {
      const visitorLocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      };
      console.log('Visitor Location:', visitorLocation);
    }
  );
} else {
  console.log('Geolocation is not supported in this browser.');
}
const visitorUser = []

const currentPage = window.location.href;
console.log('Current Page:', currentPage);
if ("geolocation" in navigator) {
  navigator.geolocation.getCurrentPosition(
    position => {
      const visitorTime = new Date();
      const visitorLocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      };
      const currentPage = window.location.href;
      
      console.log('Visitor Time:', visitorTime);
      console.log('Visitor Location:', visitorLocation);
      console.log('Current Page:', currentPage);
      console.log(TimeMe.getTimeOnCurrentPageInSeconds())
      let currentpageloc = {
        'Visitor Time': visitorTime,
        'Visitor Location': visitorTime,
        'Current Page': currentPage,
        'Hold time' :TimeMe.getTimeOnCurrentPageInSeconds()
      }
      visitorUser.push(currentpageloc)
      console.log(visitorUser)
    },
    error => {
      console.error('Error getting visitor location:', error.message);
    }
  );
} else {
  console.log('Geolocation is not supported in this browser.');
}



  const getStores = async () => {
    try {
      const res = await axios.get(`${url}/stores`, getHeaderToken)
      let current_url = window.location.origin;
      // console.log(res.data.store[0].url)
      // console.log(current_url)
      // console.log(res.data.store[0].url===current_url,'Nepal')
      // console.log(res.data.store[1].url===current_url,'Shri lanka')
      // console.log(res.data.store[2].url===current_url,'Bangladesh')
      // console.log(res.data.store[3].url===current_url,'United Arab Emirates')
      // console.log(res.data.store[4].url===current_url,'Qatar')
      //console.log('33333'+localStorage.getItem('storeId'))
      //console.log(localStorage.getItem('storeId'))

      if(current_url == 'https://global.lavamobiles.com')
      {
        if(!localStorage.getItem('storeId') && localStorage.getItem('storeId')!=''){
      let response = await axios.get(`https://ipapi.co/json/`);
      res.data.store.map(val=>{
         console.log(res.data.store[val.id])
      })
        console.log(res.data.store[4].title)
      if(res.data.store[1].title === response.data.country_name || current_url === res.data.store[1].url){

        if(current_url!==res.data.store[1].url)
        {
          window.location.replace(res.data.store[1].url);
        }
        else{
        console.log('country : Srilanka')
        setCountryName(res.data.store[1].title)
        setCountry(5)
        localStorage.setItem("storeId", res.data.store[1].id)
        localStorage.setItem("currency_code", res.data.store[1].currency_code)
        countryId = 5
        }
      }else if(res.data.store[0].title === response.data.country_name || current_url === res.data.store[0].url){

        if(current_url!==res.data.store[0].url)
        {
          window.location.replace( res.data.store[0].url);
        }
        else{
        console.log('country : Nepal')
        setCountry(7)
        localStorage.setItem("storeId", res.data.store[0].id)
        localStorage.setItem("currency_code", res.data.store[0].currency_code)
        countryId = 7
        }
      }else if(res.data.store[2].title === response.data.country_name || current_url === res.data.store[2].url){
        
        if(current_url!==res.data.store[2].url)
        {
          window.location.replace( res.data.store[2].url);
        }
        else{
        console.log('country : Bangladesh')
        setCountry(6)
        localStorage.setItem("storeId", res.data.store[2].id)
        localStorage.setItem("currency_code", res.data.store[2].currency_code)
        countryId = 6
        }
      }else if(res.data.store[3].title === response.data.country_name || current_url === res.data.store[3].url){

        if(current_url!==res.data.store[3].url)
        {
          window.location.replace( res.data.store[3].url);
        }
        else{
        console.log('country : UAE')
        setCountry(8)
        localStorage.setItem("storeId", res.data.store[3].id)
        localStorage.setItem("currency_code", res.data.store[3].currency_code)
        countryId = 8
        }
      } else if(res.data.store[4].title === response.data.country_name || current_url === res.data.store[4].url){

        if(current_url!==res.data.store[4].url)
        {
          window.location.replace( res.data.store[4].url);
        }
        else{
        console.log('country : Qatar')
        setCountry(4)
        localStorage.setItem("storeId", res.data.store[4].id)
        localStorage.setItem("currency_code", res.data.store[4].currency_code)
        countryId = 4
        }
      }
      else{
       window.location.replace('https://www.lavamobiles.com/');
      }
     }
     else{
      if(current_url === res.data.store[1].url){
        console.log('country : Srilanka')
        setCountryName(res.data.store[1].title)
        setCountry(5)
        localStorage.setItem("storeId", res.data.store[1].id)
        localStorage.setItem("currency_code", res.data.store[1].currency_code)
        countryId = 5
      }else if(current_url === res.data.store[0].url){
        console.log('country : Nepal')
        setCountryName(res.data.store[0].title)
        setCountry(7)
        localStorage.setItem("storeId", res.data.store[0].id)
        localStorage.setItem("currency_code", res.data.store[0].currency_code)
        countryId = 7
      }else if( current_url === res.data.store[2].url){

        console.log('country: Bangladesh')
        setCountry(6)
        setCountryName(res.data.store[2].title)
        localStorage.setItem("storeId", res.data.store[2].id)
        localStorage.setItem("currency_code", res.data.store[2].currency_code)
        countryId = 6
      }else if(current_url === res.data.store[3].url){
        console.log('country : UAE')
        setCountryName(res.data.store[3].title)
        setCountry(8)
        localStorage.setItem("storeId", res.data.store[3].id)
        localStorage.setItem("currency_code", res.data.store[3].currency_code)
        countryId = 8
      } else if(current_url === res.data.store[4].url){
        setCountryName(res.data.store[4].title)
        console.log('country : Qatar')
        setCountry(4)
        localStorage.setItem("storeId", res.data.store[4].id)
        localStorage.setItem("currency_code", res.data.store[4].currency_code)
        countryId = 4
        
      }
      else{
        window.location.replace('https://www.lavamobiles.com/');
      }
    }  
  }
  else
  {

    console.log("T2:"+current_url);
    if(current_url == 'https://np.lavamobiles.com')
    {
        setCountry(7)
        setCountryName(res.data.store[0].title)
        localStorage.setItem("storeId", res.data.store[0].id)
        localStorage.setItem("currency_code", res.data.store[0].currency_code)
        countryId = 7
    }
    else if(current_url == 'https://bd.lavamobiles.com')
    {
      setCountryName(res.data.store[2].title)
        setCountry(6)
        localStorage.setItem("storeId", res.data.store[2].id)
        localStorage.setItem("currency_code", res.data.store[2].currency_code)
        countryId = 6
    }
    else if(current_url == 'https://lk.lavamobiles.com')
    {
      setCountryName(res.data.store[3].title)
        setCountryName(res.data.store[3].title)
        setCountry(5)
        localStorage.setItem("storeId", res.data.store[3].id)
        localStorage.setItem("currency_code", res.data.store[3].currency_code)
        countryId = 5
    }
    else if(current_url == 'https://uae.lavamobiles.com')
    {
      console.log('country : UAE')
        setCountryName(res.data.store[4].title)
        setCountry(8)
        localStorage.setItem("storeId", res.data.store[4].id)
        localStorage.setItem("currency_code", res.data.store[4].currency_code)
        countryId = 8
    }
    else if(current_url == 'https://qa.lavamobiles.com')
    {
      console.log("T3:"+res.data);
      setCountryName(res.data.store[1].title)
        console.log('country : Qatar')
        setCountry(4)
        localStorage.setItem("storeId", res.data.store[1].id)
        localStorage.setItem("currency_code", res.data.store[1].currency_code)
        countryId = 4
    }
    


  }
      
      
      setAllCountry(res.data.store)
      //console.log('wwwwwwww'+country+'#####'+localStorage.getItem('storeId'))
     // if(countryName === "" && !localStorage.getItem('storeId')){

       // console.log('I am here');
        //getCountryName(res)
      //}else{ 
      // old implementation without auto selection of country
      // res.data.store.find(async(item) => {
      //   if(response.data.country_name == item.title){
      //     setCountry(item.id)
      //     setCountryName(item.title)
      //     res.data.store.map((country, i) => (country.title == item.title) && setShowSelectedCountryName(country.store_code));
      //     localStorage.setItem("storeId", item.id)
      //     localStorage.setItem("currency_code", item.currency_code)
      //   }
      // })
      //console.log(res.data.store[1].url)
        res.data.store.map(async(item) => {

          console.log(item.id,'itemID')
          console.log(countryId ,'countryId')
          console.log(countryId == item.id)
          if(countryId == item.id){

            console.log(item.id,'itemID Inner')
          console.log(countryId ,'countryId Inner')
          
            setCountry(item.id)
            setCountryName(item.title)
            res.data.store.map((country, i) => {
              (country?.title === item?.title) && setShowSelectedCountryName(country.store_code);
            });
            localStorage.setItem("storeId", item.id)
            localStorage.setItem("currency_code", item.currency_code)

            let current_url = window.location.origin;

            console.log(current_url + '####'+ item.url)

              if(current_url!==item.url)
               {
                 console.log('redirect  url :'+ item.url);
                // window.location.href = item.url;
                 window.location.replace(item.url);
                // setTimeout(() => { 
                //   window.location.replace(item.url);
                // }, 5000);
                //  console.log(item.url)

               }
          }
        })
      //}
    } catch (error) {
      console.log(error)
    }
    getSubMenu();
  }
  const getLatestProduct = async () => {
    try {
      const { data } = await axios.get(`${url}/latestProduct/${country}`,getHeaderToken)
      setLatestProductData(data.products)
    } catch (error) {
      console.log(error)
    }
  }
  // const getCountryName = async (res) => {
  //   let response = await axios.get(`https://ipapi.co/json/`);
  //   console.log(response.data.country_name)
  //   res.data.store.find(async(item) => {
  //     if(response.data.country_name == item.title){
  //       setCountry(item.id)
  //       setCountryName(item.title)
  //       res.data.store.map((country, i) => (country.title == item.title) && setShowSelectedCountryName(country.store_code));
  //      localStorage.setItem("storeId", item.id)
  //      localStorage.setItem("currency_code", item.currency_code)

  //       window.location.reload(false);
        
  //     }
  //   })
  // }
  const getSubMenu =async()=>{
    try {
      const res = await axios.get(`${url}/menu/${countryId}`, getHeaderToken)
      console.log(res.data.category)
      setSubMenuData(res?.data?.category)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getStores()
    function updatePosition() {
      var currentWindowHeight = window.pageYOffset || document.documentElement.scrollTop;
      if (currentWindowHeight > lastScrollTop) {
         setPosition(false)
      } else if (currentWindowHeight < lastScrollTop && currentWindowHeight > 100) {
         setPosition(currentWindowHeight);
      }
      lastScrollTop = currentWindowHeight <= 0 ? 0 : currentWindowHeight;
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
    
    
  }, [])
  const handleCountry = (e) => {
    if(e.title === "India"){
      window.open("https://www.lavamobiles.com/", "_blank")
    }
    // if(e.title === "Sri Lanka"){
    //   window.location.href="https://lkintqrdweb.indlava.com"
    // }
    // if(e.title === "Bangladesh"){
    //   window.location.href='https://bdintqrdweb.indlava.com'
    // }
    // if(e.title === "United Arab Emirates"){
    //   window.location.href="https://uaeintqrdweb.indlava.com"
    // }
    // if(e.title === "Qatar"){
    //   window.location.href="https://qaintqrdweb.indlava.com"
    // }
    localStorage.setItem("storeId", e.id)
    console.log(e.title)
    setCountryChange(e.title)
    setCountryName(e.title)
    allCountry.map((country, i) => (country.title == e.title) && setShowSelectedCountryName(country.store_code));
    window.location.reload(false);

    //window.location.href = country.url
  }
  const HeaderSearchBar = () => (<div className="search_bar_dropdown">
    <input autoFocus className='search_con_input'  type="text" placeholder="Search for Lava Mobiles" value={search} onKeyDown={handleSearchKey} onChange={(e)=>setSearch(e.target.value)}/>
  </div>)
  const HeaderSelectCountryBar = () => (<div className='country_list'>
    <ul>
    { allCountry?.length > 0 ? ( 
        allCountry.map((country, i) => 
          <li key={`country${i}`} className={`${country.title === (countryName)?'selected_country':''}`}
          onClick={()=>handleCountry(country)}> <a href={country.url}>{country.title}</a> </li>
        )): (<>
          <li class=""> <a href="https://np.lavamobiles.com">Nepal</a></li>
          <li class=""> <a href="https://qa.lavamobiles.com">Qatar</a></li>
          <li class="selected_country"> <a href="https://bd.lavamobiles.com">Bangladesh</a> </li>
          <li class=""> <a href="https://lk.lavamobiles.com">Sri Lanka</a></li>
          </>
        )
    }
    </ul>
  </div>)
  const handleSearchKey = async (e) => {
    if (e.key === 'Enter') {
      if (e?.target?.value?.length >= 3) {
         const { data } = await axios.get(`${url}/search/${country}/?q=${e.target.value}`)
        setSearch("")
        navigate(basepath+'/product-search/' + e.target.value)
      }
    }
  }
  const handleSearch = async () => {
    if (search.length >= 3) {
      setSearch("")
      navigate('/product-search/' + search)
    }
  }
  const hideSearchAndCountry = () => {
    setShowSearchBar(false); setShowSelectCountryBar(false);
  }
  useEffect(() => {
    hideSearchAndCountry();
    }, [navigate])

  return (
    <>
      <div className='d-flex flex-column justify-content-between'>
        <header className={`main_header  ${scrollPosition? 'fixed_header' : ''}`}>
          <div className={`${router.pathname !== '/' ? 'full_container' : 'full_container'} `}>
            <div className='menu_in'>
              <div className='row'>
                <div className='col-lg-2 col-md-2 col-sm-2'>
                  <div className='logo'>
                    <Link to={`${basepath}/`}>
                      <img src={`${baseurl}${basepath}/images/logo.png`} alt='logo' />
                    </Link>
                  </div>
                </div>
                <div className='col-lg-10 col-md-10 col-sm-10 text-right'>
                  <div className='main_menu' onMouseEnter={hideSearchAndCountry}>
                    <ul className={`${showBurgerMenu}`}>
                      <li className="product_dropdown_list" onMouseMove={hideSearchAndCountry}>
                      <NavLink className="custom-a" to={`${basepath}/product/smartphone`}>Product </NavLink>
                        <div className="sub_menu_dropdown">
                          <div className="full_container ">
                            <div className="row head_second_row">
                              {subMenuData?.map((item, i)=>(
                                  <div key={`subMenuData${i}`} className="col-md-3">
                                    <div className="one_sub_menu">
                                      <div className="spanHeadSecond black">
                                        <NavLink to={`${basepath}/product/${item.slug}`}>{item.name}</NavLink>
                                      </div>
                                      <ul>
                                        {
                                          item?.product?.map((pro, index) => (index<5) && <li key={`${pro.slug}subMenuDataproduct${index}`} ><Link to={`${basepath}/product-details/${pro.slug}`}>{pro.title}</Link></li>)
                                        }
                                      </ul>
                                      <div className='viall_all_head'>
                                        {(item?.product.length > 5) && <Link to={`${basepath}/product/${item.slug}`}>View All</Link>}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <NavLink to={`${basepath}/about`}>About Us</NavLink>
                      </li>
                      <li>
                        <NavLink to={`${basepath}/contact`}>contact us </NavLink>
                      </li>
                      <li id='area-of-interest-time-1'></li>
                    </ul>
                    <ul className='icon-block'>            
                      <li className="last_li">
                        <span className='selec_coun'
                          onClick={()=>{
                            if(showSearchBar){
                              setShowSearchBar(false);
                            }
                            setShowSelectCountryBar(!showSelectCountryBar)
                            }}>
                            <span>{showSelectedCountryName}</span>
                          <i className="fa fa-globe" aria-hidden="true"></i>{'       '}{showSelectCountryBar && <HeaderSelectCountryBar/>}
                        </span>
                      </li>
                      <li className="last_li r_border">
                        <span className='selec_coun' 
                          onClick={()=>{
                            if(showSelectCountryBar){
                              setShowSelectCountryBar(false);
                            }
                            setShowSearchBar(!showSearchBar)
                          }} >
                          {!showSearchBar ? 
                          <i className='fa fa-search'  aria-hidden='true'></i> : 
                          <i className='fa fa-times' aria-hidden='true'></i>}
                        </span>
                      </li>
                      <li className={`last_li burger_menu`}  onClick={(e)=>setShowBurgerMenu(showBurgerMenu == 'head_menu' ? 'head_menu head_menu_show': 'head_menu')}>
                        <span>
                          <i className="fa fa-bars" aria-hidden="true"></i>
                        </span>  
                      </li> 
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {showSearchBar && <HeaderSearchBar/>}
          </div>
        </header>
      </div>
    </>
  )
}

export default Header
