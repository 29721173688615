import React from 'react';
import  "../styles/blaze2.scss";
import { Link } from 'react-router-dom';
import {basepath,baseurl} from '../basepath'
import ImpressDesign from '../components/blazeCom/ImpressDesign';
import PunchHole from '../components/blazeCom/PunchHole';
import WhooshCharging from '../components/blazeCom/WhooshCharging';
import Promode from '../components/blazeCom/Promode';
import SlowMotionMode from '../components/blazeCom/SlowMotionMode';
import TimeLapes from '../components/blazeCom/TimeLapes';
import InternalStorage from '../components/blazeCom/InternalStorage';
import RearCamera from '../components/blazeCom/RearCamera';
import UltrafastProcessor from '../components/blazeCom/UltrafastProcessor';
export default function Blaze2() {
  return (
    <>
    <section className='page_title inner_breadcum detailpage_breadcum'>
        <div className="full_container">
            <div className="left">
                <h4>Blaze 2</h4>
            </div>
            <div className="right">
                <Link to='/'>Overview</Link>
                <Link to='/'>Specification</Link>
            </div>
        </div>
    </section>

    <div className='herobanner'>
        <div className="_bg">
            <div className="_left">
                <div className="herocontent">
                    <h1>Blaze 2</h1>
                    <h3>Unlocl the thrill...</h3>
                    <h4>With fastest chipset in the segment</h4>
                </div>
            </div>
            <div className="_right">
              <img src={`${baseurl}${basepath}/images/blaze2/hero-banner-mobile1.png`} className='img-fluid layer1' alt="Blaze 2" />
              <img src={`${baseurl}${basepath}/images/blaze2/hero-banner-mobile2.png`} className='img-fluid layer2' alt="Blaze 2" />
              <img src={`${baseurl}${basepath}/images/blaze2/hero-banner-mobile3.png`} className='img-fluid layer3' alt="Blaze 2" />
              <img src={`${baseurl}${basepath}/images/blaze2/hero-banner-mobile4.png`} className='img-fluid layer4' alt="Blaze 2" />
            </div>
        </div>
    </div>
    <div className="infographic _blaze2">
        <div className="_main_info">
            <div className="_column_one">
                <div className="_columns">
                    <h4 className="title_fourth">RAM+ <br/>6GB + 5GB*</h4>
                     <img src={`${baseurl}${basepath}/images/blaze2/ram6+.png`} className='img-fluid ram6+' alt="Blaze 2" />
                </div>
                <div className="_columns">
                    <div className="_columns_div">
                       <h4 className="title_fourth">90Hz Punch Hole <br/>HD+ Display</h4>
                       <img src={`${baseurl}${basepath}/images/blaze2/display_screen.png`} className='img-fluid display_screen' alt="Blaze 2" />
                    </div>
                    <div className="_columns_div">
                       <h4 className="title_fourth">13MP <br/>AI DUAL <br/>Rear Camera</h4>
                       <img src={`${baseurl}${basepath}/images/blaze2/3mp_camera.png`} className='img-fluid 3mp_camera' alt="Blaze 2" />
                    </div>
                  
                </div>
                <div className="_column_one_last">
                <h4 className="title_fourth"> ULTRA FAST T616 Chipset <br/>Best in class experience <br/> with 250K+ ANTUTU score</h4>
                    <img src={`${baseurl}${basepath}/images/blaze2/processor-t616.png`} className='img-fluid processor-t616' alt="Blaze 2" />
                </div>
            </div>
            <div className="_column_two">
                <div className="_columns_div">
                <h4 className="title_fourth">The bold and <br/>confident </h4>
                    <img src={`${baseurl}${basepath}/images/blaze2/autorecord.png`} className='img-fluid autorecord' alt="Blaze 2" />
                </div>
                <div className="_columns_div">
                <h4 className="title_fourth">18W <br/> Fast Charger <br/>In the Box</h4>
                    <img src={`${baseurl}${basepath}/images/blaze2/18w-img.png`} className='img-fluid 18w-img' alt="Blaze 2" />
                </div>
            </div>
        </div>
    </div>
     <ImpressDesign/>
     <PunchHole/>
     <InternalStorage/>
     <RearCamera/>
     <Promode/>
     <SlowMotionMode/>
     <TimeLapes/>
     <UltrafastProcessor/>
     <WhooshCharging/>
    </>
  )
}
