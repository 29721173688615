import React from 'react'
import  "../styles/lavagempower.scss";
import { Link } from 'react-router-dom';
import {basepath,baseurl} from '../basepath'

export default function LavaGemPower() {
  return (
    <>
    <section className='page_title inner_breadcum detailpage_breadcum'>
        <div className="full_container">
            <div className="left">
                <h4>Lava Gem Power</h4>
            </div>
            <div className="right">
                <Link to='/'>Overview</Link>
                <Link to='/'>Specification</Link>
            </div>
        </div>
    </section>
    <section className='gemBanner'>
    {/* <img alt="Lava Gem Power" src={`${baseurl}${basepath}/images/lavagempower/banner-bg.png`}   /> */}
      <div className="container">
          <div className="flex">
            <div class="text-center _leftscreen">
              <h1>Lava Gem Power</h1>
            <img alt="Lava Gem Power" data-aos="fade-up" src={`${baseurl}${basepath}/images/lavagempower/banner-content.png`}  className='aos-init aos-animate img-fluid' />
            </div>
            <div className="_rightscreen">
              <img alt="Lava Gem Power" src={`${baseurl}${basepath}/images/lavagempower/banner-mobile.png`}   className='img-fluid'/>
            </div>
          </div>
      </div>
    </section>
    <section className='infographics'>
        <div className="container">
          <div className="row">
            <div className='groups'>
                <div className='box'>
                  <h4>2.8” Super <br/>Big Display</h4>
                  <img alt="Lava Gem Power" src={`${baseurl}${basepath}/images/lavagempower/card1.png`}   className='img-fluid'/>
                </div>
                <div className='box'>
                  <h4>2575 mAh 
Battery</h4>
                  <img alt="Lava Gem Power" src={`${baseurl}${basepath}/images/lavagempower/card2.png`}   className='img-fluid'/>
                </div>
                <div className='box'>
                  <h4>Durable Design</h4>
                  <img alt="Lava Gem Power" src={`${baseurl}${basepath}/images/lavagempower/card3.png`}   className='img-fluid'/>
                </div>
                <div className='box'>
                  <h4>Wireless FM <br/>
with recording</h4>
                  <img alt="Lava Gem Power" src={`${baseurl}${basepath}/images/lavagempower/card4.png`}   className='img-fluid'/>
                </div>
                <div className='box'>
                  <h4>Auto Call <br/>
Recording</h4>
                  <img alt="Lava Gem Power" src={`${baseurl}${basepath}/images/lavagempower/card5.png`}   className='img-fluid'/>
                </div>
            </div>
          </div>
        </div>
    </section>
    <section className='gemscreen_01'>
      <div className="container">
          <div className="row justify-content-center">
              <div className='col-md-6'>
                  <div className="_card">
                    <img alt="Lava Gem Power" data-aos="fade-in"
                         src={`${baseurl}${basepath}/images/lavagempower/screen_01_mobile.png`}  
                         className='img-fluid aos-init aos-animate'/>
                  </div>
              </div>
              <div className='col-md-5'>
                <div className="_rightscreen">
                  <h2 data-aos="fade-left" className='aos-init aos-animate'>2.8" Super <br/>Big Display</h2>
                  <p>Clear and Vibrant Viewing Experience, whether you’re reading messages, browsing, or viewing multimedia content
</p>
                </div>
              </div>
          </div>
      </div>
    </section>
    <section className='gemscreen_02'>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-6">
              <div className="_leftscreen" >
                <h2 data-aos="fade-down" className='aos-init aos-animate'>10 Days<br/>Battery Backup</h2>
                <p>Unleash Endless Power,<br/>
It’s not just a phone; it’s your reliable companion!
</p>
              </div> 
            </div>
            <div className='col-md-6'>
           <div className="_rightscreen">
            <div className="_card">
                <img alt="Lava Gem Power" data-aos="fade-up"
                         src={`${baseurl}${basepath}/images/lavagempower/screen_02-mobile.png`}  
                         className='img-fluid aos-init aos-animate'/>
                 </div>
           </div>
            </div>
          </div>
        </div>
    </section>
    <section className='gemscreen_03'>
         <div className="container">
          <div className="row">
            <div className="col-md-5 p-0">
              <div className="_leftscreen">
                  <h2 data-aos="fade-up" className='aos-init aos-animate'>Elegance Meets <br/>
Durability</h2>
<p className='aos-init aos-animate' data-aos="fade-right">It’s not just a phone; it’s a statement!</p>
              </div>
            </div>
            <div className="col-md-7">
              <div className="_rightscreen">
              <div className="_card">
                <img alt="Lava Gem Power" data-aos="fade-in"
                         src={`${baseurl}${basepath}/images/lavagempower/screen_03-mobile.png`}  
                         className='img-fluid aos-init aos-animate'/>
                 </div>
              </div>
            </div>
          </div>
         </div>
    </section>
    <section className='gemscreen_04'>
        <div className="container">
          <div className="row">
            <div className='col-md-6'>
            <div className="_leftscreen">
            <img alt="Lava Gem Power" data-aos="fade-in"
                         src={`${baseurl}${basepath}/images/lavagempower/screen04-mobile.png`}  
                         className='img-fluid aos-init aos-animate'/>
            </div>
            </div>
            <div className='col-md-6'>
            <div className="_rightscreen aos-init aos-animate" data-aos="fade-right">
              <h2>Bluetooth Support</h2>
              <p>Easily transfer/Share your favorite Music, Video & Contacts</p>
            </div>
          </div>
          </div>
        </div>
    </section>
    <section className='gemscreen_05'>
          <div className="container">
              <div className="row">
                  <div className="col-md-6">
                    <div className="_leftscreen">
                      <h2>Expand memory 
                      up to 32GB</h2>
                      <p>Non Stop Entertainment
                      Anywhere, Anytime</p>
                    <img src={`${baseurl}${basepath}/images/lavagempower/screen05-32gb.png`} className='__23gb' alt='' /> 
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="_righscreen">
                    <img src={`${baseurl}${basepath}/images/lavagempower/screen05-mobile.png`} alt='' /> 
                    </div>
                  </div>
              </div>
          </div>
    </section>
    <section className='gemscreen_06'>
        <div className="container">
          <div className="row">
              <div className="col-md-7 mx-auto text-center">
                <div className="_topscreen">
                  <h2>Auto Call Recording</h2>
                  <p>Automatically Record Your Calls Within Folders</p>
                </div>
              <div className="_bottomscreen">
              <img src={`${baseurl}${basepath}/images/lavagempower/screen06-mobile.png`} alt='' /> 
              </div>
              </div>
              
          </div>
        </div>
    </section>
    </>
  )
}
