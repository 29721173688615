import React from 'react'
import {basepath,baseurl} from '../../basepath'

export default function SlowMotionMode() {
  return (
    <div className='slowmotionmode'>
        <div className="slowmotionmode_txt">
        <img src={`${baseurl}${basepath}/images/blaze2/slow-motion-text.png`} className='img-fluid' alt="Blaze 2" />
        </div>
        <div className="slow_container">
            <h2>Slow Motion Mode</h2>
            <p>
            Don't let life pass you by in a blur! <br/> With AI powered SLO-MO mode, you can capture the details of every moment and make them last forever. You can rejoice, relive, and enjoy every minute moment captured in stunning slow motion.
            It's like having a time machine in your pocket!
            </p>
        </div>
        <div className="slow-motion-up">
            <img src={`${baseurl}${basepath}/images/blaze2/slow-motion-up.png`} className='img-fluid _up' alt="Blaze 2" />
            <img src={`${baseurl}${basepath}/images/blaze2/slow-motion-shadow.png`} className='img-fluid _shadow' alt="Blaze 2" />

            <div className="_down">
            <img src={`${baseurl}${basepath}/images/blaze2/slow-motion-down.png`} className='img-fluid' alt="Blaze 2" />

            </div>

        </div>
    </div>
  )
}
