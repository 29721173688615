import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {basepath,baseurl} from '../basepath'

const url = process.env.REACT_APP_API_URL;

const Contact = () => {
  const [activeClass, setActiveClass] = useState(false)

  const [writeUs, setWriteUs] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    description: '',
  })

  // validation for write us form 

  const [writeNameError,setWriteNameError] = useState("")
  const [writeEmailError,setWriteEmailError] = useState("")
  const [writePhoneError,setWritePhoneError] = useState("")
  const [writeAddressError,setWriteAddressError] = useState("")
  const [writeDescriptionError,setWriteDescriptionError] = useState("")
  const [letterTypeError,setLetterTypeError] = useState("")
  const [productNameError,setProductNameError] = useState("")
  const [productTypeError,setProductTypeError] = useState("")
  const [categoryError,setCategoryError] = useState("")
  
  const [salesEnquiry, setSalesEnquiry] = useState({
    name: '',
    mobile: '',
    email: '',
    address: '',
    description: '',
    type: 'sales',
  })

  const [letterType, setLetterType] = useState('')
  const [productType, setProductType] = useState('')
  const [productName, setProductName] = useState('')
  const [category, setCategory] = useState('')

  // validation for sales form 
  const [nameError,setNameError] = useState("")
  const [emailError,setEmailError] = useState("")
  const [mobileError,setMobileError] = useState("")
  const [addressError,setAddressError] = useState("")
  const [descriptionError,setDescriptionError] = useState("")
  const [writeuskeyError,setWriteuskeyError] = useState("")
  const [salesKeyError,setSalesKeyError] = useState("")


  const [captcha,setCaptcha] = useState("")
  const [captchaSales, setCaptchaSales] = useState("")
  const [writeusKey,setWriteusKey] = useState("")
  const [salesKey,setSalesKey]= useState("")

const generateCaptcha =()=>{
  var chars = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";  
  var string_length = 5;  
    var randomstring = '';
    for (var i=0; i<string_length; i++) {  
      var rnum = Math.floor(Math.random() * chars.length);  
      randomstring += chars.substring(rnum,rnum+1);  
  } 
  let string = Math.random().toString(36).slice(2, 7);
  setCaptcha(randomstring)
}
const generateCaptcha2 =()=>{
  var chars = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";  
  var string_length = 5;  
    var randomstring = '';
    for (var i=0; i<string_length; i++) {  
      var rnum = Math.floor(Math.random() * chars.length);  
      randomstring += chars.substring(rnum,rnum+1);  
  } 
  let string = Math.random().toString(36).slice(2, 7);
  setCaptchaSales(randomstring)
}

useEffect(()=>{
  generateCaptcha2()
  generateCaptcha()
},[])

  const handleLetterType = (e) => {
    setLetterType(e.target.value)
  }

  const handleProductType = (e) => {
    setProductType(e.target.value)
  }

  const handleProductName = (e) => {
    setProductName(e.target.value)
  }

  const handleCategory = (e) => {
    setCategory(e.target.value)
  }

  const handelWriteUs = (e) => {
    const {name, value} = e.target
    setWriteUs(() => ({
      ...writeUs,
      [name]: value,
    }))
  }

  const handelSalseEnquiry = (e) => {
    const {name, value} = e.target
    setSalesEnquiry(() => ({
      ...salesEnquiry,
      [name]: value,
    }))
  }

  const handleSalesKey=(e)=>{
    setSalesKey(e.target.value)
  }

  const handelWriteUskey =(e)=>{
    setWriteusKey(e.target.value)
  }

  const submitDetails = async () => {

    if(writeUs.name===""){
      setWriteNameError("Please insert your name")
      return
    }
    if(writeUs.email===""){
      setWriteEmailError("Please insert your email")
      return
    }
    if(writeUs.phone===""){
      setWritePhoneError("Please insert your phone number")
      return
    }    
    if(writeUs.address===""){
      setWriteAddressError("Please insert your address")
      return
    }
    if(writeUs.description===""){
      setWriteDescriptionError("Please insert description")
      return
    }
    if(letterType===""){
      setLetterTypeError("Please select letter type")
      return
    }
    if(productName===""){
      setProductNameError("Please select product name")
      return
    }
    if(productType===""){
      setProductTypeError("Please select product type")
      return
    }
    if(category===""){
      setCategoryError("Please select category")
      return
    }

    if(captcha!==writeusKey){
        setWriteuskeyError("Please file up write captcha ")
        return
    }

    const detailsData = writeUs

    if (letterType !== '' || productName !== '' || productType !== '' || category !== '') {
      detailsData.feed_type = letterType
      detailsData.product_name = productName
      detailsData.product_type = productType
      detailsData.category = category
    } else {
      return
    }
    try {
      const res = await axios.post(
        `${url}/needhelp`,
        detailsData
      )
      if (res.status === 200) {
        setCategory('')
        setProductName('')
        setLetterType('')
        setWriteUs({
          name: '',
          phone: '',
          email: '',
          address: '',
          description: '',
        })
        setLetterType('')
        setCaptcha("")
        setWriteuskeyError("")
        setWriteusKey("")
        setWriteAddressError("")
        setWriteDescriptionError("")
        setWriteEmailError("")
        setWriteNameError("")
        setWritePhoneError("")
        setProductNameError("")
        setProductTypeError("")
        setLetterTypeError("")
        setCategoryError("")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const submitSalesEnquiry = async () => {
    try {

      if(salesEnquiry.name ===''){
        setNameError("Name can't be blank")
        return
      }
      if(salesEnquiry.mobile ===''){
        setMobileError("Mobile can't be blank")
        return
      }
      
      if(salesEnquiry.email ===''){
        setEmailError("Email can't be blank")
        return
      }
      if(salesEnquiry.address ===''){
        setAddressError("Address can't be blank")
        return
      }
      if(salesEnquiry.description ===''){
        setDescriptionError("Description can't be blank")
        return
      }
      if(salesKey!==captchaSales){
        setSalesKeyError("Please file up write captcha")
        return
      }

      const res = await axios.post(
        `${url}/contact`,
        salesEnquiry
      )
      if(res.status===200){
      setSalesEnquiry(
        {
          name: '',
          mobile: '',
          email: '',
          address: '',
          description: '',
          type: 'sales',
        }
      )  
      setAddressError("")
      setSalesKey("")
      setCaptcha("")
      setSalesKeyError("")
      setNameError("")
      setMobileError("")
      setEmailError("")
      setDescriptionError("")   
      }
      console.log(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <section>
        <div className='full_container innerpage_toppadding'>
          <div className='contact_1'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='section_title text-center'>
                  <h4>Contact Us</h4>
                  <div className='breaker'></div>
                </div>
              </div>
            </div>
            {/* <!--
				<div class="row location_map_sec">
					<div class="col-md-3">
						<div class="location_details">
							<div class="one_location">
								<h5>LAVA International Ltd.</h5>
								<p>Corporate address <br>
									A-56, Sector – 64, <br>
									Noida- 201301, UP, India <br>
									Tel: 120 463 7100</p>
							</div>

							<div class="one_location">
								<h5>Lava Helpline Number:</h5>
								<p><a href="">1860-500-5001 </a></p>
								<p>10.00 AM till 7.00 PM,</p>
								<p>Monday to Sunday</p>
							</div>


						</div>
					</div>

					<div class="col-md-9">
						<div class="location_details_map">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.5823928016976!2d77.37295661508215!3d28.612302382425806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5135260691d%3A0x2c4dec1e62551dcf!2sLava%20International%20Ltd.!5e0!3m2!1sen!2sin!4v1667218129992!5m2!1sen!2sin"
								style="border:0;" allowfullscreen="" loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"></iframe>
						</div>
					</div>
				</div> --> */}

            <div className='row'>
              <div className='col-md-12'>
                <div className='media_cnter helpline'>
                  <ul>
                    <li className='m_zero shadow-sm rounded-0 border-bottom"'>
                      <img src={`${baseurl}${basepath}/images/Support-icon.png`} alt='img' />
                      <h4>Helpline</h4>
                      <a href='tel:120 463 7100'>Tel: 120 463 7100</a> <br />
                      <a>Monday to Sunday</a> <br />
                      <a>10.00 AM till 7.00 PM</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <div className='media_cnter'>
                  <ul>
                    <li className="shadow-sm rounded-0 border-bottom">
                      <h4>Media Contacts</h4>
                      <a href='mailto:press@lavainternational.in'>press@lavainternational.in</a>
                    </li>

                    <li className="shadow-sm rounded-0 border-bottom">
                      <h4>Service Contacts</h4>
                      <a href='mailto:lavacare@lavainternational.in'>
                        lavacare@lavainternational.in
                      </a>
                    </li>

                    {/* <li>
                      <h4>Corporate Enquiry</h4>
                      <a href='mailto:enterprisesales@lavainternational.in'>
                        enterprisesales@lavainternational.in
                      </a>
                    </li> */}

                    <li className="shadow-sm rounded-0 border-bottom">
                      <h4>Business Opportunity</h4>
                      <a href='mailto:rahul.kumar1@lavainternational.in'>
                        rahul.kumar1@lavainternational.in
                      </a>
                    </li>

                    <li className="shadow-sm rounded-0 border-bottom">
                      <h4>Whistleblower Contacts</h4>
                      <a href='mailto:speakup@lavainternational.in'>speakup@lavainternational.in</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='full_container'>
          <div className='contact_1'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='section_title'>
                  <h4>Submit your Enquiry</h4>
                  <div className='breaker'></div>
                </div>
              </div>
            </div>

            <div className='row submit_your_enquiry'>
              <div className='col-md-12'>
                <div className='submit_tabs'>
                  {/* <!-- Nav pills --> */}
                  <ul className='nav nav-pills'>
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${!activeClass ? 'active' : ''}`}
                        data-toggle='pill'
                        onClick={() => setActiveClass(false)}
                      >
                        Write to Us
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${activeClass ? 'active' : ''}`}
                        data-toggle='pill'
                        onClick={() => setActiveClass(true)}
                      >
                        Sales Enquiry
                      </a>
                    </li>
                  </ul>

                  {/* <!-- Tab panes --> */}
                  <div className='tab-content'>
                    <div className={`tab-pane ${!activeClass ? 'active' : ''}`} id='home'>
                      <div className='write_form'>
                        <form>
                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Name <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='text'
                                  name='name'
                                  onChange={handelWriteUs}
                                  value={writeUs.name}
                                  className='form-control'
                                />
                              </div>
                              {
                                writeUs.name === "" && <p style={{color:'red'}} >{writeNameError}</p>
                              }
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Phone Number <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='number'
                                  name='phone'
                                  onChange={handelWriteUs}
                                  value={writeUs.phone}
                                  className='form-control'
                                />
                              </div>
                              {
                                writeUs.phone === "" && <p style={{color:'red'}} >{writePhoneError}</p>
                              }
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Your Email ID <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='email'
                                  onChange={handelWriteUs}
                                  name='email'
                                  value={writeUs.email}
                                  className='form-control'
                                />
                              </div>
                              {
                                writeUs.email === "" && <p style={{color:'red'}} >{writeEmailError}</p>
                              }
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Address <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='text'
                                  onChange={handelWriteUs}
                                  value={writeUs.address}
                                  name='address'
                                  className='form-control'
                                />
                              </div>
                              {
                                writeUs.address === "" && <p style={{color:'red'}} >{writeAddressError}</p>
                              }
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Letter Type <span className='required'>*</span>{' '}
                                </label>
                                <select
                                  className='form-control'
                                  value={letterType}
                                  onChange={handleLetterType}
                                >
                                  <option value='Select Feedback'>Select Feedback</option>
                                  <option value='1'>1</option>
                                  <option value='2'>2</option>
                                </select>
                              </div>
                              {
                                letterType === "" && <p style={{color:'red'}} >{letterTypeError}</p>
                              }
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Product Type<span className='required'>*</span>{' '}
                                </label>
                                <select
                                  className='form-control'
                                  value={productType}
                                  onChange={handleProductType}
                                >
                                  <option selected='selected' value='0'>
                                    -Select Product Type-
                                  </option>
                                  <option value='accessories'>Accessories</option>
                                  <option value='feature phone'>Feature phone</option>
                                  <option value='laptops'>Laptops</option>
                                  <option value='smart phone'>Smart Phone</option>
                                  <option value='tablets'>Tablets</option>
                                </select>
                              </div>
                              {
                                productType === "" && <p style={{color:'red'}} >{productTypeError}</p>
                              }
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputPassword1' className='mb-2'>
                                  Product Name
                                </label>

                                <select
                                  className='form-control'
                                  value={productName}
                                  onChange={handleProductName}
                                >
                                  <option value='0'>-Select Product-</option>
                                  <option value='LAVA A77'>Lava Blaze</option>
                                  <option value='Lava Helium 12'>Blaze 5G</option>
                                  <option value='Lava Helium 14'>Blaze Pro</option>
                                  <option value='LAVA A77'>Lava Z3</option>
                                  <option value='Lava Twinpad'>Champion A1</option>
                                  <option value='Lava Helium 12'>Lava A5</option>
                                  <option value='Lava Helium 14'>Hero 600+</option>
                                  <option value='Lava Twinpad'>A3 Power</option>
                                  <option value='Lava Twinpad'>A1 Super 2021</option>
                                </select>
                              </div>
                              {
                                productName === "" && <p style={{color:'red'}} >{productNameError}</p>
                              }
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputPassword1' className='mb-2'>
                                  Category
                                </label>
                                <select
                                  className='form-control'
                                  value={category}
                                  onChange={handleCategory}
                                >
                                  <option value='0'>-Select Category-</option>
                                  <option selected='selected' value='Smartphone'>Smart Phones</option>
                                  <option value='Featurephone'>Feature Phones</option>
                                </select>
                              </div>
                              {
                                category === "" && <p style={{color:'red'}} >{categoryError}</p>
                              }
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputPassword1' className='mb-2'>
                                  Enter Details <span className='required'>*</span>
                                </label>
                                <textarea
                                  name='description'
                                  onChange={handelWriteUs}
                                  value={writeUs.description}
                                  className='form-control'
                                  rows='5'
                                ></textarea>
                              </div>
                              {
                                writeUs.description === "" && <p style={{color:'red'}} >{writeDescriptionError}</p>
                              }
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Security Key<span className='required'>*</span>{' '}
                                </label>
                                <div className='input-group'>
                                  <input
                                    type='text'
                                    name='writeusKey'
                                    className='form-control'
                                    value={writeusKey}
                                    required
                                    onChange={handelWriteUskey}
                                  />
                                  <div className='input-group-prepend'>
                                    <span className='input-group-text' id='inputGroupPrepend2'>
                                      {captcha}
                                    </span>
                                  </div>
                                  
                                </div>
                                {writeuskeyError!=="" && writeusKey===""  && <p style={{color:'red'}} >{writeuskeyError}</p>}
                              </div>

                              <button
                                type='button'
                                onClick={submitDetails}
                                className='btn btn-primary'
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className={`tab-pane ${activeClass ? 'active' : ''}`} id='menu1'>
                      <div className='write_form'>
                        <form>
                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Name <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='text'
                                  name='name'
                                  onChange={handelSalseEnquiry}
                                  value={salesEnquiry.name}
                                  className='form-control'
                                />
                              </div>
                              {
                                salesEnquiry.name === ""?<p style={{color:'red'}} >{nameError}</p>:""
                              }
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Phone Number <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='number'
                                  name='mobile'
                                  maxLength={12}
                                  minLength={10}
                                  onChange={handelSalseEnquiry}
                                  value={salesEnquiry.mobile}
                                  className='form-control'
                                />
                              </div>
                              {
                                salesEnquiry.mobile === ""?<p style={{color:'red'}} >{mobileError}</p>:""
                              }
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Your Email ID <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='email'
                                  name='email'
                                  onChange={handelSalseEnquiry}
                                  value={salesEnquiry.email}
                                  className='form-control'
                                />
                              </div>
                              {
                                salesEnquiry.email === ""?<p style={{color:'red'}} >{emailError}</p>:""
                              }
                            </div>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Address <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='text'
                                  name='address'
                                  value={salesEnquiry.address}
                                  onChange={handelSalseEnquiry}
                                  className='form-control'
                                />
                              </div>
                              {
                                salesEnquiry.address === ""?<p style={{color:'red'}} >{addressError}</p>:""
                              }
                            </div>
                            
                          </div>
                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputPassword1' className='mb-2'>
                                  Enter Details <span className='required'>*</span>
                                </label>
                                <textarea
                                  className='form-control'
                                  name='description'
                                  value={salesEnquiry.description}
                                  onChange={handelSalseEnquiry}
                                  rows='5'
                                ></textarea>
                              </div>
                              {
                                salesEnquiry.description === ""?<p style={{color:'red'}} >{descriptionError}</p>:""
                              }
                            </div>
                            
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Security Key<span className='required'>*</span>{' '}
                                </label>
                                <div className='input-group'>
                                  <input
                                    type='text'
                                    name='salesKey'
                                    className='form-control'
                                    value={salesKey}
                                    onChange={handleSalesKey}
                                    required
                                  />
                                  <div className='input-group-prepend'>
                                    <span className='input-group-text' id='inputGroupPrepend2'>
                                      {captchaSales}
                                    </span>
                                  </div>
                                </div>
                                {salesKeyError!=="" && salesKey===""  && <p style={{color:'red'}} >{salesKeyError}</p>}
                              </div>
                              <button
                                type='button'
                                onClick={submitSalesEnquiry}
                                className='btn btn-primary'
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
