import './App.css'
import React, {useEffect, useState} from 'react'
import {Route, Routes,useLocation } from 'react-router-dom'
import HomePage from './pages/HomePage'
import Product from './pages/Product'
import ProductDetails from './pages/ProductDetails'
import Specification from './pages/Specification'
import Contact from './pages/Contact'
import CustomerService from './pages/CustomerService'
import PressAndMedia from './pages/PressAndMedia'
import StoresAndDealers from './pages/StoresAndDealers'
import Support from './pages/Support'
import About from './pages/About'
import {basepath} from './basepath'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Condition from './pages/Condition'
import LavaBlaze from './pages/LavaBlaze'
import Blazepro from './pages/Blazepro'
import Search from './pages/Search'
import AOS from 'aos'
import 'aos/dist/aos.css'
import CommingSoon from './components/CommingSoon'
import ServiceCenter from './pages/ServiceCenter'
import Yuvapro from './pages/Yuvapro'
import Blaze2 from './pages/Blaze2'
import LavaGemPower from './pages/LavaGemPower'
import AtTorchBlack from './pages/AtTorchBlack'
import A1Josh from './pages/A1Josh'
import A3Vibe from './pages/A3Vibe'
import A1Vibe from './pages/A1Vibe'
import Hero600glow from './pages/Hero600glow'

import LandingPage from './pages/LandingPage'
import axios from 'axios'



function App() {
  
   let link =  window.location.href
   console.log("link ="+link);
   //const [landing,setLanding] = useState(link)
   let landing = false;
  
  useEffect(()=>{
   AOS.init({
    // initClassName: 'aos-init ',
    // delay: 100,
    // useClassNames: true,
    // initClassName: true,
    // animatedClassName: 'aos-animate',
    // initClassName: 'aos-init aos-animate',
    // startEvent: 'DOMContentLoaded'
    })
  },[])
  // if(link==='https://bd.lavamobiles.com/')
  // {
  //  landing = true;
  // }

  console.log("landing=="+landing);
  
  return (
    <Routes>
<Route path="*" element={<CommingSoon title={"Sorry! We are under maintenance currently!!"} />} />
      <Route exact path={`/${basepath}/service-center`} element={<ServiceCenter />} />
      <Route path={`/${basepath}`} element={landing? <LandingPage/> : <HomePage />} />
      <Route path={`/${basepath}/product/:slug`} exact element={<Product />} />
      <Route path={`/${basepath}/yuva-2-pro`} exact element={<Yuvapro/>} />
      <Route path={`/${basepath}/blaze-2`} exact element={<Blaze2/>} />
      <Route path={`/${basepath}/lava-gem-power`} exact element={<LavaGemPower/>} />
      <Route path={`/${basepath}/a7-torch`} exact element={<AtTorchBlack/>} />
      <Route path={`/${basepath}/a1-josh`} exact element={<A1Josh/>} />
      <Route path={`/${basepath}/a3-vibe`} exact element={<A3Vibe/>} />
      <Route path={`/${basepath}/a1-vibe`} exact element={<A1Vibe/>} />
      <Route path={`/${basepath}/hero-glow`} exact element={<Hero600glow/>} />
      <Route exact path={`/${basepath}/product-details/:slug`}  element={<ProductDetails />} />
      <Route exact path={`/${basepath}/specification/:slug`} element={<Specification />} />
      <Route exact path={`/${basepath}/contact`} element={<Contact />} />
      <Route exact path={`/${basepath}/press-and-media`} element={<PressAndMedia />} />
      <Route exact path={`/${basepath}/stores-and-dealers`} element={<StoresAndDealers />} />
      <Route exact path={`/${basepath}/support`} element={<Support />} />
      <Route exact path={`/${basepath}/about`}  element={<About />} />
      <Route exact path={`/${basepath}/privacy`}  element={<PrivacyPolicy />} />
      <Route exact path={`/${basepath}/condition`}  element={<Condition />} />
      <Route exact path={`/${basepath}/lavaBlaze`}  element={<LavaBlaze />} />
      <Route exact path={`/${basepath}/blazepro`}  element={<Blazepro />} /> 
      <Route path={`/${basepath}/product-search/:searchQuery`} exact element={<Search />} />
      <Route exact path={`/${basepath}/CustomerService`}  element={<CustomerService />} />
    </Routes>
  )
}

export default App
