import React from 'react'
import {basepath,baseurl} from '../../basepath'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function RearCamera() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        arrows:false,
      };
  return (
    <div className='rearcamera'>
            <div className="titlecontent">
               <img src={`${baseurl}${basepath}/images/blaze2/13mp-heading.png`} className='img-fluid' alt="Blaze 2" />
               <p>Capture every shot with Multiple Camera Modes that let you snap every moment, every mood, every occasion with dazzling, 
                smart and vibrant photos taken by the amazing, AI powered 13MP dual camera.</p>
            </div>
            <div className="mp13-gallery">
            <div className='mask-container'>
                <Slider {...settings} className='slider-container'>
                <div>  <img src={`${baseurl}${basepath}/images/blaze2/13mp-gallery.png`} className='' alt="Blaze 2"/> </div>
                <div>  <img src={`${baseurl}${basepath}/images/blaze2/13mp-gallery.png`} className='' alt="Blaze 2"/> </div>
                <div>  <img src={`${baseurl}${basepath}/images/blaze2/13mp-gallery.png`} className='' alt="Blaze 2"/> </div>
                <div>  <img src={`${baseurl}${basepath}/images/blaze2/13mp-gallery.png`} className='' alt="Blaze 2"/> </div>
                </Slider>
            </div>
            </div>
    </div>
  )
}
