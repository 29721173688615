import React from "react";
import { Link } from "react-router-dom";
import {basepath,baseurl} from '../basepath'

const PrivacyPolicy = () => {
  return (
    <>
      <section className="innerpage_toppadding">
        <div className="full_container">
          <div className="contact_1">
            <div className="row">
              <div className="col-md-12">
                <div className="section_title text-center">
                  <h4>Lava Privacy Policy</h4>
                  <div className="breaker"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="faq1  terms">
                  <div className="ip_content">
                    <p>
                      This Privacy Policy <strong>("Policy")</strong> is
                      published in accordance with the provisions of Rule 3(1)
                      of the Information Technology (Intermediaries Guidelines)
                      Rules, 2011. The Policy is an 'electronic record' as
                      contemplated under section 2(t) of the Information
                      Technology Act, 2000 and the rules. This Policy being an
                      electronic record is generated by a computer system and
                      does not require any physical or digital signature.
                    </p>

                    <p>
                      In addition to this Privacy Policy, we provide data and
                      privacy information embedded in our products and certain
                      features that ask to use your personal information.
                    </p>

                    <p>
                      You will be given an opportunity to review this
                      product-specific information before enabling these
                      features. You also can view this information at any time,
                      either in Settings related to those features and/or online
                      at Lava.com/legal/privacy.
                    </p>

                    <h4 className="title">What Is Personal Data at Lava?</h4>
                    <p>
                      At Lava, we believe strongly in fundamental privacy rights
                      - and that those fundamental rights should not differ
                      depending on where you live in the world.{" "}
                      <strong>
                        That's why we treat any data that relates to an
                        identified or identifiable individual or that is linked
                        or linkable to them by Lava as "personal data," no
                        matter where the individual lives.
                      </strong>{" "}
                      This means that data that directly identifies you - such
                      as your name, email address, residential address, phone
                      number, photograph and other information collected by
                      specific services/products - is personal data, and also
                      data that does not directly identify you, but that can
                      reasonably be used to identify you - such as the serial
                      number of your device, IP address, login id, email
                      address, password, devise connection information,
                      transaction history, date, time and duration of session
                      etc. (collectively referred as 'personal data').
                    </p>

                    <p>
                      This Privacy Policy covers how Lava handles personal data
                      whether you interact with us on our websites, through Lava
                      apps (such as Lava Pay), or in person (including by phone
                      or when visiting our retail stores or authorised service
                      partners). Lava may also link to third parties on our
                      services or make third-party apps available for download
                      in our App Store. Lava's Privacy Policy does not apply to
                      how third parties define personal data or how they use it.
                      We encourage you to read their privacy policies and know
                      your privacy rights before interacting with them.
                    </p>

                    <h4 className="title">Your Privacy Rights at Lava</h4>
                    <p>
                      At Lava, we respect your ability to know, access, correct,
                      transfer, restrict the processing of, and delete your
                      personal data. We have provided these rights to our global
                      customer base and if you choose to exercise these privacy
                      rights, you have the right not to be treated in a
                      discriminatory way nor to receive a lesser degree of
                      service from Lava. Lava does not sell your data to any
                      individual or entity.
                    </p>

                    <p>
                      <strong>
                        To exercise your privacy rights and choices, visit the
                        Lava website at{" "}
                        <a
                          href="https://www.lavamobiles.com"
                          target="_blank"
                          style={{ color: "#ed1556" }}
                        >
                          www.lavamobiles.com{" "}
                        </a>
                        .
                      </strong>
                    </p>
                    <p>
                      There may be situations where we cannot grant your request
                      - for example, if you ask us to delete your transaction
                      data and Lava is legally obligated to keep a record of
                      that transaction to comply with law. We may also decline
                      to grant a request where doing so would undermine our
                      legitimate use of data for anti-fraud and security
                      purposes, such as when you request deletion of an account
                      that is being investigated for security concerns. Other
                      reasons your privacy request may be denied are if it
                      jeopardizes the privacy of others, is frivolous or
                      vexatious, or would be extremely impractical.
                    </p>

                    <h4 className="title">
                      Personal Data Lava Collects from You
                    </h4>
                    <p>
                      At Lava, we believe that you can have great products and
                      great privacy. This means that we strive to{" "}
                      <strong>
                        collect only the personal data that we need.
                      </strong>{" "}
                      The personal data Lava collects depends on how you
                      interact with Lava.
                    </p>
                    <p>
                      When you create an ID, apply for commercial credit,
                      purchase and/or activate a product or device, download a
                      software update, register for a class, connect to our
                      services, contact us (including by social media),
                      participate in an online survey, or otherwise interact
                      with Lava, we may collect a variety of information,
                      including:
                    </p>
                    <ul className="list">
                      <li>
                        <strong>Account Information.</strong> Your ID and
                        related account details, including email address,
                        devices registered, account status, and age
                      </li>
                      <li>
                        <strong>Device Information.</strong> Data from which
                        your device could be identified, such as device serial
                        number, or about your device, such as browser type
                      </li>
                      <li>
                        <strong>Contact Information.</strong> Data such as name,
                        email address, physical address, phone number, or other
                        contact information
                      </li>
                      <li>
                        <strong>Payment Information.</strong> Data about your
                        billing address and method of payment, such as bank
                        details, credit, debit, or other payment card
                        information
                      </li>
                      <li>
                        <strong>Transaction Information.</strong> Data about
                        purchases of Lava products and services or transactions
                        facilitated by Lava, including purchases on Lava
                        platforms
                      </li>
                      <li>
                        <strong>Fraud Prevention Information.</strong> Data used
                        to help identify and prevent fraud, including a device
                        trust score
                      </li>
                      <li>
                        <strong>Usage Data.</strong> Data about your activity on
                        and use of our offerings, such as app launches within
                        our services, including browsing history; search
                        history; product interaction; crash data, performance
                        and other diagnostic data; and other usage data
                      </li>

                      <li>
                        <strong>Health Information.</strong> Data relating to
                        the health status of an individual, including data
                        related to one's physical or mental health or condition.
                        Personal health data also includes data that can be used
                        to make inferences about or detect the health status of
                        an individual.{" "}
                      </li>
                      <li>
                        <strong>Government ID Data.</strong> In certain
                        jurisdictions, we may ask for a government-issued ID in
                        limited circumstances, including when setting up a
                        wireless account and activating your device, for the
                        purpose of extending commercial credit, managing
                        reservations, or as required by law
                      </li>
                      <li>
                        <strong>Other Information You Provide to Us.</strong>{" "}
                        Details such as the content of your communications with
                        Lava, including interactions with customer support and
                        contacts through social media channels You are not
                        required to provide the personal data that we have
                        requested. However, if you choose not to do so, in many
                        cases we will not be able to provide you with our
                        products or services or respond to requests you may
                        have.
                      </li>
                    </ul>

                    <h4 className="title">
                      Personal Data Lava Receives from Other Sources
                    </h4>
                    <p>
                      Lava may receive personal data about you from other{" "}
                      <strong>individuals,</strong> from businesses or third
                      parties acting <strong>at your direction,</strong> from
                      our <strong>partners</strong> who work with us to provide
                      our products and services and assist us in security and
                      fraud prevention, and from other lawful sources.
                    </p>
                    <ul className="list">
                      <li>
                        <strong>Individuals.</strong> Lava may collect data
                        about you from other individuals - for example, if that
                        individual has sent you a product or gift card, invited
                        you to participate in an Lava service or forum, or
                        shared content with you.
                      </li>
                      <li>
                        <strong>At Your Direction.</strong> You may direct other
                        individuals or third parties to share data with Lava.
                        For example, you may direct your mobile carrier to share
                        data about your carrier account with Lava for account
                        activation, or for your loyalty program to share
                        information about your participation so that you can
                        earn rewards for Lava purchases.
                      </li>
                    </ul>

                    <h4 className="title">Lava's Use of Personal Data</h4>
                    <p>
                      <strong>
                        Lava uses personal data to power our services, to
                        process your transactions, to communicate with you, for
                        security and fraud prevention, and to comply with law.
                        We may also use personal data for other purposes with
                        your consent.
                      </strong>
                    </p>

                    <p>
                      <strong>
                        Lava uses your personal data only when we have a valid
                        legal basis to do so.
                      </strong>{" "}
                      Depending on the circumstance, Lava may rely on your
                      consent or the fact that the processing is necessary to
                      fulfill a contract with you, protect your vital interests
                      or those of other persons, or to comply with law. We may
                      also process your personal data where we believe it is in
                      our or others' legitimate interests, taking into
                      consideration your interests, rights, and expectations.
                    </p>
                    <ul className="list">
                      <li>
                        <strong>Process Your Transactions.</strong> To process
                        transactions, Lava must collect data such as your name,
                        purchase, and payment information.
                      </li>
                      <li>
                        <strong>Communicate with You.</strong> To respond to
                        communications, reach out to you about your transactions
                        or account, market our products and services, provide
                        other relevant information, or request information or
                        feedback. From time to time, we may use your personal
                        data to send important notices, such as communications
                        about purchases and changes to our terms, conditions,
                        and policies. Because this information is important to
                        your interaction with Lava, you may not opt out of
                        receiving these important notices.
                      </li>
                      <li>
                        <strong>Security and Fraud Prevention.</strong> To
                        protect individuals, employees, and Lava and for loss
                        prevention and to prevent fraud, including to protect
                        individuals, employees, and Lava for the benefit of all
                        our users, and prescreening or scanning uploaded content
                        for potentially illegal content.
                      </li>
                      <li>
                        <strong>Comply with Law.</strong> To comply with
                        applicable law - for example, to satisfy tax or
                        reporting obligations, or to comply with a lawful
                        governmental request.
                      </li>
                    </ul>

                    <p>
                      Lava does not use algorithms or profiling to make any
                      decision that would significantly affect you without the
                      opportunity for human review.
                    </p>

                    <p>
                      <strong>
                        Lava retains personal data only for so long as necessary
                        to fulfill the purposes for which it was collected,
                        including as described in this Privacy Policy or in our
                        service-specific privacy notices, or as required by law.
                      </strong>{" "}
                      We will retain your personal data for the period necessary
                      to fulfill the purposes outlined in this Privacy Policy
                      and our service-specific privacy summaries. When assessing
                      retention periods, we first carefully examine whether it
                      is necessary to retain the personal data collected and, if
                      retention is required, work to retain the personal data
                      for the shortest possible period permissible under law.
                    </p>

                    <h4 className="title">
                      Protection of Personal Data at Lava
                    </h4>
                    <p>
                      At Lava, we believe that great privacy rests on great
                      security. We use{" "}
                      <strong>
                        administrative, technical, and physical safeguards
                      </strong>{" "}
                      to protect your personal data, taking into account the
                      nature of the personal data and the processing, and the
                      threats posed. We are constantly working to improve on
                      these safeguards to help keep your personal data secure.
                      However, any such safeguards cannot eliminate security
                      risks. Lava endeavours to take all measures to protect the
                      security, integrity and confidentiality of the personal
                      data against unauthorized breach and hacking. However,
                      Lava cannot ensure or warrant a 100% security of the
                      personal data. Lava will not be responsible or liable in
                      any manner whatsoever, for any unauthorised access of use
                      of the personal data.
                    </p>
                    <p>
                      If any user is provided with any identification code,
                      password or any other piece of information as part of the
                      product/service's security procedure, the user should
                      treat such information as confidential, and the user
                      should not disclose it to any third party including to
                      other users. To prevent third party's access to the
                      product/service, the user should sign out from the user
                      account after availing the services. Lava has the right to
                      disable the identification code or password, whether
                      chosen by any user or allotted by Lava, at any time, if
                      the user fails to comply with instructions or terms,
                      conditions or policies of Lava related to use of
                      products/services and this privacy policy.
                    </p>

                    <p>
                      The user is solely responsible for the activities that
                      occur under the user's account including the
                      confidentiality of the user's password and Lava is not
                      responsible for the same. While using and accessing the
                      product/service, the user is responsible for maintaining
                      the confidentiality of the user's account and for
                      restricting access to the user's computer or other system
                      or equipment through which the user uses and accesses the
                      service/product.
                    </p>

                    <p>
                      Notwithstanding anything contained in this Policy or
                      elsewhere, Lava shall not be held responsible for any
                      loss, damage or misuse of the personal data, if such loss,
                      damage or misuse is attributable to a Force Majeure Event.
                      A <strong>"Force Majeure Event"</strong> means any event
                      that is beyond the reasonable control of Lava and
                      includes, without limitation, fire, flood, explosion, acts
                      of God, civil commotion, strikes, lock outs or industrial
                      action of any kind, riots, insurrection, war, acts of
                      government, power failure, sabotage, computer hacking,
                      unauthorised access to computer data and storage device,
                      system failure, virus, attacks, bugs, computer crashes,
                      breach of security and encryption.
                    </p>

                    <h4 className="title">Third Party Websites And Links</h4>

                    <p>
                      Lava's products/services may provide or be linked with
                      other websites, services and/or advertisements. Lava is in
                      no way responsible for the privacy practices or content of
                      such websites. The linked websites or advertisers are not
                      necessarily under the control of Lava and Lava expressly
                      disclaims all liability associated with the content and
                      the use of such websites and advertisements. Display of
                      any third party advertisements on the products/services
                      shall not be construed as an endorsement or promotion of
                      any products or services by Lava. The user should read the
                      privacy policy of each and every linked
                      website/service/product and any such access or use is
                      entirely at the user's own risk. Lava is not responsible
                      for any direct, indirect, solicited or unsolicited
                      communication made by any of these linked third party
                      websites or advertisers or their respective agents or
                      representatives, to the users including by telephone,
                      email, mobile phones and facsimile.
                    </p>

                    <p>
                      These linked third-party websites and advertisers may use
                      various technologies to receive the user's IP address and
                      personal information. The third party advertisers may also
                      use cookies, JavaScript, web beacons and other
                      technologies to gauge the effectiveness of their
                      advertisements and to personalize advertising experience
                      and content. Lava does not have any control over the
                      cookies or technologies that advertisers or websites may
                      use and the user should contact them directly for more
                      information about their privacy practices and policies.
                    </p>

                    <p>
                      Based on the personal data including the nature of
                      products purchased by the user, Lava may display
                      personalized third party advertisements on the Website.
                      When the user clicks on the advertisement link, the third
                      party advertisers may assume that the user has met the
                      criteria that was used to personalize the advertisement.
                      The user acknowledges and agrees that in no event Lava is
                      responsible to the user accessing the services of third
                      party advertisers or other websites.
                    </p>

                    <h4 className="title">Cookies and Other Technologies</h4>
                    <p>
                      Lava's websites, online services, interactive
                      applications, email messages, and advertisements may use
                      "cookies" and other technologies.{" "}
                      <strong>
                        These technologies help us to better understand user
                        behavior including for security and fraud prevention
                        purposes, tell us which parts of our websites people
                        have visited, and facilitate and measure the
                        effectiveness of advertisements and web searches.
                      </strong>
                    </p>
                    <ul className="list">
                      <li>
                        <strong>Communications Cookies.</strong> These cookies
                        are used to enable network traffic to and from Lava's
                        systems, including by helping us detect any errors.
                      </li>
                      <li>
                        <strong>Strictly Necessary Cookies.</strong> These
                        cookies are set as required to provide a specific
                        feature or service that you have accessed or requested.
                        For example, they allow us to display our websites in
                        the proper format and language, to authenticate and
                        verify your transactions, and to preserve the contents
                        of your Bag when shopping online at Lava.com.
                      </li>
                      <li>
                        <strong>Other Cookies.</strong> These cookies are used
                        to understand how visitors interact with our websites
                        and online services, including by helping us to assess
                        the effectiveness of advertisements and web searches.
                        Lava also uses these cookies to remember choices you
                        make while browsing, so we can provide you with a
                        customized experience.
                      </li>
                      <li>
                        <p>
                          If you prefer that Lava not use cookies, we provide
                          you with the means to disable their use. If you want
                          to disable cookies and you're using the web browser,
                          choose "Block all cookies" in web browser's privacy
                          settings. If you are using a different browser, check
                          with your provider to find out how to disable cookies.
                          Certain features of the Lava website may not be
                          available if all cookies are disabled.
                        </p>

                        <p>
                          In addition to cookies, Lava uses other technologies
                          that help us achieve similar objectives.
                        </p>

                        <p>
                          In some email messages Lava sends to you, we provide a
                          "click-through URL" that links you to content on the
                          Lava website. When you click one of these URLs, they
                          pass through a separate server before arriving at the
                          destination page on our website. We track this
                          click-through to help us determine interest in
                          particular topics and measure whether we are
                          communicating with you effectively. If you prefer not
                          to be tracked in this way, you should not click
                          graphic or text links in email messages.
                        </p>
                      </li>

                      <p>
                        <strong>
                          Lava generally treats data we collect using these
                          cookies and similar technologies as non-personal data.
                        </strong>{" "}
                        However, to the extent that Internet Protocol (IP)
                        addresses or similar identifiers are considered personal
                        data by local law, we also treat these identifiers as
                        personal data. In addition, Lava sometimes combines
                        non-personal data collected from these technologies with
                        other personal data Lava holds. When we combine data in
                        this way, we treat the combined data as personal data
                        for purposes of this Privacy Policy.
                      </p>

                      <h4 className="title">
                        Our Companywide Commitment to Your Privacy
                      </h4>
                      <p>
                        To make sure your personal data is secure, we
                        communicate our privacy and security guidelines to Lava
                        employees and strictly enforce privacy safeguards within
                        the company.
                      </p>

                      <h4 className="title">General Terms</h4>

                      <p>
                        This Policy is to be read in conjunction with and shall
                        be deemed to be part of Lava Products/Service Terms of
                        Use and Terms and Conditions of Sale.
                      </p>

                      <p>
                        The personal data resides in Lava's database for a
                        certain period of time even after the user ceases to use
                        or access the product/service and in such cases Lava is
                        not be liable for any usage of the personal data after
                        the user ceases to use or access the Website.
                      </p>

                      <p>
                        The Users agree and acknowledge that personal data
                        collated through the product/service could be stored and
                        processed in any other country across the world. The
                        users acknowledge that for effective provision of Lava's
                        products/services in respective territories including
                        territories outside India, the personal data may be
                        stored or processed by trusted third parties. Whenever
                        the personal data is shared with trusted third parties
                        in countries outside India, Lava endeavours that the
                        personal data is transferred in accordance with the
                        applicable laws of India.
                      </p>

                      <p>
                        The user should be aware that the User Information or
                        other data, the user provides to Lava could be read,
                        collected, or used by other users or third parties in
                        which case the personal data might be used for sending
                        unsolicited messages or communications for which Lava is
                        not responsible.
                      </p>

                      <p>
                        The user agrees and acknowledges Lava's right to utilize
                        the user's posts, contents, information and other
                        personal data for the purpose of providing services
                        through the Website. The contents on the Website may
                        contain comments, post and reviews posted by the users
                        which may be public and searchable on the internet in
                        which case the user's comments, post reviews and
                        information and personal data provided to the Website
                        may be viewed by other users and Lava cannot guarantee
                        that other users or third parties have not made copies
                        of or used the ideas and information that the user has
                        shared on the Website.
                      </p>

                      <h4 className="title">Grievance Officer</h4>

                      <p>
                        In case of any questions about Lava's Privacy Policy or
                        privacy practices, complaints or grievances, the user
                        may contact the Grievance Officer as under:
                      </p>

                      <p>
                        <strong>Name: Kushal Singh Chauhan</strong>
                      </p>
                      <p>
                        <strong>
                          Email: it.compliance@lavainternational.in{" "}
                        </strong>
                      </p>
                      <p>Phone number: +91 120 4637100</p>
                      <p>
                        Address: A-56, Sector 64, Noida, Gautam Budh Nagar,
                        Uttar Pradesh - 201301
                      </p>

                      <p>
                        You can also ask us questions about how to submit a
                        privacy complaint and we will endeavor to help.
                      </p>
                      <p>
                        Lava takes your privacy questions seriously. A dedicated
                        team reviews your inquiry to determine how best to
                        respond to your question or concern, including those
                        inquiries received in response to an access or download
                        request. In most cases, all substantive contacts receive
                        a response within seven days. In other cases, we may
                        require additional information or let you know that we
                        need more time to respond.
                      </p>

                      <p>
                        Where your complaint indicates an improvement could be
                        made in our handling of privacy issues, we will take
                        steps to make such an update at the next reasonable
                        opportunity. In the event that a privacy issue has
                        resulted in a negative impact on you or another person,
                        we will take steps to address that with you or that
                        other person. When there is a material change to this
                        Privacy Policy, we'll post a notice on this website at
                        least a week in advance of doing so and contact you
                        directly about the change if we have your data on file.
                      </p>

                      <h4 className="title">Governing Law And Jurisdiction</h4>

                      <p>
                        The terms and conditions of this Policy shall be
                        governed by Indian Law and the courts in New Delhi shall
                        only have exclusive jurisdiction to resolve any
                        disputes.
                      </p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
