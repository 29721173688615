import React from 'react'
import  "../styles/hero600glow.scss";
import { Link } from 'react-router-dom';
import {basepath,baseurl} from '../basepath'

export default function Hero600glow() {
  return (
    <>
    <section className='page_title inner_breadcum detailpage_breadcum'>
        <div className="full_container">
            <div className="left">
                <h4>Hero Glow</h4>
            </div>
            <div className="right">
                <Link to='/'>Overview</Link>
                <Link to='/'>Specification</Link>
            </div>
        </div>
    </section>
    <section className='heroBanner'>
        {/* <img alt="Hero 600 Glow" src={`${baseurl}${basepath}/images/hero600glow/hero_banner.png`} /> */}
        <div className="__container">
           <div className='container'>
              <div className="row">
                <div className="col-md-5">
                <div className="_leftscreen">
                    <img alt="hero-glow" src={`${baseurl}${basepath}/images/hero600glow/left-mobile_banner.png`} />
                  </div>
                </div>
                <div className="col-md-7">
                <div className="_rightscreen">
                    <h2>Hero Glow</h2>
                    <img alt="hero-glow" src={`${baseurl}${basepath}/images/hero600glow/right_content_banner.png`} />
                  </div>
                  
                </div>
              </div>
           </div>
        </div>
    </section>
    <section className="infoheroscreen">
        <div className="container">
          <div className="row">
              <div className="groups">
                <div className="_card">
                    <div className="box">
                    <img alt="Hero 600 glow" src="/images/hero600glow/card-mobile-1.png" class="img-fluid"/>
                    <h4>Glow Keypad</h4>
                    </div>
                </div>
                <div className="_card">
                    <div className="box">
                    <h4 style={{'top':'30px'}}>1000mAh Smart <br/> AI Battery</h4>
                    <img alt="Hero 600 glow" src="/images/hero600glow/card-mobile-2.png" class="img-fluid"/>
                    </div>
                </div>
                <div className="_card">
                    <div className="box">
                    <h4 style={{'top':'30px'}}>1.8” Screen</h4>
                    <img alt="Hero 600 glow" src="/images/hero600glow/card-mobile-3.png" class="img-fluid"/>
                    </div>
                </div>
                <div className="_card">
                    <div className="box">
                    <img alt="Hero 600 glow" src="/images/hero600glow/card-mobile-4.png" class="img-fluid"/>
                    <h4>One Touch Mute</h4>
                    </div>
                </div>
                <div className="_card">
                    <div className="box">
                    <img alt="Hero 600 glow" src="/images/hero600glow/card-mobile-5.png" class="img-fluid"/>
                    <h4>Auto Call <br />
                    Recording</h4>
                    </div>
                </div>
                
              </div>
          </div>
        </div>
    </section>
    <section className="Heroscreen_01">
          <div className="container">
            <div className="row">
               <div className="col-md-5">
               <div className="left">
                <h4>Hero Glow - Stay Lit,<br/>
                Stay Connected</h4>
                <p>our beacon in the night, with a glow-in-the-dark keypad for 24/7 ease.</p>
               </div>
               </div>
               <div className="col-md-7">
                <div className="right">
                  <img alt="Hero 600 glow" src="/images/hero600glow/screen_01_mobiles.png" class="img-fluid"/>
                </div>
               </div>
            </div>
          </div>
    </section>
    <section className="Heroscreen_02">
      <div className="container">
        <div className="row">
            <div className="col-md-6">
              <div className="_left_screen">
               <img alt="Hero 600 glow" src="/images/hero600glow/screen_02_left_mobile.png" class="img-fluid"/>
              </div>
            </div>
            <div className="col-md-6">

              <div className="_right_screen">
                <h2>
                <span>4</span>Days<br/>
                Battery Backup
                </h2>
                <div className="_txt">
                1000 mAH battery gives 4 days of battery backup <br/> 
with Super Battery Mode

                </div>
                <div className="table">
                <img alt="Hero 600 glow" src="/images/hero600glow/table_right.png" class="img-fluid"/>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
    <section className="Heroscreen_03">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="left">
              <h2>Shine with Style</h2>
              <p>Experience the perfect blend of elegance and resilience <br/>with its sleek design and durable build.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="_rightscreen">
            <img alt="Hero 600 glow" src="/images/hero600glow/screen_right_mobile.png" class="img-fluid"/>
            </div>
          </div>
        </div>
        </div>
        </section>
        <section className="Heroscreen_04">
      <div className="container">
          <div className="row">
              <div className="col-md-6">
                    <div className="_leftscreen">
                    <img alt="Hero 600 glow" src="/images/hero600glow/screen_04_left_mobile.png" class="img-fluid"/>
                    </div>
              </div>
              <div className="col-md-6">
                <div className="_rightscreen">
                  <h2>Bluetooth Support</h2>
                  <div className="_text">
                  Easily transfer/Share your favorite Music, Video & Contacts
                  </div>
                </div>
              </div>
          </div>
        </div>
        </section>
        <section className="Heroscreen_05">
      <div className="container">
          <div className="row">
              <div className="col-md-4">
                <div className="leftscreen">
                  <img alt="Hero 600 glow" src="/images/hero600glow/screen_05_mobile.png" class="img-fluid"/>
                </div>
              </div>
              <div className="col-md-8">
                <div className="rightscreen">
                  <h2>Expand memory 
                  up to 32GB</h2>
                  <p>Non Stop Entertainment Anywhere, Anytime</p>
                  <div className="memory">
                  <img alt="Hero 600 glow" src="/images/hero600glow/screen_05_memory.png" class="img-fluid"/>
                  </div>
                </div>
              </div>
          </div>
          </div>
      </section>
      <section className="Heroscreen_06">
      <div className="container">
          <div className="row">
            <div className="col-md-12">
                <div className="center_screen">
                  <h2>Auto Call Recording</h2>
                  <p>Automatically Record Your Calls Within Folders </p>
                  <div className="center">
                     <img alt="Hero 600 glow" src="/images/hero600glow/screen_06_mobiles.png" class="img-fluid"/>
                  </div>
                </div>
            </div>
          </div>
          </div>
          </section>
    </>
  )
}
