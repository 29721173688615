import React,{useEffect} from 'react'
import '../../styles/yuva2proj.scss'
import {basepath,baseurl} from '../../basepath'
import AOS from 'aos'

export default function Marathonbattery() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return (
    <div className='marathonbattery'>
        <div className="title_container">
            <h2>Marathon 5000mAh Battery</h2>
            <h4>Just do more worry-free with Yuva 2 pro!</h4>
            <p>Yuva 2 pro comes with the marathon 5000mAh battery, you can use it all day without worrying about running out of juice. Whether you're gaming, streaming, or browsing, Yuva 2 pro will keep you going. </p>
            <h3>No more low battery anxiety! </h3>
        </div>
        <div className="text_marathone">
           <img src={`${baseurl}${basepath}/images/yuva2pro/5000mah.png`} className='img-fluid' alt="Yuva 2 Pro"/>
           <img src={`${baseurl}${basepath}/images/yuva2pro/5000mah-effect.png`} className='img-fluid _shadow' alt="Yuva 2 Pro"/>


        </div>
        <div className="battery-mobile">
           <img src={`${baseurl}${basepath}/images/yuva2pro/battery-mobile.png`} className='img-fluid batter_img' alt="Yuva 2 Pro" data-aos="fade-up"
     data-aos-easing="linear"
     data-aos-duration="1500"/>
           <div className='_shadow'>
           <img src={`${baseurl}${basepath}/images/yuva2pro/battery-mobile-shadow.png`} className='img-fluid' alt="Yuva 2 Pro" data-aos="fade-up"
     data-aos-easing="linear"
     data-aos-duration="3000"/>
            </div>
        </div>
        <div className="__table">
           <img src={`${baseurl}${basepath}/images/yuva2pro/battery-table.png`} className='img-fluid' alt="Yuva 2 Pro"/>
        </div>
    </div>
  )
}
