import React, {useState} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {basepath,baseurl} from '../basepath'
import {Helmet} from "react-helmet";


const Support = () => {
  const [activeClass, setActiveClass] = useState(false)

  const [writeUs, setWriteUs] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    description: '',
  })

  const [salesEnquiry, setSalesEnquiry] = useState({
    name: '',
    mobile: '',
    email: '',
    address: '',
    description: '',
    type: 'sales',
  })

  const [letterType, setLetterType] = useState('')
  const [productType, setProductType] = useState('')
  const [productName, setProductName] = useState('')
  const [category, setCategory] = useState('')

  const handleLetterType = (e) => {
    setLetterType(e.target.value)
  }

  const handleProductType = (e) => {
    setProductType(e.target.value)
  }

  const handleProductName = (e) => {
    setProductName(e.target.value)
  }

  const handleCategory = (e) => {
    setCategory(e.target.value)
  }

  const handelWriteUs = (e) => {
    const {name, value} = e.target
    setWriteUs(() => ({
      ...writeUs,
      [name]: value,
    }))
  }

  const handelSalseEnquiry = (e) => {
    const {name, value} = e.target
    setSalesEnquiry(() => ({
      ...salesEnquiry,
      [name]: value,
    }))
  }

  const submitDetails = async () => {
    const detailsData = writeUs

    if (letterType !== '' || productName !== '' || productType !== '' || category !== '') {
      detailsData.feed_type = letterType
      detailsData.product_name = productName
      detailsData.product_type = productType
      detailsData.category = category
    } else {
      return
    }
    try {
      const res = await axios.post(
        `http://linuxdevserver.centralindia.cloudapp.azure.com/csipl56/lava/public/api/v1/needhelp`,
        detailsData
      )
      if (res.status === 200) {
        setCategory('')
        setProductName('')
        setLetterType('')
        setWriteUs({
          name: '',
          phone: '',
          email: '',
          address: '',
          description: '',
        })
        setLetterType('')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const submitSalesEnquiry = async () => {
    try {
      const res = await axios.post(
        `http://linuxdevserver.centralindia.cloudapp.azure.com/csipl56/lava/public/api/v1/contact`,
        salesEnquiry
      )
      console.log(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Lava Support</title>
      <meta name="support" content="support page lava" />
      </Helmet>
      <section className="page_title inner_breadcum detailpage_breadcum">
        <div className="full_container">
          <h1>Contact Us</h1>
        </div>
      </section>

      <section className='breadcurms  '>
        <div className='full_container'>
          <ul className='text-left'>
            <li>
              <Link to={`${basepath}/`}>Home</Link>
            </li>
            <li> Contact Us</li>
          </ul>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='contact_1'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='section_title text-center'>
                  <h4>Contact Us</h4>
                  <div className='breaker'></div>
                </div>
              </div>
            </div>
            {/* <!--
				<div class="row location_map_sec">
					<div class="col-md-3">
						<div class="location_details">
							<div class="one_location">
								<h5>LAVA International Ltd.</h5>
								<p>Corporate address <br>
									A-56, Sector – 64, <br>
									Noida- 201301, UP, India <br>
									Tel: 120 463 7100</p>
							</div>

							<div class="one_location">
								<h5>Lava Helpline Number:</h5>
								<p><a href="">1860-500-5001 </a></p>
								<p>10.00 AM till 7.00 PM,</p>
								<p>Monday to Sunday</p>
							</div>


						</div>
					</div>

					<div class="col-md-9">
						<div class="location_details_map">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.5823928016976!2d77.37295661508215!3d28.612302382425806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5135260691d%3A0x2c4dec1e62551dcf!2sLava%20International%20Ltd.!5e0!3m2!1sen!2sin!4v1667218129992!5m2!1sen!2sin"
								style="border:0;" allowfullscreen="" loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"></iframe>
						</div>
					</div>
				</div> --> */}

            <div className='row'>
              <div className='col-md-12'>
                <div className='media_cnter helpline'>
                  <ul>
                    <li>
                      <img src={`${baseurl}${basepath}/images/Support-icon.png`} alt='img' />
                      <h4>Helpline</h4>
                      <a href='tel:120 463 7100'>Tel: 120 463 7100</a> <br />
                      <a>Monday to Sunday</a> <br />
                      <a>10.00 AM till 7.00 PM</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <div className='media_cnter'>
                  <ul>
                    <li>
                      <h4>Media Contacts</h4>
                      <a href='mailto:press@lavainternational.in'>press@lavainternational.in</a>
                    </li>

                    <li>
                      <h4>Service Contacts</h4>
                      <a href='mailto:lavacare@lavainternational.in'>
                        lavacare@lavainternational.in
                      </a>
                    </li>

                    <li>
                      <h4>Corporate Enquiry</h4>
                      <a href='mailto:enterprisesales@lavainternational.in'>
                        enterprisesales@lavainternational.in
                      </a>
                    </li>

                    <li>
                      <h4>Business Opportunity</h4>
                      <a href='mailto:rahul.kumar1@lavainternational.in'>
                        rahul.kumar1@lavainternational.in
                      </a>
                    </li>

                    <li>
                      <h4>Whistleblower Contacts</h4>
                      <a href='mailto:speakup@lavainternational.in'>speakup@lavainternational.in</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='full_container'>
          <div className='contact_1'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='section_title'>
                  <h4>Submit your Enquiry</h4>
                  <div className='breaker'></div>
                </div>
              </div>
            </div>

            <div className='row submit_your_enquiry'>
              <div className='col-md-12'>
                <div className='submit_tabs'>
                  {/* <!-- Nav pills --> */}
                  <ul className='nav nav-pills'>
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${!activeClass ? 'active' : ''}`}
                        data-toggle='pill'
                        onClick={() => setActiveClass(false)}
                      >
                        Write to Us
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${activeClass ? 'active' : ''}`}
                        data-toggle='pill'
                        onClick={() => setActiveClass(true)}
                      >
                        Sales Enquiry
                      </a>
                    </li>
                  </ul>

                  {/* <!-- Tab panes --> */}
                  <div className='tab-content'>
                    <div className={`tab-pane ${!activeClass ? 'active' : ''}`} id='home'>
                      <div className='write_form'>
                        <form>
                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Name <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='text'
                                  name='name'
                                  onChange={handelWriteUs}
                                  value={writeUs.name}
                                  className='form-control'
                                />
                              </div>
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Phone Number <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='number'
                                  name='phone'
                                  onChange={handelWriteUs}
                                  value={writeUs.phone}
                                  className='form-control'
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Your Email ID <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='email'
                                  onChange={handelWriteUs}
                                  name='email'
                                  value={writeUs.email}
                                  className='form-control'
                                />
                              </div>
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Address <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='text'
                                  onChange={handelWriteUs}
                                  value={writeUs.address}
                                  name='address'
                                  className='form-control'
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Letter Type <span className='required'>*</span>{' '}
                                </label>
                                <select
                                  className='form-control'
                                  value={letterType}
                                  onChange={handleLetterType}
                                >
                                  <option value='Select Feedback'>Select Feedback</option>
                                  <option value='1'>1</option>
                                  <option value='2'>2</option>
                                </select>
                              </div>
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Product Type<span className='required'>*</span>{' '}
                                </label>
                                <select
                                  className='form-control'
                                  value={productType}
                                  onChange={handleProductType}
                                >
                                  <option selected='selected' value='0'>
                                    -Select Product Type-
                                  </option>
                                  <option value='accessories'>Accessories</option>
                                  <option value='feature phone'>Feature phone</option>
                                  <option value='laptops'>Laptops</option>
                                  <option value='smart phone'>Smart Phone</option>
                                  <option value='tablets'>Tablets</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputPassword1' className='mb-2'>
                                  Product Name
                                </label>

                                <select
                                  className='form-control'
                                  value={productName}
                                  onChange={handleProductName}
                                >
                                  <option value='0'>-Select Product-</option>
                                  <option value='LAVA A77'>LAVA A77</option>
                                  <option value='Lava Helium 12'>Lava Helium 12</option>
                                  <option value='Lava Helium 14'>Lava Helium 14</option>
                                  <option value='Lava Twinpad'>Lava Twinpad</option>
                                </select>
                              </div>
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputPassword1' className='mb-2'>
                                  Category
                                </label>
                                <select
                                  className='form-control'
                                  value={category}
                                  onChange={handleCategory}
                                >
                                  <option value='0'>-Select Category-</option>
                                  <option selected='selected' value='accessories'>
                                    Accessories
                                  </option>
                                  <option value='battery'>Battery</option>
                                  <option value='others'>Others</option>
                                  <option value='screen'>Screen</option>
                                  <option value='service centre'>Service Centre</option>
                                  <option value='updates'>Updates</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputPassword1' className='mb-2'>
                                  Enter Details <span className='required'>*</span>
                                </label>
                                <textarea
                                  name='description'
                                  onChange={handelWriteUs}
                                  value={writeUs.description}
                                  className='form-control'
                                  rows='5'
                                ></textarea>
                              </div>
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Security Key<span className='required'>*</span>{' '}
                                </label>
                                <div className='input-group'>
                                  <input
                                    type='text'
                                    name='security_key'
                                    className='form-control'
                                    required
                                  />
                                  <div className='input-group-prepend'>
                                    <span className='input-group-text' id='inputGroupPrepend2'>
                                      GUAMV{' '}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <button
                                type='button'
                                onClick={submitDetails}
                                className='btn btn-primary'
                              >
                                Submit Details
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className={`tab-pane ${activeClass ? 'active' : ''}`} id='menu1'>
                      <div className='write_form'>
                        <form>
                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Name <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='text'
                                  name='name'
                                  onChange={handelSalseEnquiry}
                                  value={salesEnquiry.name}
                                  className='form-control'
                                />
                              </div>
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Phone Number <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='number'
                                  name='mobile'
                                  onChange={handelSalseEnquiry}
                                  value={salesEnquiry.mobile}
                                  className='form-control'
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Your Email ID <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='email'
                                  name='email'
                                  onChange={handelSalseEnquiry}
                                  value={salesEnquiry.email}
                                  className='form-control'
                                />
                              </div>
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Address <span className='required'>*</span>{' '}
                                </label>
                                <input
                                  type='text'
                                  name='address'
                                  value={salesEnquiry.address}
                                  onChange={handelSalseEnquiry}
                                  className='form-control'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputPassword1' className='mb-2'>
                                  Enter Details <span className='required'>*</span>
                                </label>
                                <textarea
                                  className='form-control'
                                  name='description'
                                  value={salesEnquiry.description}
                                  onChange={handelSalseEnquiry}
                                  rows='5'
                                ></textarea>
                              </div>
                            </div>

                            <div className='col-md-6 mb-2'>
                              <div className='form-group'>
                                <label for='exampleInputEmail1' className='mb-2'>
                                  Security Key<span className='required'>*</span>{' '}
                                </label>
                                <div className='input-group'>
                                  <input
                                    type='text'
                                    name='security_key'
                                    className='form-control'
                                    required
                                  />
                                  <div className='input-group-prepend'>
                                    <span className='input-group-text' id='inputGroupPrepend2'>
                                      GUAMV{' '}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <button
                                type='button'
                                onClick={submitSalesEnquiry}
                                className='btn btn-primary'
                              >
                                Submit Details
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Support
