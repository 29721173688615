import React,{useEffect} from 'react'
import '../../styles/yuva2proj.scss'
import {basepath,baseurl} from '../../basepath'
import AOS from 'aos'

export default function Highsecurity() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return (
    <div className='highsecurity'>
        <div className="title_container">
            <h2>High on Security!</h2>
            <h4>Just smile or touch and you're in!</h4>
            <p>Our exceptionally fast finger print sensor and Face Unlock feature keeps your phone secure the way you want it. </p>
        </div>
        <div className="__mobiles">
           <img src={`${baseurl}${basepath}/images/yuva2pro/security-mobile1.png`} className='img-fluid _mobile1' alt="Yuva 2 Pro" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="linear"/>
           <img src={`${baseurl}${basepath}/images/yuva2pro/security-mobile2.png`} className='img-fluid _mobile2' alt="Yuva 2 Pro"/>
           <div className="__unlock">
              <img src={`${baseurl}${basepath}/images/yuva2pro/finger-print.png`} className='img-fluid _finger_print' alt="Yuva 2 Pro" data-aos-duration="2000" data-aos-easing="linear" data-aos="zoom-in"/>
              <img src={`${baseurl}${basepath}/images/yuva2pro/face-unlock.png`} className='img-fluid _face_unlock' alt="Yuva 2 Pro" data-aos-duration="2000" data-aos-easing="linear" data-aos="zoom-in"/>
           </div>
        </div>
       

        </div>
  )
}
