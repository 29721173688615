import React from 'react'
import {basepath,baseurl} from '../../basepath'
export default function ImpressDesign() {
  return (
    <div className='impressdesign'>
        <img src={`${baseurl}${basepath}/images/blaze2/design-impress-text.png`} className='img-fluid impresstxt' alt="Blaze 2" />

        <div className="impressdiv">
        <div className='_left'>
            <div className="titlecontent">
                <h2>Designed to  <br/>Impress</h2>
                <h4>Made for you - the bold confident & original !</h4>
                <p>Blaze 2 comes with stunning modern design that will turn every head. Be the
trend setter, flaunt your style statement, stand out from the crowd and inspire
other to follow your style.</p>
            </div>
        </div>
        <div className='_right'>
         <img src={`${baseurl}${basepath}/images/blaze2/design-impress-mobile1.png`} className='img-fluid mobile1' alt="Blaze 2" />
         <img src={`${baseurl}${basepath}/images/blaze2/design-impress-mobile2.png`} className='img-fluid mobile2' alt="Blaze 2" />
          <img src={`${baseurl}${basepath}/images/blaze2/design-impress-mobile3.png`} className='img-fluid mobile3' alt="Blaze 2" />
        </div>
        </div>
    </div>
  )
}
