import React from 'react'
import {basepath,baseurl} from '../../basepath'
export default function Promode() {
  return (
    <div className='promode'>
        <div className="text_img">
          <img src={`${baseurl}${basepath}/images/blaze2/pro-mode-text.png`} className='img-fluid' alt="Blaze 2" />
        </div>
        <div className="promodecontext">
            <h2>PRO MODE</h2>
            <p>Capture it like a PRO, customized all elements of a photograph – brightness of your choice, enhance contrast, balance the colours, get the perfect exposure, white balance & ISO control. 
                Control the light exposure for different environment light levels with PRO mode ISO control.</p>
        </div>
        <div className="promode_pro-mode-mobile">
          <img src={`${baseurl}${basepath}/images/blaze2/pro-mode-mobile.png`} className='img-fluid _mode' alt="Blaze 2" />
            <div className="_shdow">
             <img src={`${baseurl}${basepath}/images/blaze2/pro-mode-mobil-shadow.png`} className='img-fluid' alt="Blaze 2" />

            </div>
        </div>       
    </div>
  )
}
